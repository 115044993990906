import {
  CREATE
} from 'react-admin'

import { stringify } from 'query-string'
import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

// import { AxiosResponse } from '../axiosResponse'

const imageUploadRoute = async (type, resource, params) => {
  const options: AxiosRequestConfig = {}

  if (CREATE === type) {
    const query = {
      token: `${localStorage.getItem('token')}`,
    }
    const file = new FormData()
    file.append('image', params.image.rawFile)


    options.url = `/${resource}/${params.id}/upload?${stringify(query)}`
    options.method = 'POST'
    options.data = file
  } else {
    throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  const response = await axios(options).catch(error => {
    const {
      response: {
        status: statusCode
      }
    } = error

    if (statusCode === 401) {
      localStorage.removeItem('token')
      Cookies.remove('token')
      Cookies.remove('role')
      Cookies.remove('userId')
      Cookies.remove('userName')
      return Promise.resolve()
    }
  })


  const { data: dataResponse }: any = response
  const data = {
    data: null
  }

  data.data = dataResponse
  return data
}

export default imageUploadRoute
