
import sanitizeHtml from 'sanitize-html'

const formatHtml = text => sanitizeHtml(text, {
  allowedTags: ['span', 'i', 'b', 'p', 'strong', 's', 'em', 'table', 'tbody', 'tr', 'th', 'td', 'ol', 'ul', 'li', 'img', 'blockquote', 'br'],
  allowedAttributes: {
    img: ['alt', 'src'],
    span: ['style'],
    table: ['style', 'border', 'cellpadding', 'cellspacing'],
    td: ['rowspan', 'style', 'colspan']
  },
  nonTextTags: ['del']
})

export default formatHtml
