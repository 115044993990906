
import React, { useState } from 'react'

import {
  withDataProvider,
  DELETE,
  UPDATE,
  showNotification
} from 'react-admin'

import TextField from '@material-ui/core/TextField'
import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import SelectList from '../SelectList'
import useLevelOwnership from '../../../../utils/useLevelOwnership'
import TestSegmentAnswer from './TestSegmentAnswer'
import CKEditorField from '../../../../elements/CKEditorField'

const styles = () => createStyles({
  buttonsWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textAreaWrap: {
    marginRight: '20px',
    flexGrow: 1
  },
  button: {
    marginRight: '20px'
  },
  source: {
    marginTop: '15px',
    marginBottom: '25px'
  },
  sourceInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '17px'
  },
  subquestionWrapper: {
    marginBottom: '80px'
  },
  fileAreaWrap: {
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap' as 'wrap'
  },
})

interface IProps extends WithStyles {
  typeId: number,
  testId: number,
  question: any,
  dataProvider: any,
  dispatchR: (type: any) => void,
  status: string,
  typeSegmentId: number
}

const TestSegmentQuestion = withStyles(styles)(({
  question,
  typeId,
  testId,
  classes,
  dataProvider,
  dispatchR,
  status,
}: IProps) => {
  const {
    id,
    content
  } = question


  const levelOwnershipList = useLevelOwnership()
  const [sourceLevelId, setSourceLevelId] = useState(question.sourceLevel && question.sourceLevel.id)
  const [source, setSource] = useState(question.source)
  const [sourceDate, setSourceDate] = useState(question.sourceDate && new Date(question.sourceDate))

  const deleteQuestion = async () => {
    await dataProvider(DELETE, 'segments', {
      id,
      url: 'questions'
    }, {
      onSuccess: {
        notification: { body: 'Сохранено', level: 'info' },
      },
    })
    dispatchR({ type: 'deleteQuestion', id })
  }

  const changeQuestion = () => {
    dataProvider(UPDATE, 'segments', {
      id,
      url: 'questions',
      data: {
        testId,
        content,
        sourceLevelId,
        source,
        sourceDate
      }
    }, {
      onSuccess: {
        notification: { body: 'Сохранено', level: 'info' },
      },
    })
  }

  return (
    <div>
      <div className={classes.subquestionWrapper}>
        <CKEditorField
          classes={classes.textAreaWrap}
          label={`Подвопрос N ${question.number}`}
          initialtValue={content}
          val={content}
          cb={content => dispatchR({ type: 'editQuestion', id, content })}
          status={status}
          id={id}
          idType="questionId"
          save={changeQuestion}
          deleteEditor={deleteQuestion}
          labelSave={`Сохранить подвопрос N ${question.number}`}
          labelDelete={`Удалить подвопрос N ${question.number}`}
          sourceLevelId={sourceLevelId}
          source={source}
          sourceDate={sourceDate}
          symbols={question.symbols}
        >
          <div className={classes.sourceInfo}>
            <SelectList
              formControlStyle={classes.source}
              name="Уровень владения источником"
              selectedId={sourceLevelId}
              list={levelOwnershipList}
              onChange={setSourceLevelId}
              error={!sourceLevelId}
            />
            <TextField
              multiline
              className={classes.source}
              label="Источник"
              value={source}
              onChange={e => setSource(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              error={!source}
            />

            <MuiPickersUtilsProvider
              utils={MomentUtils}
            >
              <DatePicker
                className={classes.field}
                label="Дата редакции источника"
                value={sourceDate || null}
                onChange={setSourceDate}
                format="DD/MM/YYYY"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!sourceDate}
              />
            </MuiPickersUtilsProvider>
          </div>
        </CKEditorField>
      </div>
      <div>
        {question.answers && (
          <TestSegmentAnswer
            questionId={question.id}
            answers={question.answers}
            dataProvider={dataProvider}
            typeId={typeId}
            isQuestionType
            dispatch={dispatchR}
            status={status}
          />
        )}
      </div>

    </div>
  )
})

export default withDataProvider(TestSegmentQuestion)
