import React, { useState } from 'react'
import Cookies from 'js-cookie'

import {
  withDataProvider,
} from 'react-admin'

import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { DEACTIVATE } from "../../providers/constants";
import ButtonsToolbar from '../../elements/ButtonsToolbar'

interface IProps extends WithStyles {
  segmentId: number | any[],
  dataProvider: any,
  buttonDisabled: boolean
}

const styles = () => createStyles({
  deactivateButton: {
    marginLeft: '15px',
    outline: 'none'
  },
  dialogPopup: {
    outline: 'none'
  },
  wrap: {
    display: 'flex',
    width: 'auto',
    marginBottom: '25px',
    flexDirection: 'column'
  },
  item: {
    marginTop: '10px',
    flexBasis: '100%'
  },
  field: {
    width: '90%'
  }
})

const DeactivateSegmentModal = withStyles(styles)(({
  classes,
  segmentId,
  dataProvider,
  buttonDisabled
}: IProps) => {
  const [deactivateReason, setDeactivateReason] = useState('')
  const [deactivateDate, setDeactivateDate] = useState(new Date())
  const [deactivatePopupVisible, setDeactivatePopupVisible] = useState(false)
  const [deactivate, setDeactivate] = useState(false)
  const role = Cookies.get('role')
  const rolesSegmentManagement = ['admin', 'managerMo', 'directorMo']

  const responseSuccess = {
    onSuccess: {
      notification: { body: 'Сегмент деактивирован', level: 'info' },
    },
    onFailure: {
      notification: { body: 'Error', level: 'warning' },
    },
    refresh: true
  }

  const deactivateSegments = async () => {
    try {
      const ids = segmentId instanceof Array ? segmentId.join(',') : segmentId
      await dataProvider(DEACTIVATE, 'segments', {
        data: {
          reason: deactivateReason,
          date: deactivateDate,
        },
        id: ids
      }, responseSuccess)
    } catch (e) {
      console.log('e', e);
    }
    closeModalDeactivate()
  }

  const closeModalDeactivate = () => {
    setDeactivatePopupVisible(false)
    setDeactivateDate(new Date())
    setDeactivateReason('')
    setDeactivate(false)
  }

  return (<>
    {rolesSegmentManagement.includes(role) && (
    <Button
      onClick={() => setDeactivatePopupVisible(true)}
      className={classes.deactivateButton}
      disabled={buttonDisabled}
    >
      Деактивировать сегмент
    </Button>
    )}
    <Dialog PaperProps={{ className: classes.dialogPopup }} open={deactivatePopupVisible}
      onClose={() => setDeactivatePopupVisible(false)} aria-labelledby="form-dialog-title">
      <DialogTitle>Укажите причину деактивации</DialogTitle>
      <DialogContent>
        <div className={classes.wrap}>
          <div className={classes.item}>
            <TextField
              className={classes.field}
              label="Укажите причину"
              value={deactivateReason}
              onChange={e => setDeactivateReason(e.target.value)}
              error={!deactivateReason && deactivate}
            />
          </div>
          <div className={classes.item}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
            >
              <DatePicker
                className={classes.field}
                label="Дата дактивации"
                value={deactivateDate || null}
                onChange={setDeactivateDate}
                format="DD/MM/YYYY"
                error={!deactivateDate && deactivate}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <ButtonsToolbar buttons={[
          {
            label: "Отмена",
            onClick: () => setDeactivatePopupVisible(false)
          },
          {
            label: "Деактивировать",
            onClick: deactivateSegments,
            className: classes.deactivateButton,
          }
        ]} />
      </DialogActions>
    </Dialog>
  </>)

})

export default withDataProvider(DeactivateSegmentModal)
