import React from 'react'
import { Show } from 'react-admin'

import TestSegmentShow from './show/TestSegmentShow'
import CaseSegmentShow from './show/CaseSegmentShow'

interface IProps {
  record?: any,
  permissions?: any
}

const SegmentsShowPage = ({ record, permissions }: IProps) => {
  const { type } = record

  return (
    type === 'test' ? (
      <TestSegmentShow record={record} permissions={permissions} />
    ) : (
      <CaseSegmentShow record={record} permissions={permissions} />
    )
  )
}

const SegmentsShow = props => (
  <Show
    title=" "
    {...props}
  >
    <SegmentsShowPage permissions={props.permissions} />
  </Show>
)

export default SegmentsShow
