import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  CREATE,
  DELETE,
  HttpError
} from 'react-admin'

import { stringify } from 'query-string'
import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import {
  DEACTIVATE,
  ASSIGN_EXPERT,
  REMOVE_FILE,
  ATTACH_FILE,
  SET_CHECK_STATUS,
  CLONE_SEGMENT
} from '../constants'
import { AxiosResponse } from '../axiosResponse'
import { IQuery } from '../query'

const examCheckRoute = async (type, resource, params) => {
  const options: AxiosRequestConfig = {
    headers: { 'Content-Type': 'application/json' }
  }
  const request = {} as any
  let query: IQuery = {
    token: `${localStorage.getItem('token')}`,
  }

  switch (type) {
    case GET_ONE:
      options.url = `${resource}/${params.id}?${stringify(query)}`
      break
    case GET_LIST: {
      if (params.pagination) {
        query = {
          ...query,
          ...params.pagination
        }
      }
      if (params.filter) {
        query = {
          ...query,
          ...params.filter
        }
      }
      options.url = `/${resource}?${stringify(query)}`
      break
    }
    case CREATE: {
      options.url = `/exam-check/check?${stringify(query)}`
      options.method = 'POST'
      options.data = JSON.stringify(params.data)
      request.headers = { 'Content-Type': 'application/json' }
      break
    }
    case CLONE_SEGMENT: {
      options.headers = { 'Content-Type': 'application/json' }
      options.url = `/${params.url}/${params.id}/clone?${stringify(query)}`
      options.method = 'POST'
      options.data = JSON.stringify(params.data)
      request.headers = { 'Content-Type': 'application/json' }
      break
    }
    case UPDATE: {
      options.url = `/${params.url}/${params.id}?${stringify(query)}`
      options.method = 'PUT'
      options.data = JSON.stringify(params.data)
      break
    }
    case DELETE: {
      options.url = `/${params.url}/${params.id}?${stringify(query)}`
      options.method = 'DELETE'
      break
    }
    case ATTACH_FILE: {
      options.url = `/${params.url}/${params.id}?${stringify(query)}`
      options.method = 'POST'
      const file = new FormData()
      file.append('file', params.data.file)
      options.data = file
      break
    }
    case REMOVE_FILE: {
      options.url = `/${params.url}/${params.attachId}/remove-attach?${stringify(query)}`
      options.method = 'DELETE'
      break
    }
    case ASSIGN_EXPERT: {
      const ids = params.segmentId instanceof Array ? params.segmentId.join(',') : params.segmentId
      options.url = `/${resource}/${ids}/check?${stringify(query)}`
      options.method = 'POST'
      options.data = JSON.stringify(params.data)
      break
    }
    case DEACTIVATE: {
      options.url = `/${resource}/${params.id}/deactivate?${stringify(query)}`
      options.method = 'POST'
      options.data = JSON.stringify(params.data)
      break
    }
    case SET_CHECK_STATUS: {
      options.headers = { 'Content-Type': 'application/json' }
      options.url = `/${resource}/${params.segmentId}/check-verify?${stringify(query)}`
      options.method = 'POST'
      options.data = JSON.stringify(params.data)
      break
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  const response: any = await axios(options).catch(error => {
    const {
      response: {
        status: statusCode
      }
    } = error

    if (statusCode === 401) {
      localStorage.removeItem('token')
      Cookies.remove('token')
      Cookies.remove('role')
      Cookies.remove('userId')
      Cookies.remove('userName')
      return Promise.resolve()
    }

    if (statusCode === 403) throw new HttpError(error.response.data.message)

    if (statusCode === 422) throw new HttpError(error.response.data[0].message)
  })

  const { data: dataResponse } = response

  const data: IRAdminData<ISegment> = {
    data: null,
  }

  if ([GET_ONE, UPDATE, CREATE, ASSIGN_EXPERT, ATTACH_FILE, REMOVE_FILE, DEACTIVATE, CLONE_SEGMENT].includes(type)) {
    data.data = dataResponse
  } else {
    data.data = dataResponse.models
    data.total = dataResponse.total
  }

  return data
}

export default examCheckRoute
