import React from 'react'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import { removeHtml } from '../utils/getFields'

const CustomTextField = ({
  className = '',
  source,
  record,
  cb,
}) => {
  let text

  if (!Array.isArray(record)) {
    if (Array.isArray(source)) {
      text = source.map(item => get(record, item)).join(' ')
    } else {
      text = cb ? cb(get(record, source)) : get(record, source)
    }
  } else {
    text = record.map(item => item[source])
  }

  return (
    <Typography className={className}>
      <span dangerouslySetInnerHTML={{ __html: removeHtml(text) as string }} />
    </Typography>
  )
}

export default CustomTextField
