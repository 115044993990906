import React from 'react'

import {
  Toolbar,
  withDataProvider,
  showNotification,
  GET_ONE
} from 'react-admin'

import Cookies from 'js-cookie'
import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { ASSIGN_EXPERT } from '../../../providers/constants'
import UsersSelect from '../../../elements/UsersSelect'
import FormDialog from '../../../elements/FormDialog'
import DeactivateSegmentModal from '../DeactivateSegmentModal'

// todo рефакторинг


const styles = () => createStyles({
  wrap: {
    display: 'flex',
    width: '700px',
    marginBottom: '20px',
  },
  elements: {
    marginRight: '25px',
  },
  buttonWrap: {
    width: '596px',
    textAlign: 'right',
  },
  textAreaWrap: {
    marginBottom: '15px',
  },
  button: {
    marginRight: '15px'
  },
})

const responseSuccess = {
  onSuccess: {
    notification: { body: 'Сохранено', level: 'info' },
  },
  onFailure: {
    notification: { body: 'Error', level: 'warning' },
  },
  refresh: true
}

interface IProps extends WithStyles {
  dataProvider: any,
  segmentId: number,
  experts: any,
  permissions: any,
  status: any,
  users: any,
  verifyStatus: number
}

const ToolbarProvider = withStyles(styles)(({
  classes, dataProvider, segmentId, experts, status, users = {}, verifyStatus
} : IProps) => {
  const role = Cookies.get('role')

  const statusId = status.id

  const setCheckStatus = async (statusId, successText: boolean | string = false, mute = false) => {
    await dataProvider('SET_CHECK_STATUS', 'segments', {
      segmentId,
      data: {
        statusId // id assign
      }
    }, successText ? {
      onSuccess: {
        notification: { body: successText, level: 'info' },
      },
      onFailure: {
        notification: { body: 'Error', level: 'warning' },
      },
      refresh: true
    } : responseSuccess)
  }

  const assignExpert = async (statusId, selectId, successText: boolean | string = false) => {
    const newExpert = (document.getElementById(selectId) as HTMLInputElement).value as unknown

    if (newExpert as number > 0) {
      await dataProvider('ASSIGN_EXPERT', 'segments', {
        segmentId,
        data: {
          toUserId: newExpert,
          status: 'assign',
          segmentStatusId: statusId // id assign
        }
      }, successText ? {
        onSuccess: {
          notification: { body: successText, level: 'info' },
        },
        onFailure: {
          notification: { body: 'Error', level: 'warning' },
        },
        refresh: true
      } : responseSuccess)
    } else {
      showNotification('Эксперт не выбран', 'warning')
    }
  }

  const checkNewExpert = async selectId => {
    const newExpert = (document.getElementById(selectId) as HTMLInputElement).value as unknown
    const error = []
    let checked = false

    experts.map(item => {
      if (item.id as number == newExpert as number) {
        checked = true
      }
    })
    if (checked) { error.push('Данный пользователь уже проверял этот сегмент, подтвердите повторное назначение эксперта') }

    const segmentInfo = await dataProvider(GET_ONE, 'segments', {
      id: segmentId,
      url: 'segments'
    })
    if (segmentInfo.data.verifyStatusId === 2) { error.push('Данный сегмент находится в работе, вы уверены, что хотите его переназначить?') }

    return {
      result: checked,
      error,
    }
  }

  const setStatus = async (statusId, successText: boolean | string = false) => {
    await dataProvider('ASSIGN_EXPERT', 'segments', {
      segmentId,
      data: {
        status: 'assign',
        segmentStatusId: statusId // id assign
      }
    }, successText ? {
      onSuccess: {
        notification: { body: successText, level: 'info' },
      },
      onFailure: {
        notification: { body: 'Error', level: 'warning' },
      },
      redirectTo: `/segments/${segmentId}`,
      refresh: true
    } : responseSuccess)
  }

  const hasExpertByRole = roleName => experts && experts.length && experts.some(expert => expert.role === roleName)

  /**
   * Позволяет создать кнопку назначения эксперта
   * @param statusId - Id статуса для перехода
   * @param data - данные для выбора назначения
   * @param userType - системный тип эксперта
   * @param userLabel - лейбл эксперта для отображения
   */
  const buildFormDialog = (statusId, data, userType, userLabel) => (
    <FormDialog
      label={`Назначить ${userLabel}`}
      modalTitle={`Назначить ${userLabel}`}
      content={<UsersSelect data={data} id={`users-select-${userType}`} />}
      confirm={() => assignExpert(statusId, `users-select-${userType}`)}
      className={classes.button}
      onBeforeConfirm={() => checkNewExpert(`users-select-${userType}`)}
    />
  )

  /* В зависимости от статуса, возвращаем разные тулбары */
  if ((role === 'managerMo' || role === 'admin' || (role === 'directorMo' && statusId !== 8))) {
    switch (statusId) {
      case 1:
        return (
          <>
            <FormDialog
              label="Назначить эксперта"
              modalTitle="Назначить эксперта"
              content={<UsersSelect data={users.experts} id="users-select-experts" />}
              confirm={() => assignExpert(4, 'users-select-experts', 'Переведено на проверку')}
              className={classes.button}
              onBeforeConfirm={() => checkNewExpert('users-select-experts')}
            />
            <Button onClick={() => setStatus(4, 'Переведено на проверку')} color="primary" className={classes.button}>
              На проверку
            </Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      case 2:
        return (
          <>
            <FormDialog
              label="Назначить эксперта"
              modalTitle="Назначить эксперта"
              content={<UsersSelect data={users.experts} id="users-select-experts" />}
              confirm={() => assignExpert(4, 'users-select-experts', 'Переведено на проверку')}
              className={classes.button}
              onBeforeConfirm={() => checkNewExpert('users-select-experts')}
            />
            <Button onClick={() => setStatus(4, 'Переведено на проверку')} color="primary" className={classes.button}>
              На проверку
            </Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      case 4:
        return (
          <>
            <FormDialog
              label="Назначить эксперта"
              modalTitle="Назначить эксперта"
              content={<UsersSelect data={users.experts} id="users-select-experts" />}
              confirm={() => assignExpert(4, 'users-select-experts')}
              className={classes.button}
              onBeforeConfirm={() => checkNewExpert('users-select-experts')}
            />
            <FormDialog
              label="Назначить НМО"
              modalTitle="Назначить НМО"
              content={<UsersSelect data={users.nmo} id="users-select-nmo" />}
              confirm={() => assignExpert(8, 'users-select-nmo')}
              className={classes.button}
              onBeforeConfirm={() => checkNewExpert('users-select-nmo')}
            />
            <Button
              onClick={() => setStatus(1, 'Возвращено в статус "Новый"')}
              className={classes.button}
            >
              Вернуть
            </Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      case 8: // На согласовании
        return (
          <>
            <FormDialog
              label="Назначить НМО"
              modalTitle="Назначить НМО"
              content={<UsersSelect data={users.nmo} id="users-select-nmo" />}
              confirm={() => assignExpert(8, 'users-select-nmo')}
              className={classes.button}
              onBeforeConfirm={() => checkNewExpert('users-select-nmo')}
            />
            <FormDialog
              label="Назначить корректора"
              modalTitle="Назначить корректора"
              content={<UsersSelect data={users.editors} id="users-select-editors" />}
              confirm={() => assignExpert(7, 'users-select-editors')}
              className={classes.button}
              onBeforeConfirm={() => checkNewExpert('users-select-editors')}
            />
            <Button
              onClick={() => setStatus(7, 'Переведено на рецензирование')}
              color="primary"
              className={classes.button}
            >
              На рецензирование
            </Button>
            <Button onClick={() => setStatus(4, 'Возвращено на проверку')} className={classes.button}>Вернуть</Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      case 7: // На рецензировании
        return (
          <>
            {!hasExpertByRole('editor') && buildFormDialog(7, users.editors, 'editors', 'корректора')}
            <FormDialog
              label="Назначить главного методиста"
              modalTitle="Назначить главного методиста"
              content={<UsersSelect data={users.mainNmo} id="users-select-mainNmo" />}
              confirm={() => assignExpert(9, 'users-select-mainNmo')}
              className={classes.button}
              onBeforeConfirm={() => checkNewExpert('users-select-mainNmo')}
            />
            <Button onClick={() => setStatus(8, 'Отправлено на согласование')} className={classes.button}>На согласование</Button>
            <Button onClick={() => setStatus(8, 'Возвращено на согласование')} className={classes.button}>Вернуть</Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      case 9: // На утверждении
        return (
          <>
            {!hasExpertByRole('methodist') && buildFormDialog(9, users.mainNmo, 'mainNmo', 'главного методиста')}
            <Button
              onClick={() => setStatus(7, 'Возвращено на рецензирование')}
              className={classes.button}
            >
              Вернуть
            </Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      case 10: // Утвержден
        return (
          <>
            <Button onClick={() => setStatus(8, 'Отправлено на согласование')} className={classes.button}>На согласование</Button>
            <Button onClick={() => setStatus(11, 'Переведено в статус "На экзамен"')} className={classes.button}>На экзамен</Button>
          </>
        )
      case 11: // На экзамен
        return (
          <>
            <FormDialog
              label="Назначить эксперта"
              modalTitle="Назначить эксперта"
              content={<UsersSelect data={users.experts} id="users-select-experts" />}
              confirm={() => assignExpert(4, 'users-select-experts', 'Переведено на проверку')}
              className={classes.button}
              onBeforeConfirm={() => checkNewExpert('users-select-experts')}
            />
            <Button onClick={() => setCheckStatus(8, 'Возвращено на согласование')} color="primary" className={classes.button}>На согласование</Button>
            <Button onClick={() => setStatus(10, 'Возвращено в статус "Утвержден"')} className={classes.button}>Исключить</Button>
            <Button onClick={() => setStatus(2, 'Переведено в архив')} className={classes.button}>В архив</Button>
          </>
        )
      default:
        return <> </>
    }
  } else if (
    (role === 'editor' && statusId === 7)
    || (role === 'expertReviewer' && statusId === 4)
  ) {
    const acceptText = () => (role === 'directorMo' ? 'Согласовано' : 'Проверено')
    switch (verifyStatus) {
      case 2: // В работе
        return (
          <>
            <Button onClick={() => setCheckStatus(3, acceptText())} color="primary" className={classes.button}>{acceptText()}</Button>
            <Button onClick={() => setCheckStatus(0, 'Отклонено')} color="primary" className={classes.button}>Отклонить</Button>
            <Button onClick={() => setCheckStatus(1, 'Работа остановлена')} color="primary" className={classes.button}>Остановить работу</Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      case 3: // Проверено
        return (
          <>
            {/* <Button onClick={() => setCheckStatus(2, 'Возвращено в работу')} color="primary" className={classes.button}>Перепроверить</Button> */}
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      default: // Назначен (null || 1)
        return (
          <>
            <Button onClick={() => setCheckStatus(2, 'В работе')} color="primary" variant="outlined" className={classes.button}>
              Взять в работу
            </Button>
            <Button onClick={() => setCheckStatus(0, 'Отклонено')} color="primary" className={classes.button}>Отклонить</Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
    }
  } else if ((role === 'methodist' || role === 'admin') && (statusId === 9 || statusId === 10)) {
    switch (statusId) {
      case 9:
        return (
          <>
            <Button onClick={() => setStatus(8)} color="primary" className={classes.button}>Отклонить</Button>
            <Button
              onClick={() => setStatus(10, 'Сегмент утвержден')}
              color="primary"
              variant="outlined"
              className={classes.button}
            >
              Утвердить
            </Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      case 10:
        return (
          <>
            <Button
              onClick={() => setStatus(9, 'Возвращено на утверждение')}
              color="primary"
              className={classes.button}
            >
              Вернуть
            </Button>
            <Button
              onClick={() => setStatus(11, 'Переведено в статус "На экзамен"')}
              color="primary"
              className={classes.button}
            >
              На экзамен
            </Button>
            <DeactivateSegmentModal segmentId={segmentId} />
          </>
        )
      default:
        return <> </>
    }
  } else if (role === 'directorMo' && statusId === 8) {
    switch (verifyStatus) {
      case 2: // В работе
        return (
          <div style={{ display: 'block', padding: '15px 0' }}>
            <div>
              <Button onClick={() => setCheckStatus(3, 'Согласовано')} color="primary" className={classes.button}>Согласовано</Button>
              <Button onClick={() => setCheckStatus(0, 'Отклонено')} color="primary" className={classes.button}>Отклонить</Button>
              <Button onClick={() => setCheckStatus(1, 'Работа остановлена')} color="primary" className={classes.button}>Остановить работу</Button>
              <DeactivateSegmentModal segmentId={segmentId} />
            </div>
            {/* ManagerMOToolbar */}
            <div style={{ marginTop: 15 }}>
              <FormDialog
                label="Назначить НМО"
                modalTitle="Назначить НМО"
                content={<UsersSelect data={users.nmo} id="users-select-nmo" />}
                confirm={() => assignExpert(8, 'users-select-nmo')}
                className={classes.button}
                onBeforeConfirm={() => checkNewExpert('users-select-nmo')}
              />
              <FormDialog
                label="Назначить корректора"
                modalTitle="Назначить корректора"
                content={<UsersSelect data={users.editors} id="users-select-editors" />}
                confirm={() => assignExpert(7, 'users-select-editors')}
                className={classes.button}
                onBeforeConfirm={() => checkNewExpert('users-select-editors')}
              />
              <Button
                onClick={() => setStatus(7, 'Переведено на рецензирование')}
                color="primary"
                className={classes.button}
              >
                На рецензирование
              </Button>
              <Button onClick={() => setStatus(4, 'Возвращено на проверку')} className={classes.button}>Вернуть</Button>
              {/* <DeactivateSegmentModal segmentId={segmentId} /> */}
            </div>
          </div>
        )
      case 3: // Проверено
        return (
          <>
            <DeactivateSegmentModal segmentId={segmentId} />
            {/* ManagerMOToolbar */}
            <div style={{ marginTop: 15 }}>
              <FormDialog
                label="Назначить НМО"
                modalTitle="Назначить НМО"
                content={<UsersSelect data={users.nmo} id="users-select-nmo" />}
                confirm={() => assignExpert(8, 'users-select-nmo')}
                className={classes.button}
                onBeforeConfirm={() => checkNewExpert('users-select-nmo')}
              />
              <FormDialog
                label="Назначить корректора"
                modalTitle="Назначить корректора"
                content={<UsersSelect data={users.editors} id="users-select-editors" />}
                confirm={() => assignExpert(7, 'users-select-editors')}
                className={classes.button}
                onBeforeConfirm={() => checkNewExpert('users-select-editors')}
              />
              <Button onClick={() => setStatus(8, 'Отправлено на согласование')} color="primary" className={classes.button}>На согласование</Button>
              <Button onClick={() => setStatus(7, 'Переведено на рецензирование')} color="primary" className={classes.button}>На рецензирование</Button>
              <Button onClick={() => setStatus(9, 'На утверждении')} color="primary" className={classes.button}>На утверждение</Button>
              <Button onClick={() => setStatus(4, 'Возвращено на проверку')} className={classes.button}>Вернуть</Button>
            </div>
          </>
        )
      default: // Назначен (null || 1)
        return (
          <div style={{ display: 'block', padding: '15px 0' }}>
            <Button onClick={() => setCheckStatus(2, 'В работе')} color="primary" variant="outlined" className={classes.button}>
              Взять в работу
            </Button>
            <FormDialog
              label="Назначить эксперта"
              modalTitle="Назначить эксперта"
              content={<UsersSelect data={users.experts} id="users-select-experts" />}
              confirm={() => assignExpert(4, 'users-select-experts', 'Переведено на проверку')}
              className={classes.button}
              onBeforeConfirm={() => checkNewExpert('users-select-experts')}
            />
            <Button onClick={() => setCheckStatus(0, 'Отклонено')} color="primary" className={classes.button}>Отклонить</Button>
            <Button onClick={() => setStatus(9, 'На утверждении')} color="primary" className={classes.button}>На утверждение</Button>
            <DeactivateSegmentModal segmentId={segmentId} />
            {/* <ManagerMOToolbar /> */}
            <div style={{ marginTop: 15 }}>
              {!hasExpertByRole('directorMo') && buildFormDialog(8, users.nmo, 'nmo', 'НМО')}
              {buildFormDialog(7, users.editors, 'editors', 'корректора')}
              <Button onClick={() => setStatus(4, 'Возвращено на проверку')} className={classes.button}>Вернуть</Button>
            </div>
          </div>
        )
    }
  } else {
    return <> </>
  }
})

export default withDataProvider(ToolbarProvider)
