import React, { useState } from 'react'
import {
  FileInput,
  SimpleForm,
  FileField,
  DateInput,
  showNotification,
  withDataProvider,
  SaveButton,
  Toolbar
} from 'react-admin'
import {
  Paper, CardContent, Grid
} from '@material-ui/core'

import axios from 'axios'
import Cookies from 'js-cookie'
import {SERVER_URL} from "../../providers/constants";
import moment from "moment";
import SnackbarMessage from "../../elements/SnackBarMessage";

//axios.defaults.baseURL = `${SERVER_URL}/api/`

export const isValidFile = filename => {
  const availableExtensions = ['xls', 'xlsx']
  const ext = filename.split('.').pop()

  if (availableExtensions.includes(ext)) return true
  return false
}

const Report = props => {
  const [sending, setSending] = useState(false)

  /**
   * Отправляет запрос на получение отчёта по сдаче тестовых заданий.
   * @param formData
   */
  const sendingTestsReport = async formData => {
    const { dispatch } = props
    const from = moment(formData.from).format('YYYY-MM-DD')
    const to = moment(formData.to).format('YYYY-MM-DD')

    setSending(true)
    axios.get('/app/exam-groups/tests-report?from=' + from + '&to=' + to, {
      baseURL: `${SERVER_URL}/api`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('token')}`
      }
    })
      .then(response => {
        setSending(false)
        window.open(response.data.url, '_blank')
      })
      .catch(error => {
        setSending(false)
        dispatch(showNotification('Возникли ошибки при получении отчёта.', 'warning'))
      })
  }

  const SendToolBar = props => (
    <Toolbar {...props} >
      <SaveButton
        label="Получить"
        redirect="show"
        disabled={sending}
        submitOnEnter={true}
      />
    </Toolbar>
  )

  return (
    <>
      <Paper>
        <CardContent>
          <h2>Статистика по сдаче тестов</h2>
        </CardContent>

        <SimpleForm save={sendingTestsReport} toolbar={<SendToolBar />}>
          <Grid container>
            <DateInput label="От" source="from" defaultValue={new Date('2020-07-09')} />
            <DateInput label="До" source="to" defaultValue={new Date()} />
          </Grid>
        </SimpleForm>
      </Paper>
    </>
  )
}
export default withDataProvider(Report)
