import React, { useState } from 'react'
import {Link} from 'react-router-dom'

import {
  withDataProvider,
} from 'react-admin'

import { createStyles, WithStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import moment from "moment";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = () => createStyles({
  link: {
    '&:visited': {
      color: 'rgb(0, 0, 238)'
    }
  },
  tableSmall: {
    cellPadding: '0',
    cellSpacing: '0',
    borderSpacing: '0',
    fontSize: '0.875rem'
  },
  bordered: {
    padding: '10px 5px',
    margin: '0',
    borderBottom: '1px solid #ccc',
  },
  btn: {
    backgroundColor: '#cfcfcf'
  },
  status: {
    backgroundColor: '#eee',
    padding: '5px',
    fontSize: '14px'
  }
})

const SegmentVersionModal = ({classes, versions, ...props}) => {
  const [showModal, setShowModal] = useState(false)

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <>
      {(versions && versions.length > 3) && (
        <p>
          <Button className={classes.btn} onClick={() => {setShowModal(true)}}>Показать полностью</Button>
        </p>
      )}
      <Dialog open={showModal} onClose={closeModal} aria-labelledby="form-dialog-title">
        <DialogTitle>История изменений</DialogTitle>
        <DialogContent>
          <table className={classes.tableSmall}>
            <tbody>
            {versions && versions.map(({id, createdAt, segmentId, author, status, comment}) => (
              <tr key={id}>
                <td className={classes.bordered}>
                  <Link to={{pathname: `/version/${segmentId}/show`}} className={classes.link} target="_blank">
                    {moment(createdAt).format('LLL')}
                  </Link>
                </td>
                <td className={classes.bordered}>
                  {author.surname} {author.name}
                </td>
                <td className={classes.bordered}>
                  <span className={classes.status}>{comment ? comment : status.action}</span>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(SegmentVersionModal)
