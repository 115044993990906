import React from 'react'
import { CardActions, BulkDeleteButton } from 'react-admin'

const RequestPasswordActions = props => (
  <CardActions>
    <BulkDeleteButton {...props} label="Отклонить выбранные" />
  </CardActions>
)


export default RequestPasswordActions
