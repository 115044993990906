import React, { useEffect, useRef, useState } from 'react'
import IconButton from './IconButtonCompact'

interface IProps {
  typeId: number,
  labelSave: string,
  isDelete: boolean,
  role: string,
  labelDelete: string,
  sourceLevelId: number,
  source: string,
  sourceDate: string,
  val: string,
  deleteEditor: ()=> void,
  save: ()=> void
}

const CKEditorFieldControls: React.FunctionComponent<IProps> = ({
  typeId,
  labelSave,
  isDelete = true,
  role,
  labelDelete,
  deleteEditor,
  sourceLevelId,
  source,
  sourceDate,
  val,
  save
}) => {
  const [isChange, setIsChange] = useState(false)
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current && !isChange) {
      setIsChange(true)
    } else {
      didMountRef.current = true
    }
  }, [sourceLevelId, source, sourceDate, val])

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

      <IconButton
        label={labelSave}
        isConfirmed={isChange}
        onClick={() => {
          if (isChange) {
            save()
            setIsChange(false)
          }
        }}
      />

      {isDelete
         && (typeId !== 6 && role !== 'expertReviewer') && (
         <IconButton
           label={labelDelete}
           onClick={deleteEditor}
           isDelete
         />
      )}
    </div>
  )
}

export default CKEditorFieldControls
