// Пояснение к CKEditor
// На данный момент в качестве rich-text editor для создания и редактирования сегментов используется
// реактовый компонент CKEditor (https://www.npmjs.com/package/ckeditor4-react), а для функционала track changes
// (история изменений текста) - бесплатная версия плагина LITE для CKEditor (https://github.com/loopindex/ckeditor-track-changes, https://www.loopindex.com/lite/doc/ (краткая версия документации, но в ней есть ошибки :( )), https://www.loopindex.com/lite/doc/api/index.html)
// Основные причины для такого решения: нехватка времени (для написания собственного решения для track-changes) и неготовность заказчика
// купить платные модули CKEditor или другого WYSIWYG-редактора в похожим функционалом.
// LITE был выбран из множества других бесплатных плагинов, потому что лучше всего отвечал требованиям заказчика.

// LITE совместим только с CKEditor 4, что исключило возможность использования более актуального CKEditor 5.
// Для корректной работы реакт-компонента CKEditor требуется ссылка на какую-либо сборку CKEditor (стандартную или кастомизированную, см. https://ckeditor.com/docs/ckeditor4/latest/guide/dev_react.html#customizing-ckeditor-preset-or-version и https://ckeditor.com/cke4/builder)
// В нашем случае требовалась кастомизированная сборка (в неё нужно было включить LITE и некоторые другие сторонние плагины, не входящие в standard build), при этом одним из основных требований
// была автономность всей системы, поэтому было принято решение сохранить всю сборку в самом проекте (public/ckeditor).

// Мб, стоит рассмотреть возможность создать private npm-package с актуальной сборкой и подключать CKEditor как npm-пакет (см. https://docs.npmjs.com/creating-and-publishing-private-packages)

import React, { useState } from 'react'
import CKEditor from 'ckeditor4-react'
import FormLabel from '@material-ui/core/FormLabel'
import Cookies from 'js-cookie'

import CKEditorFieldControls from './CKEditorFieldControlsCompact'
import Comments from './comments'

declare global {
  interface Window {
    CKEDITOR: any,
    LITE: any
  }
}
window.CKEDITOR = window.CKEDITOR || {}
window.LITE = window.LITE || {}

CKEditor.editorUrl = '../../../ckeditor/ckeditor.js'

const CKEditorFieldCompact = props => {
  const {
    label,
    initialValue,
    classes,
    cb,
    status,
    isSmaller,
    id,
    idType,
    entityForCase,
    val,
    children,
    symbols,
    ...controlProps
  } = props

  const [liteChanges, setLiteChanges] = useState(0)

  const { LITE } = window
  const userName = Cookies.get('userName')

  const answerHeight = isSmaller ? '60px' : ''

  // отображать плагин для track changes в зависимости от статуса сегмента
  const loadLite = !(['Утверждено', 'Архив'].includes(status))

  // отображать кнопки плагина track changes
  const liteToolbar = loadLite ? { name: 'lite' } : {}

  // кнопки для track changes
  const liteCommands = [
    LITE.Commands.TOGGLE_TRACKING,
    LITE.Commands.TOGGLE_SHOW,
    LITE.Commands.ACCEPT_ONE,
    LITE.Commands.REJECT_ONE,
    LITE.Commands.ACCEPT_ALL,
    LITE.Commands.REJECT_ALL
  ]
  // отображать кнопки для всего функционала (принять/отклонить изменения, etc), если с сегментом работает корректор или начальник МО,
  // и только кнопку "показать/скрыть изменения", если работают эксперты
  const liteButtons = (['Новый', 'На согласовании', 'На рецензировании'].includes(status)) ? liteCommands : [LITE.Commands.TOGGLE_SHOW]

  const config = {
    extraPlugins: 'simage, wordcount, lite, autogrow',
    // todo плагины, которые были включены в кастомную сборку CKEditor, но в результате не пригодились.
    // Возможно, стоит подумать о том, чтобы сделать и загрузить новую сборку CKEditor без них
    removePlugins: 'image, image2, footnotes, allowsave, preview, eqneditor, magicline',
    width: '100%',
    height: answerHeight,
    autoGrow_maxHeight: answerHeight,
    fillEmptyBlocks: false,
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection'] },
      { name: 'insert' },
      { name: 'forms' },
      { name: 'tools' },
      { name: 'document', groups: ['document', 'doctools'] },
      { name: 'others' },
      '/',
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
      { name: 'about' },
      liteToolbar
    ],
    lite: {
      userId: Cookies.get('userId'),
      userName,
      contextMenu: false,
      commands: liteButtons,
      isTracking: loadLite,
      tooltipTemplate: '%u %a %t %hh.%nn'
    },
    wordcount: {
      showParagraphs: false
    },
    imageUploadURL: `${process.env.REACT_APP_API_URL}/api/v1/file/upload-image`,
    allowedContent: true,
    extraAllowedContent: 'img'
  }

  const onChange = e => {
    const value = e.editor.getData()
    const lite = e.editor.plugins.lite.findPlugin(e.editor)
    const changes = lite.countChanges()
    setLiteChanges(changes)
    cb(value)
  }

  const value = initialValue || val

  return (
    <>
      <CKEditorFieldControls
        {...controlProps}
        val={value}
      />
      <div style={children ? { clear: 'both', display: 'flex' } : { clear: 'both' }}>
        <div className={classes}>
          {label && (
          <FormLabel filled>{label}</FormLabel>
          )}
          <CKEditor
            type="inline"
            data={value}
            config={config}
            onBeforeLoad={CKEDITOR => {
              CKEDITOR.disableAutoInline = true
            }}
            onChange={onChange}
          />
        </div>
        {children && (
        <div>
          {children}
        </div>
        )}
      </div>
    </>
  )
}

export default CKEditorFieldCompact
