import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import moment from "moment";
import SegmentVersionModal from "./SegmentVersionModal";
import Cookies from 'js-cookie'

const styles = {
  card: {
    width: '100%',
    backgroundColor: '#f8f8f8',
    alignSelf: 'flex-start'
  },
  title: {
    marginBottom: '10px'
  },
  link: {
    '&:visited': {
      color: 'rgb(0, 0, 238)'
    }
  },
  tableSmall: {
    cellPadding: '0',
    cellSpacing: '0',
    borderSpacing: '0',
    fontSize: '0.875rem'
  },
  bordered: {
    padding: '10px 0',
    margin: '0',
    borderBottom: '1px solid #ccc'
  }
}

const SegmentVersion = ({classes, versions, originalVersionId}) => (
  (versions.length > 0 && Cookies.get('role') !== 'expertReviewer') && (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="title" className={classes.title}>История изменений</Typography>
        <table className={classes.tableSmall}>
          <tbody>
          {versions && versions.map(({ id, createdAt, segmentId, author, status, comment }, index) => (
            (index < 3 && (
              <tr key={id}>
                <td className={classes.bordered}>
                  <Link to={{ pathname: `/version/${segmentId}/show` }} className={classes.link}>
                    {moment(createdAt).format('LLL')}
                  </Link>
                  &nbsp;
                </td>
                <td className={classes.bordered}>
                  {author.surname} {author.name}
                  &nbsp;
                </td>
                <td className={classes.bordered}>
                  {comment ? comment : status.action}
                </td>
              </tr>
            ))
          ))}
          </tbody>
        </table>

        <SegmentVersionModal versions={versions} />
      </CardContent>
    </Card>
  )
)

export default withStyles(styles)(SegmentVersion)
