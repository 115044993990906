import React from 'react'
import {
  List, Datagrid, TextField, EditButton, FunctionField
} from 'react-admin'

const TicketCriteriaList = props => (
  <List
    exporter={false}
    bulkActionButtons={false}
    pagination={false}
    {...props}
  >
    <Datagrid>
      <TextField source="module.name" label="Модуль" sortBy="moduleId" />
      <FunctionField
        label="Количество вопросов"
        render={({ module, count }) => module.levelId === 1 && count}
      />
      <EditButton label="" />
    </Datagrid>
  </List>
)

export default TicketCriteriaList
