import React from 'react'
import {
  CREATE, SimpleForm, Toolbar, SaveButton, ImageInput, ImageField, showNotification, withDataProvider
} from 'react-admin'

const ImageToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Сохранить изображение" {...props} />
  </Toolbar>
)

const ImageForm = props => {
  const handleClick = data => {
    const { dataProvider, dispatch } = props
    if (!data.image || !data.image.rawFile) {
      dispatch(showNotification('Файл не выбран', 'warning'))
    } else {
      dataProvider(CREATE, 'user', data, {
        onSuccess: {
          notification: { body: 'Изображение добавлено', level: 'info' },
          refresh: true
        },
        onFailure: {
          notification: { body: 'Произошла ошибка', level: 'warning' },
          refresh: true
        }
      })
    }
  }

  return (
    <SimpleForm toolbar={<ImageToolbar />} save={handleClick}>
      <ImageInput source="image" label="Новое изображение" accept="image/jpeg,image/png" maxSize={5242880}>
        <ImageField source="src" />
      </ImageInput>

    </SimpleForm>
  )
}

export default withDataProvider(ImageForm)
