import segmentsRoute from './module/segments'
import usersRoute from './module/users'
import rolesRoute from './module/roles'
import specializationsRoute from './module/specializations'
import imageUploadRoute from './module/imageUpload'
import resetPasswordRoute from './module/resetPassword'
import passwordRequests from './module/passwordRequests'
import importRoute from './module/import'
import commentsRoute from './module/comments'
import archiveRoute from './module/archive'
import examTicketsRoute from './module/examTickets'
import appointmentRoute from './module/appointment'
import ticketCommentsRoute from './module/ticketComments'
import testTypesRoute from './module/test-types'
import experts from './module/experts'
import examCheck from './module/exam-check'

export default async (type, resource, params = {}) => {
  switch (resource) {
    case 'segments':
      return segmentsRoute(type, resource, params)
    case 'users': {
      return usersRoute(type, resource, params)
    }
    case 'roles': {
      return rolesRoute(type, resource)
    }
    case 'specializations': {
      return specializationsRoute(type, resource)
    }
    case 'user': {
      return imageUploadRoute(type, resource, params)
    }
    case 'experts': {
      return experts(type, resource, params)
    }
    case 'reset-password-request': {
      return resetPasswordRoute(type, resource, params)
    }
    case 'password-requests': {
      return passwordRequests(type, resource, params)
    }
    case 'import': {
      return importRoute(type, resource, params)
    }
    case 'comments': {
      return commentsRoute(type, resource, params)
    }
    case 'archive': {
      return archiveRoute(type, 'segments', params)
    }
    case 'version': {
      return archiveRoute(type, 'segments', params)
    }
    case 'exam-tickets': {
      return examTicketsRoute(type, resource, params)
    }
    case 'exam-check': {
      return examCheck(type, resource, params)
    }
    case 'test-types': {
      return testTypesRoute(type, resource)
    }
    case 'ticket-criterias': {
      return usersRoute(type, resource, params)
    }
    case 'ticket-comments': {
      return ticketCommentsRoute(type, resource, params)
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }
}
