import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin'

import axios from 'axios'
import Cookies from 'js-cookie'
import { AxiosResponse } from './axiosResponse'
import { SERVER_URL } from './constants'

axios.defaults.baseURL = `${SERVER_URL}/api/v1`

enum ActionAuthType {
  'AUTH_LOGIN',
  'AUTH_LOGOUT',
  'AUTH_ERROR',
  'AUTH_CHECK',
  'AUTH_GET_PERMISSIONS'
}

interface IUser {
  id: number,
  name: string,
  surname: string,
  middleName: string,
  email: string,
  phone: string,
  password: string,
  role: string,
  status: string,
  image: null,
  accessToken: string,
  authKey: string,
  passwordResetToken: null,
  verificationToken: null,
  expiredResetToken: null,
  createdAt: string,
  updatedAt: string,
  specialization: null
}

export default async (type: ActionAuthType, params: any): Promise<void | AxiosResponse<IUser>> => {
  switch (type) {
    case AUTH_LOGIN:
      const { email, password } = params
      let response: AxiosResponse<IUser>
      try {
        response = await axios.post('/site/login', {
          headers: { 'Content-Type': 'application/json' },
          email,
          password,
        })
        if (response.status === 200) {
          const {
            data: {
              accessToken, role, id, name, middleName, surname
            }
          } = response
          localStorage.setItem('token', accessToken)
          Cookies.set('token', accessToken)
          Cookies.set('role', role)
          Cookies.set('userId', id)
          Cookies.set('userName', `${name} ${middleName} ${surname}`)
        }
      } catch (e) {
        throw new Error('Неверный email или пароль, попробуйте ещё раз')
      }
      return response
    case AUTH_LOGOUT:
      localStorage.removeItem('token')
      Cookies.remove('token')
      Cookies.remove('role')
      Cookies.remove('userId')
      Cookies.remove('userName')
      return Promise.resolve()
    case AUTH_ERROR:
      const { status } = params
      if (status === 401 || status === 403) {
        localStorage.removeItem('token')
        Cookies.remove('token')
        return Promise.reject()
      }
      return Promise.resolve()
    case AUTH_CHECK:
      const check = !!localStorage.getItem('token')
      return check ? Promise.resolve() : Promise.reject()
    case AUTH_GET_PERMISSIONS:
      const permission: any = {}
      const role = Cookies.get('role')
      const userId = Cookies.get('userId')
      permission.role = role
      permission.userId = userId
      return permission ? Promise.resolve(permission) : Promise.reject()
    default:
      return Promise.reject('Unknown method')
  }
}
