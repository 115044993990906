import React from 'react'

import Parser, { domToReact } from 'html-react-parser'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import formatHtml from '../../utils/formatHtml'
import ApplicanExamQuestionComments from './ApplicanExamQuestionComments'
import './ApplicantExamQuestionCase.css'

const styles = createStyles({
  questionNotation: {
    marginBottom: '16px',
  },
  head: {
    margin: '0px 0px 10px'
  },
  layout: {
    marginTop: '15px',
    padding: '20px'
  },
  title: {
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '15px'
  },
  markInput: {
    marginBottom: '20px',
  },
  filesWrap: {
    padding: '20px 0 0 0'
  },
  fileItem: {
    display: 'inline-block',
    padding: '5px 13px',
    border: '1px solid',
    color: '#000',
    textDecoration: 'none',
    borderRadius: '32px',
  },
  commentButttonWrap: {
    margin: '10px 0 0 0',
    textAlign: 'right'
  },
  commentWrap: {
    margin: '10px 0 20px 0',
  },
  panel: {
    border: '1px solid #ddd',
    marginBottom: '20px',
  },
  panelHeader: {
    backgroundColor: '#eee',
    color: '#333',
    padding: '10px'
  },
  panelHeaderBg: {
    backgroundColor: '#b71c1c',
    color: '#fff',
    padding: '10px'
  },
  panelBody: {
    padding: '15px'
  },
  tableResponsive: {
    overflowX: 'auto'
  }
})

const ApplicanExamQuestionCase = ({
  classes, activeQuestion, ticketId, dispatch
}) => {
  const { id: segmentId, case: { questions, task, module: { name } } } = activeQuestion

  const questionMarkChangeHandler = ({ id, balls, expertMark: { mark } }) => ({ target: { value } }) => {
    const isValidValue = (value >= 0 && value <= +balls && /^[0-9]+(\.([0-9]{1,2})?)?$/.test(value)) || !value

    dispatch({
      type: 'add_mark_to_question',
      payload: {
        segmentId,
        questionId: id,
        expertMark: isValidValue ? value : mark
      }
    })
  }

  const answerHtmlParserOptions = {
    replace: node => {
      if (node.name === 'td') {
        return (
          <td
            style={{ border: '1px solid #aaa', padding: '10px' }}
            colSpan={node.attribs.colspan}
            rowSpan={node.attribs.rowspan}
          >
            { domToReact(node.children) }
          </td>
        )
      }

      return node
    }
  }

  return (
    <>
      <Card className={classes.layout}>
        <h3 className={classes.head}>{name}</h3>
        {Parser(formatHtml(task.content))}
      </Card>
      {questions.map(question => (
        <div key={question.id}>
          <Card className={classes.layout}>
            <div className={classes.panel}>
              <div className={classes.panelHeader}>Вопрос</div>
              <div className={classes.panelBody}>
                <div className={classes.tableResponsive}>
                  {Parser(formatHtml(question.content))}
                </div>
              </div>
            </div>
            <br />
            {question.applicantAnswer && (
              <div className={classes.panel}>
                <div className={classes.panelHeaderBg}>Ответ претендента</div>
                <div className={classes.panelBody}>
                  <div className={classes.tableResponsive}>
                    {Parser(formatHtml(question.applicantAnswer.answer), answerHtmlParserOptions)}
                  </div>
                  <br />
                  <h4>Прикреплённые файлы:</h4>
                  {question.applicantAnswer && question.applicantAnswer.files && (
                      <p>
                        {question.applicantAnswer.files.map(({ id, link, name: fileName }) => (
                            <a
                                key={id}
                                className={classes.fileItem}
                                href="/"
                                onClick={event => {
                                  event.preventDefault()
                                  window.open(link, 'Download')
                                }}
                            >
                              {fileName}
                            </a>
                        ))}
                      </p>
                  )}
                </div>
              </div>
            )}
            <br />
            <div className={classes.panel}>
              <div className={classes.panelHeader}>Маркировка и оценка</div>
              <div className={classes.panelBody}>
                <div dangerouslySetInnerHTML={{ __html: question.mark }} />
                <TextField
                    className={classes.markInput}
                    label={`Оценка (0 - ${question.balls})`}
                    value={question.expertMark.mark || ''}
                    onChange={questionMarkChangeHandler(question)}
                />
                <ApplicanExamQuestionComments
                    dispatchR={dispatch}
                    questionId={question.id}
                    comments={question.comments}
                    segmentId={segmentId}
                    ticketId={ticketId}
                />
              </div>
            </div>
          </Card>
        </div>
      ))}
    </>
  )
}

export default withStyles(styles)(ApplicanExamQuestionCase)
