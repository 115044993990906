import React from 'react'
import Typography from '@material-ui/core/Typography'

const FullNameField = ({ record, type }) => (
  <Typography variant={type} component="span">
    {record.surname}
    {' '}
    {record.name}
    {' '}
    {record.middleName}
  </Typography>
)

export default FullNameField
