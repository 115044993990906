import React from 'react'
import { Create } from 'react-admin'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import SegmentHead from './segmentsType/header/SegmentHead'


const styles = () => createStyles({
  layout: {
    paddingTop: '10px',
    paddingLeft: '25px',
  },
})

const SegmentsCreate = ({ classes, ...props }) => (
  <Create {...props} title=" ">
    <div className={classes.layout}>
      <SegmentHead
        create
        typeSegmentId={7}
        test={{ }}
      />
    </div>
  </Create>
)

export default withStyles(styles)(SegmentsCreate)
