
import { stringify } from 'query-string'
import axios, { AxiosRequestConfig } from 'axios'
import { AxiosResponse } from '../axiosResponse'
import Cookies from 'js-cookie'

const importRoute = async (type, resource, params) => {
    const options: AxiosRequestConfig = {}

    switch (type) {
        case 'IMPORT': {
            const query = {
                token: `${localStorage.getItem('token')}`,
            }
            options.headers = { 'Content-Type': 'multipart/form-data' }
            options.url = `/segments/${resource}?${stringify(query)}`
            options.method = 'POST'

            const file = new FormData()
            file.append('file', params.files.rawFile)

            options.data = file
            break
        }
        default:
            throw new Error(`Unsupported Data Provider request type ${type}`)
    }

    let response: any
    response = await axios(options).catch(error => {
        const {
            response: {
                status: statusCode
            }
        } = error

        if (statusCode === 401) {
            localStorage.removeItem('token')
            Cookies.remove('token')
            Cookies.remove('role')
            Cookies.remove('userId')
            Cookies.remove('userName')
            return Promise.resolve()
        }
    })

    const { data } = response

    return data
}

export default importRoute
