import React from 'react'
import {
  TextField, SimpleShowLayout, ReferenceField, FunctionField
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import { formatHtml } from '../../../utils/getFields'
import SegmentVersion from '../segmentsType/header/SegmentVersion'
import SegmentWatch from '../SegmentWatch'
import ToolbarProvider from '../toolbarProvider/SegmentToolbarProvider'
import { dataProvider } from '../../../providers'
import SegmentCopy from '../SegmentCopy'
import moment from "moment";
import Comments from "../../../elements/comments";

const styles = {
  columnLeft: {
    '&:first-child': {
      paddingTop: '0',
      paddingLeft: '0'
    }
  },
  segmentView: {
    width: '327px'
  },
  formInputField: {
    padding: "10px 0",
    borderBottom: "1px dashed rgba(0, 0, 0, 0.38)"
  },
  title: {
    marginBottom: '5px'
  },
  subHeader: {
    color: '#777'
  },
  groupContent: {
    backgroundColor: '#f5f5f5',
    padding: '15px',
    marginBottom: '15px',

    '& del': {
      backgroundColor: 'rgb(254, 221, 222)'
    },

    '& ins': {
      backgroundColor: 'rgb(230, 254, 207)',
      textDecoration: 'none'
    }
  },
  flexGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  textDanger: {
    color: '#b71c1c'
  }
}

const changesHover = (node) => {
  const user = node.getAttribute('data-username')
  const changeUser = node.getAttribute('data-changedata')
  if (user != undefined) {
    const action = node.tagName === 'INS' ? 'Добавил(-а)' : 'Удалил(а)'
    node.setAttribute('title', action + ' ' + (user ? user : changeUser))
  }
}

const CaseSegmentShow = ({ classes, record, permissions }) => (
  <>
    <SimpleShowLayout record={record} basePath="archive">
      <div className={classes.flexGroup}>
        <div>
          {record.statusId !== 2 ? (
            <>
              <h2 className={classes.title}>Версия сегмента {record.originalId}</h2>
              <div className={classes.subHeader}>
                Изменён {moment(record.createdAt).format('LLL')}. {record.author.surname} {record.author.name}
              </div>
            </>
          ) : (
            <>
              <h2 className={classes.title}>Сегмент {record.id} (в архиве)</h2>
            </>
          )}
        </div>
        <div>
          <SegmentCopy
            segmentId={record.id}
          />
          <SegmentWatch
            type="case"
            segment={{
              task: record.case.task.content,
              questions: record.case.questions
            }}
          />
        </div>
      </div>
      <ReferenceField
        reference="test-types"
        source="typeId"
        linkType={false}
        label="Тип"
      >
        <TextField class={classes.formInputField} source="name" />
      </ReferenceField>

      <div className={classes.flexGroup}>
        <section>
          <SimpleShowLayout
            record={record}
            basePath="/archive"
            className={classes.columnLeft}
          >
            <TextField
              source="case.module.name"
              label="Модуль"
            />
            <TextField
              className={classes.textDanger}
              source="reasonDeactivated"
              label="Причина архивации"
            />
            <TextField
              source="case.taskType.name"
              label="Тип задания"
            />
          </SimpleShowLayout>
        </section>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className={classes.segmentView}>
            <SegmentVersion
              versions={record.versions}
              originalVersionId={record.originalId}
            />
          </div>
        </div>
      </div>
      <br />
      <div className={classes.groupContent}>
        <div><small className={classes.subHeader}>Задание</small></div>
        <div
          dangerouslySetInnerHTML={{
            __html: record.case.task.content
          }}
          onMouseMove={(event) => {changesHover(event.target)}}
        />
        <Comments id={record.case.task.id} idType="caseTaskId" showAddBlock={false} originalComment={true} />
      </div>
      {record.case.questions.map((question, i) => (
        <SimpleShowLayout record={record} basePath="archive">
          <div className={classes.groupContent}>
            <div><small className={classes.subHeader}>Подвопрос {i + 1}</small></div>
            <div
              dangerouslySetInnerHTML={{
                __html: question.content
              }}
              onMouseMove={(event) => {changesHover(event.target)}}
            />
            <Comments id={question.id} idType="caseQuestionId" entityForCase="null" showAddBlock={false} originalComment={true} />
            <hr /><br />

            <div><small className={classes.subHeader}>Ответ</small></div>
            <div
              dangerouslySetInnerHTML={{
                __html: question.answer
              }}
              onMouseMove={(event) => {changesHover(event.target)}}
            />
            <Comments id={question.id} idType="caseQuestionId" entityForCase="answer" showAddBlock={false} originalComment={true} />
            <hr /><br />

            <div><small className={classes.subHeader}>Маркировка</small></div>
            <div
              dangerouslySetInnerHTML={{
                __html: question.mark
              }}
              onMouseMove={(event) => {changesHover(event.target)}}
            />
            <Comments id={question.id} idType="caseQuestionId" entityForCase="mark" showAddBlock={false} originalComment={true} />
            <hr /><br />

            <div><small className={classes.subHeader}>Баллы</small></div>
            <div
              dangerouslySetInnerHTML={{
                __html: question.balls
              }}
              onMouseMove={(event) => {changesHover(event.target)}}
            />
          </div>
        </SimpleShowLayout>
      ))}
    </SimpleShowLayout>
    <div style={{ margin: 10 }}>
      <ToolbarProvider
        verifyStatus={record.verifyStatus}
        dataProvider={dataProvider}
        permissions={permissions}
        status={record.status}
        segmentId={record.id}
      />
    </div>
  </>
)

export default withStyles(styles)(CaseSegmentShow)
