import React from 'react'
import { connect } from 'react-redux'
import { AppBar, MenuItemLink, userLogout } from 'react-admin'
import Cookies from 'js-cookie'
import Button from '@material-ui/core/Button'
import ExitIcon from '@material-ui/icons/PowerSettingsNew'
import Tooltip from '@material-ui/core/Tooltip'
import Refresh from '@material-ui/icons/Refresh'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

const CustomAppBar = ({ userLogout: logout }) => {
  const userId = Cookies.get('userId')
  const userName = Cookies.get('userName')
  const refreshPage = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }

  return (
    <AppBar color="primary" title="ЕАК">
      <Tooltip title="Профиль">
        <MenuItemLink
          to={`/users/${userId}/show`}
          primaryText={userName}
          style={{ color: 'white', marginLeft: 'auto' }}
          leftIcon={<AccountCircleIcon />}
        />
      </Tooltip>
      <Tooltip title="Выйти">
        <Button onClick={logout}>
          <ExitIcon style={{ fill: 'white' }} />
        </Button>
      </Tooltip>
      <Tooltip title="Обновить">
        <Button onClick={refreshPage}>
          <Refresh style={{ fill: 'white' }} />
        </Button>
      </Tooltip>
    </AppBar>
  )
}

const redirectTo = '/login'
const customUserLogout = () => userLogout(redirectTo)
export default connect(undefined, { userLogout: customUserLogout })(CustomAppBar)
