import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import { formatHtml } from '../../utils/getFields'
import FileInfo from '../../elements/files/FileInfo'

const styles = () => createStyles({
  previewButtonWrap: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '15px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'baseline'
  },
  numeric: {
    marginRight: '10px'
  }
})

const SegmentWatch = ({
  classes,
  segment,
  type,
}: any) => {
  const isCase = type === 'case'
  const { task, answers, questions } = segment
  const [showPreview, setShowPreview] = useState<boolean>(false)

  return (
    <>
      <div className={classes.previewButtonWrap}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setShowPreview(true)}
        >
          Предварительный просмотр
        </Button>
      </div>
      <Dialog
        open={showPreview}
        onClose={() => setShowPreview(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle>Предварительный просмотр</DialogTitle>
        <DialogContent>
          {isCase ? (
            <>
              Задание
              :
              <div dangerouslySetInnerHTML={{ __html: formatHtml(task) }} />
              <br />
              {questions.map(question => (
                <>
                  {`Подвопрос ${question.number}:`}
                  <div dangerouslySetInnerHTML={{ __html: formatHtml(question.content) }} />
                  <br />
                  {question.attaches.map(({
                    id,
                    name,
                    filename,
                    size
                  }) => (
                    <>
                      {' '}
                      <FileInfo
                        key={id}
                        title={name}
                        source={filename}
                        size={size}
                        showRemove={false}
                      />
                    </>
                  ))}
                </>
              ))}
            </>
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: formatHtml(task) }} />
              <br />
              {answers && answers.map(answer => (
                <div key={answer.id}>
                  {answer.correctPosition ? (
                    <div className={classes.wrap}>
                      <div className={classes.numeric}>
                        {answer.correctPosition}
                          )
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: formatHtml(answer.content) }} />
                    </div>
                  ) : (
                    <div className={classes.wrap}>
                      <div>
                        <Checkbox
                          disableRipple
                          checked={answer.correct || false}
                        />
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: formatHtml(answer.content) }} />
                    </div>
                  )}
                </div>
              ))}
              {questions && questions.map(item => (
                <div key={item.id}>
                  <div className={classes.wrap}>
                    <div className={classes.numeric}>
                      {item.number}
                        )
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: formatHtml(item.content) }} />
                  </div>
                  <>
                    {item.answers.map(answer => (
                      <div key={answer.id}>
                        {answer.correctPosition ? (
                          <div className={classes.wrap}>
                            <div className={classes.numeric}>
                              {answer.correctPosition}
                                )
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: formatHtml(answer.content) }} />
                          </div>
                        ) : (
                          <div className={classes.wrap}>
                            <div>
                              <Checkbox
                                disableRipple
                                checked={answer.correct || false}
                              />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: formatHtml(answer.content) }} />
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                </div>
              ))}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(SegmentWatch)
