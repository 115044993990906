import React from 'react'
import { List, withDataProvider } from 'react-admin'
import Pagination from '../../elements/Pagination'
import SegmentsListFilters from './SegmentsListFilters'
import Segments from './Segments'
import useTestTypesList from '../../utils/useTestTypesList'
import useCompetencesList from '../../utils/useCompetencesList'
import useCriteriasList from '../../utils/useCriteriasList'
import useModulesList from '../../utils/useModulesList'

interface ISegmentsList {
  statusId: any,
  assignId: number,
  dataProvider: any,
  dispatch: any,
  isArchived?: boolean
}

const SegmentsList = ({
  statusId,
  assignId,
  dispatch,
  dataProvider,
  isArchived,
  ...props
}: ISegmentsList) => {
  const types = useTestTypesList()
  const competences = useCompetencesList()
  const criterias = useCriteriasList()
  const modules = useModulesList()

  const levelTypes = [
    { id: 'test', name: 'Базовый уровень' },
    { id: 'case', name: 'Основной уровень' },
    { id: 'situation', name: 'Квалификационный уровень' }
  ]

  return (
    <>
      <List
        {...props}
        exporter={false}
        filter={!isArchived && { statusId, assignId }}
        filters={(
          <SegmentsListFilters
            levelTypes={levelTypes}
            statusId={statusId}
            isArchived={false}
          />
        )}
        pagination={<Pagination rows={[10, 20, 50]} />}
        bulkActionButtons={false}
      >
        <Segments
          dispatch={dispatch}
          dataProvider={dataProvider}
          types={types}
          competences={competences}
          criterias={criterias}
          modules={modules}
          isArchived={isArchived}
          statusId={statusId}
        />
      </List>
    </>
  )
}

export default withDataProvider(SegmentsList)
