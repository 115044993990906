import React from 'react'
import { Create } from 'react-admin'
import TicketCriteriaForm from './TicketCriteriaForm'

const TicketCriteriaCreate = props => (
  <Create {...props}>
    <TicketCriteriaForm formType="create" />
  </Create>
)

export default TicketCriteriaCreate
