import { useEffect, useRef } from 'react'

const useIsMounted = function useIsMounted() {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return function cleanupSetIsMounted() {
      isMounted.current = false
    }
  }, [])

  return isMounted
}

const useUpdateEffect = function useUpdateEffect(effect, dependencies) {
  const isMounted = useIsMounted()
  const isInitialMount = useRef(true)

  useEffect(() => {
    let effectCleanupFunc = function noop() {}

    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      effectCleanupFunc = effect() || effectCleanupFunc
    }
    return () => {
      effectCleanupFunc()
      if (!isMounted.current) {
        isInitialMount.current = true
      }
    }
  }, dependencies)
}

export default useUpdateEffect
