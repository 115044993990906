import React from 'react'
import {
  List,
  withDataProvider,
  TextField,
  Datagrid,
  FunctionField,
  ShowButton,
  UPDATE,
  DELETE,
  showNotification,
  setListSelectedIds
} from 'react-admin'
import Text from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'react-router-redux'
import { removeHtml } from '../../utils/getFields'

const STATUS_EXAM = 11

const styles = () => createStyles({
  questionText: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    margin: '10px 0'
  }
})

const CaseSegmentsActions = withDataProvider(props => {
  const { dataProvider, selectedIds, moduleId, id, dispatch, isLoading } = props

  const onGenerateClick = () => {
    dataProvider(UPDATE, 'ticket-criterias', { id, data: { segments: selectedIds, moduleId } })
      .then(() => {
        dispatch(setListSelectedIds('segments', []))
        dispatch(showNotification('Билет сформирован'))
        dispatch(push('/ticket-criterias'))
      })
      .catch(({ message }) => {
        dispatch(showNotification(message, 'warning'))
      })
  }

  const onDeleteClick = () => {
    dataProvider(DELETE, 'ticket-criterias', { id })
      .then(() => {
        dispatch(showNotification('Элемент удален'))
        dispatch(push('/ticket-criterias'))
      })
  }

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        resource="/ticket-criterias"
        onClick={onGenerateClick}
        disabled={selectedIds.length === 0 || isLoading}
        style={{ marginLeft: 'auto', marginRight: 10 }}
      >
        Сформировать билет
      </Button>
      <Button
        color="primary"
        onClick={onDeleteClick}
      >
        <DeleteIcon style={{ marginRight: 5 }} />
        Удалить
      </Button>
    </>
  )
})

const CaseSegmentsList = ({ RAprops, record, classes }) => {
  const { moduleId, id } = record

  return (
    <>
      <Text
        value={record.module.name}
        label="Модуль"
        margin="normal"
        disabled
        style={{ width: 'calc(100% - 20px)', paddingLeft: 10 }}
        InputLabelProps={{ style: { paddingLeft: 10 } }}
      />
      <List
        basePath="segments"
        {...RAprops}
        bulkActionButtons={null}
        actions={<CaseSegmentsActions moduleId={moduleId} id={id} />}
        exporter={false}
        hasCreate={false}
        hasEdit={false}
        hasList={false}
        hasShow={false}
        resource="segments"
        filter={{ moduleId, statusId: STATUS_EXAM, type: 'case' }}
      >
        <Datagrid>
          <TextField source="id" label="Номер сегмента" sortable={false} />
          <FunctionField
            render={segment => <span className={classes.questionText} dangerouslySetInnerHTML={{ __html: removeHtml(segment.case.task.content) as string }} />}
            label="Текст вопроса"
          />
          <ShowButton
            basePath="/version"
            label=""
          />
        </Datagrid>
      </List>
    </>
  )
}

export default withStyles(styles)(CaseSegmentsList)
