import React, { useState, useEffect, useReducer } from 'react'

import {
  withDataProvider,
  Show,
  CREATE,
  UPDATE
} from 'react-admin'

import Card from '@material-ui/core/Card'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import ApplicantExamStepper from './ApplicantExamStepper'
import ApplicanExamQuestion from './ApplicanExamQuestion'
import PageTitle from './PageTitle'


const styles = () => createStyles({
  examTicketsTitle: {
    fontSize: 48,
    fontWeight: 400,
    marginBottom: 20
  },
  examBallsTitle: {
    fontSize: 38,
    fontWeight: 400,
    marginBottom: 20
  },
  expertsButton: {
    marginTop: 30,
    width: 210,
    marginLeft: 'auto'
  },
  wrap: {
    padding: '20px',
  },
  layout: {
    marginTop: '15px',
    padding: '20px'
  },
  head: {
    display: 'flex',
    padding: '20px 0 10px 20px',
    justifyContent: 'space-between',
  },
  main: {
    '& > div': {
      background: 'none',
      boxShadow: 'none'
    },
  },
  bottom: {
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'space-between'
  },
  title: {
    margin: '0 0 20px 0',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  input: {
    margin: '0 0 20px 0',

  }
})


let questionCount
let questionLeftCount

const ExamTicketCheckPage = ({
  dataProvider, classes, record
}: any) => {
  const [selectedStatus, setSelectedStatus] = useState(0)
  const [selectedList] = useState([{
    id: 1,
    name: 'Не проверена',
    value: 'empty',
  },
  {
    id: 2,
    name: 'На проверке',
    value: 'verify',
  },
  {
    id: 3,
    name: 'В работе',
    value: 'work',
  },
  {
    id: 4,
    name: 'Проверена',
    value: 'complete',
  },
  {
    id: 5,
    name: 'Отменена',
    value: 'cancel',
  },
  ])

  useEffect(() => {
    const idCurrentStatus = selectedList.filter(status => status.value === record.verifyStatus)[0].id

    setSelectedStatus(idCurrentStatus)
  }, [record])

  const getAssigner = ({ assign }) => (assign ? `${assign.surname} ${assign.name} ${assign.middleName}`.trim() : ' ')

  const handleChange = async event => {
    setSelectedStatus(event.target.value)
    const currentStatus = selectedList.filter(item => item.id === event.target.value)[0].value

    await dataProvider(UPDATE, 'exam-tickets', {
      id: record.id,
      data: {
        verifyStatus: currentStatus
      }
    })
  }

  return (
    <>
      <Card className={classes.wrap}>
        <h2 className={classes.title}>{`Информация об экзаменационной работе №${record.ticket.number}`}</h2>
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <TextField
              className={classes.input}
              label="Назначен на"
              disabled
              value={getAssigner(record)}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              className={classes.input}
              label="Дата назначние проверки"
              disabled
              value={record.assignedAt || ' '}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              className={classes.input}
              label="Дата экзамена"
              disabled
              value={moment(record.exam.examDate).format('DD.MM.YYYY')}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <TextField
              className={classes.input}
              label="Уровень"
              disabled
              value={record.exam.level.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              className={classes.input}
              label="Модуль"
              disabled
              value={record.exam.modules[0].name}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={4}>
            {questionCount && (
              <TextField
                className={classes.input}
                label="Прогресс"
                disabled
                fullWidth
                value={`${questionCount - questionLeftCount} проверено из ${questionCount}`}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <div style={{
              paddingTop: '16px'
            }}
            >
              <Select
                id="status"
                onChange={handleChange}
                value={selectedStatus}
                fullWidth
              >
                {selectedList && selectedList.map(expert => (
                  <MenuItem value={expert.id}>{expert.name}</MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item xs={2}>
            <TextField
              className={classes.input}
              label="Общая оценка"
              disabled
              value={record.mark}
              fullWidth
            />
          </Grid>
        </Grid>
      </Card>
      <ExamTicketQuestion dataProvider={dataProvider} classes={classes} record={record} />
    </>
  )
}

function reducer(state, { type, payload }) {
  switch (type) {
    case 'set_segments':
      return payload.segments
    case 'add_mark':
      return [...state.map(item => {
        if (payload.segmentId === item.id) {
          return {
            ...item,
            expertMark: {
              ...item.expertMark,
              mark: payload.expertMark
            }
          }
        }
        return item
      })]
    case 'set_chechk':
      return [...state.map(item => {
        if (payload.segmentId === item.id) {
          return {
            ...item,
            expertMark: {
              ...item.expertMark,
              isCreated: true
            }
          }
        }
        return item
      })]
    case 'add_comment':
      return [...state.map(item => {
        if (payload.segmentId === item.id) {
          return {
            ...item,
            comments: [...item.comments, payload.comment]
          }
        }
        return item
      })]
    case 'add_mark_to_question':
      return [...state.map(item => {
        if (payload.segmentId === item.id) {
          return {
            ...item,
            case: {
              ...item.case,
              questions: item.case.questions.map(question => {
                if (payload.questionId === question.id) {
                  return {
                    ...question,
                    expertMark: {
                      ...question.expertMark,
                      mark: payload.expertMark
                    }
                  }
                }
                return question
              })
            }
          }
        }
        return item
      })]
    case 'add_сomment_to_question':
      return [...state.map(item => {
        if (payload.segmentId === item.id) {
          return {
            ...item,
            case: {
              ...item.case,
              questions: item.case.questions.map(question => {
                if (payload.questionId === question.id) {
                  return {
                    ...question,
                    comments: [...question.comments, payload.comment]
                  }
                }
                return question
              })
            }
          }
        }
        return item
      })]
    default:
      throw new Error()
  }
}

const ExamTicketQuestion = ({ dataProvider, classes, record }: any) => {
  const [completeQuestion, setCompleteQuestion] = useState([])
  const [currentPostion, setCurrentPostion] = useState(1)
  const [activeQuestion, setActiveQuestion] = useState()
  const [questionList, dispatch] = useReducer(reducer, record.segments)

  const getNextElement = currentPositionNumber => {
    if (questionList) {
      const currentElement = questionList[currentPositionNumber]
      if (!currentElement) {
        return getNextElement(0)
      }

      if (!currentElement.expertMark.isCreated) {
        setCurrentPostion(currentPositionNumber + 1)
        return currentElement
      }

      return getNextElement(currentPositionNumber + 1)
    }
  }

  useEffect(() => {
    if (record.segments) {
      dispatch({ type: 'set_segments', payload: { segments: record.segments } })

      const answeredQuestionList = record.segments.filter(question => question.expertMark.isCreated)
      setCompleteQuestion(answeredQuestionList)
      setActiveQuestion(record.segments[0])
    }
  }, [record.segments])

  useEffect(() => {
    if (questionList && activeQuestion) {
      const newActiveQuestion = questionList.filter(question => {
        if (question.id === activeQuestion.id) {
          return question
        }
      })
      setActiveQuestion(newActiveQuestion[0])
    }
  }, [questionList])

  if (!questionList) return null

  questionCount = questionList.length
  questionLeftCount = questionCount - completeQuestion.length


  const getCurrentElementPosition = () => {
    const searchTerm = activeQuestion.id
    let index = -1
    for (let i = 0, len = questionList.length; i < len; i++) {
      if (questionList[i].id === searchTerm) {
        index = i
        break
      }
    }

    return index + 1
  }

  const nextQuestion = () => {
    const nextElem = getNextElement(getCurrentElementPosition())

    if (nextElem) setActiveQuestion(nextElem)
  }

  const saveQuestion = async () => {
    const activeQuestionId = activeQuestion.id
    setCompleteQuestion([
      ...completeQuestion.filter(q => (q.activeQuestionId || q.id) !== activeQuestionId),
      { activeQuestionId }
    ])

    if (questionLeftCount >= 1 && activeQuestion.type === 'test') nextQuestion()

    dispatch({ type: 'set_chechk', payload: { segmentId: activeQuestion.id } })
    try {
      if (activeQuestion.type === 'case') {
        const currentMark = activeQuestion.case.questions.map(question => question.expertMark)
        await dataProvider(CREATE, 'exam-tickets', {
          id: record.id,
          data: currentMark,
        })
      } else {
        await dataProvider(CREATE, 'exam-tickets', {
          id: record.id,
          data: activeQuestion.expertMark,
        })
      }
    } catch (e) {
      console.error(e)
    }

    dataProvider(UPDATE, 'exam-tickets', {
      id: record.id,
      data: {}
    })
  }

  return (
    <>
      <PageTitle title={`Вопрос ${currentPostion}`} />
      <ApplicantExamStepper
        questionList={questionList}
        activeQuestionId={activeQuestion && activeQuestion.id}
        setActiveQuestion={setActiveQuestion}
        setCurrentPostion={setCurrentPostion}
      />

      {activeQuestion && (<ApplicanExamQuestion dispatch={dispatch} activeQuestion={activeQuestion} ticketId={record.ticket.id} />)}

      <div className={classes.bottom}>
        <Button
          variant="contained"
          onClick={nextQuestion}
        >
          Пропустить
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={saveQuestion}
        >
          Оценить ответ
        </Button>
      </div>
    </>
  )
}

const ExamTicketCheck = withStyles(styles)(({
  record, dataProvider, classes, ...props
}: any) => (
  <>
    <Typography
      variant="title"
      className={classes.examTicketsTitle}
    >
        Список работ на проверку
    </Typography>
    <Show title="test" {...props} className={classes.main}>
      <ExamTicketCheckPage classes={classes} dataProvider={dataProvider} />
    </Show>
  </>
))

export default withDataProvider(withDataProvider(ExamTicketCheck))
