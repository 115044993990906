import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {
  withDataProvider,
  showNotification
} from 'react-admin'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
})

const onSuccess = {
  onSuccess: {
    notification: { body: 'Сохранено', level: 'info' },
  },
  onError: {
    notification: { body: 'Произошла ошибка', level: 'warning' },
  },
  refresh: true
}

const AttachFileButton = ({
  classes,
  accept,
  id,
  idSuffix = '',
  label,
  fileType,
  dataProvider,
  dispatch
}) => {
  const [disabled, setDisabled] = useState(false)
  const [file, setFile] = useState({ size: 0 })
  const [changed, setChanged] = useState(false)


  useEffect(() => {
    (async () => {
      if (changed) {
        if (file.size < 20971520) {
          setDisabled(true)
          const result = await dataProvider('ATTACH_FILE', 'segments', {
            id,
            url: 'case-questions/attach',
            data: {
              file,
              type: fileType
            }
          }, { onSuccess })

          dispatch({
            type: 'uploadAttach',
            data: result.data,
            id,
            attachesValueKey: fileType && `${fileType}Attaches`
          })

          showNotification('Файл загружен', 'info')
          setDisabled(false)
        } else {
          showNotification('Превышен максимально допустимый размер файла (20 Мб.)', 'warning')
        }
      }
      setChanged(false)
    })()
  }, [file])

  const attachFile = event => {
    setChanged(true)
    setFile(event.target.files[0])
    event.target.value = null
  }

  return (
    <>
      <input
        type="file"
        onChange={attachFile}
        accept={accept}
        id={`case-segment-file-form-${id + idSuffix}`}
        className={classes.input}
      />
      <label htmlFor={`case-segment-file-form-${id + idSuffix}`}>
        <Button variant="raised" component="span" disabled={disabled}>
          {label}
        </Button>
      </label>
    </>
  )
}

export default withStyles(styles)(AttachFileButton)
