import * as React from 'react'
import {
  Table, TableBody, TableRow, TableCell, TableHead, WithStyles
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { red, green } from '@material-ui/core/colors'

import moment from 'moment'
import 'moment/locale/ru'

const styles = {
  success: {
    backgroundColor: green[300],
  },
  fail: {
    backgroundColor: red[400],
  }
}

interface IProps extends WithStyles {
  segments?: any
}

const ImportTable = withStyles(styles)(({ segments, classes }: IProps) => {
  const getDate = date => moment(date).format('l')

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">Номер</TableCell>
          <TableCell align="right">Данные</TableCell>
          <TableCell align="right">Время</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {segments.map(row => {
          const { segment } = row
          if (!segment) {
            return (
              <TableRow key={row.number} className={classes.fail}>
                <TableCell component="th" scope="row">
                                Сегмент №
                  {row.number}
                </TableCell>
                <TableCell component="th" scope="row" />
                <TableCell component="th" scope="row" />
              </TableRow>
            )
          }
          return (
            <TableRow key={row.number} className={classes[row.status]}>
              <TableCell component="th" scope="row">
                                Сегмент №
                {row.number}
              </TableCell>
              <TableCell>
                {segment.type && segment.type.name
                                    && (
                                    <div>
Тип сегмента:
                                      {' '}
                                      {segment.type.name}
                                    </div>
                                    )}

                {segment.competence && segment.competence.name
                                    && (
                                    <div>
Компетенция:
                                      {' '}
                                      {segment.competence.name}
                                    </div>
                                    )}

                {segment.module && segment.module.name
                                    && (
                                    <div>
Модуль:
                                      {' '}
                                      {segment.module.name}
                                    </div>
                                    )}


              </TableCell>
              <TableCell>
                {segment.createdAt && <div>{getDate(segment.createdAt)}</div>}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
})


export default ImportTable
