import React, { useState } from 'react'
import * as Yup from 'yup'
import {
  DELETE, CREATE, withDataProvider, SimpleForm, TextInput
} from 'react-admin'
import CardActions from '@material-ui/core/CardActions'
import CustomListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { withStyles } from '@material-ui/core/styles'
import { WithStyles } from '@material-ui/core'
import { ListItem, FullNameField } from '../../elements'
import { getDateTime, getDate } from '../../utils/getFields'

interface IProps extends WithStyles {
  id: number,
  data?: any,
  dataProvider?: any,
  selected?: boolean,
  onToggleItem?:(id: number) => void
}

const styles = {
  card: {
    marginBottom: '10px',
    padding: '10px',
    minHeight: '150px',
    flexGrow: 1
  },
  ticketTitle: {
    fontWeight: 500
  },
  listItem: {
    paddingTop: '0',
    paddingBottom: '0'
  },
  cardActions: {
    marginLeft: 'auto',
    alignSelf: 'flex-end'
  }
}

const RequestItem = ({
  id, data, classes, dataProvider, selected, onToggleItem
}: IProps) => {
  const [expanded, setExpanded] = useState(false)

  const handleReject = itemId => {
    dataProvider(DELETE, 'password-requests', { id: itemId }, {
      onSuccess: {
        notification: { body: 'Запрос удален', level: 'info' },
        refresh: true
      },
      onFailure: {
        notification: { body: 'Произошла ошибка, попробуйте снова', level: 'warning' }
      }
    })
  }

  const handleSave = (itemId, passwords) => {
    dataProvider(CREATE, 'password-requests', { id: itemId, data: passwords }, {
      onSuccess: {
        notification: { body: 'Пароль успешно изменен', level: 'info' },
        refresh: true
      },
      onFailure: {
        notification: { body: 'Произошла ошибка, попробуйте снова', level: 'warning' }
      }
    })
  }

  const schema = Yup.object().shape({
    password: Yup.string()
      .required('Обязательное поле'),
    passwordRepeat: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать'),
  })

  const asyncValidate = values => new Promise((resolve, reject) => {
    schema.validate(values, { abortEarly: false })
      .then(() => {
        resolve()
      })
      .catch(errors => {
        const rErrors = {}

        errors.inner.forEach(error => {
          rErrors[error.path] = error.message
        })
        reject(rErrors)
      })
  })


  return (
    <ExpansionPanel expanded={expanded} className={classes.card}>
      <ExpansionPanelSummary className={classes.summary}>
        <Checkbox
          checked={selected}
          color="primary"
          onClick={e => e.stopPropagation()}
          onChange={() => onToggleItem(data[id].id)}
        />
        <div>
          <CustomListItem className={classes.listItem}>
            <Typography className={classes.ticketTitle}>
                  Запрос №
              {data[id].id}
            </Typography>
          </CustomListItem>
          <ListItem
            record={data[id]}
            source="createdAt"
            label="Дата и время запроса:"
            cb={getDateTime}
          />
          <ListItem
            record={data[id]}
            source="user.email"
            label="Пользователь:"
          />
          <CustomListItem className={classes.listItem}>
            <Typography component="span" className={classes.label}>
                  ФИО:&nbsp;
            </Typography>
            <FullNameField record={data[id].user} type="body1" />
          </CustomListItem>
          <ListItem
            record={data[id]}
            source="user.phone"
            label="Телефон:"
          />
          <ListItem
            record={data[id]}
            source="user.createdAt"
            label="Дата регистрации:"
            cb={getDate}
          />
          <ListItem
            record={data[id]}
            source="user.updatedAt"
            label="Дата последней активности:"
            cb={getDate}
          />
        </div>
        <CardActions className={classes.cardActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Закрыть' : 'Принять'}
          </Button>
          <Button
            onClick={() => handleReject(data[id].id)}
            variant="contained"
          >
            Отклонить
          </Button>
        </CardActions>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <SimpleForm
          save={passwords => handleSave(data[id].id, passwords)}
          asyncValidate={asyncValidate}
          form={`form-${data[id].id}`}
        >
          <TextInput source="password" label="Пароль" type="password" />
          <TextInput source="passwordRepeat" label="Повторите пароль" type="password" />
        </SimpleForm>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const StyledRequestItem = withStyles(styles)(RequestItem)

export default withDataProvider(StyledRequestItem)
