import React, { useState, useEffect } from 'react'

import Parser from 'html-react-parser'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import formatHtml from '../../utils/formatHtml'

const RadioCheckbox = withStyles({
  root: {
    '&$checked': {
      color: 'green'
    },
  },
  checked: {},
})((props: any): any => <Radio color="default" {...props} />);

const styles = createStyles({
  wrap: {
    display: 'flex',
    alignItems: 'center'
  },
})

const ApplicanExamAnswersСomposite = ({ questions, classes }) => {
  return (
    <FormControl>
      <FormGroup>
        {questions.map(question => (
          <div key={question.id}>
            <div className={classes.wrap} dangerouslySetInnerHTML={{ __html: `${question.number} ) ${formatHtml(question.content)}` }} />
            {question.answers.map(answer => (
              <FormControlLabel key={answer.id} value={String(answer.id)} name={String(answer.id)} control={<RadioCheckbox checked={answer.applicantAnswer ? answer.applicantAnswer : false} />} label={<Label answer={answer} />} />
            ))}

          </div>
        ))}
      </FormGroup>
    </FormControl>
  )
}

const Label = ({ answer }) => {
  let style = {}

  if (answer.correct) {
    style = { color: 'green' }
  } else if (!answer.correct && answer.applicantAnswer) {
    style = { color: 'red' }
  }

  return (
    <div style={style}>
      {Parser(formatHtml(answer.content))}
    </div>
  )
}

export default withStyles(styles)(ApplicanExamAnswersСomposite)
