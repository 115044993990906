import React from 'react'
import * as Yup from 'yup'
import {
  SimpleForm, TextInput, Toolbar, SaveButton
} from 'react-admin'

const PasswordToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Изменить пароль" {...props} />
  </Toolbar>
)

const UserPasswordForm = props => {
  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Обязательное поле'),
    passwordRepeat: Yup.string()
      .required('Обязательное поле')
      .oneOf([Yup.ref('newPassword'), null], 'Пароли должны совпадать'),
  })

  const validate = values => new Promise((resolve, reject) => {
    schema.validate(values, { abortEarly: false })
      .then(() => {
        resolve()
      })
      .catch(errors => {
        const rErrors = {}
        errors.inner.forEach(error => {
          rErrors[error.path] = error.message
        })
        reject(rErrors)
      })
  })

  return (
    <SimpleForm
      toolbar={<PasswordToolbar />}
      asyncValidate={validate}
      redirect={false}
      {...props}
    >
      <TextInput
        source="newPassword"
        label="Пароль"
        type="password"
        fullWidth
      />
      <TextInput
        source="passwordRepeat"
        label="Повторите пароль"
        type="password"
        fullWidth
      />
    </SimpleForm>
  )
}

export default UserPasswordForm
