import React from 'react'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import AttachFile from '@material-ui/icons/AttachFile'
import Close from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import { SERVER_URL } from '../../providers/constants'

const styles = () => createStyles({
  fileInfoWrap: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    paddingBottom: '10px'
  },
  removeIcon: {
    cursor: 'pointer',
    color: 'red',
    alignSelf: 'flex-start',
    fontSize: '22px'
  },
  typeIcon: {
    cursor: 'pointer',
    fontSize: '35px',
  },
  fileLink: {
    color: '#3f51b5',
    textDecoration: 'none'
  }
})

const onSuccess = {
  onSuccess: {
    notification: { body: 'Сохранено', level: 'info' },
  },
  onError: {
    notification: { body: 'Произошла ошибка', level: 'warning' },
  },
  refresh: true
}

const Icon = ({ className, filename }) => {
  const type = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
  switch (type) {
    case 'pdf': {
      return <PdfIcon className={className} />
    }
    default:
      return <AttachFile className={className} />
  }
}

const FileInfo = ({
  classes,
  title,
  source,
  showRemove = true,
  attachId,
  id,
  fileUrl,
  fileType,
  dataProvider,
  dispatch
}) => {
  const deleteFile = async () => {
    await dataProvider('REMOVE_FILE', 'segments', {
      url: 'case-question',
      attachId
    }, onSuccess)

    dispatch({
      type: 'removeAttach',
      attachId,
      id,
      attachesValueKey: fileType && `${fileType}Attaches`
    })
  }

  const downloadFile = e => {
    e.preventDefault()
    window.open(fileUrl || `//${SERVER_URL}/${source}`, 'Download')
  }

  return (
    <div className={classes.fileInfoWrap}>
      <a
        href="/"
        className={classes.fileLink}
        onClick={downloadFile}
      >
        <Icon className={classes.typeIcon} filename={source} />
      </a>
      {title}
      {showRemove && (
        <Close className={classes.removeIcon} onClick={deleteFile} />
      )}
    </div>
  )
}

export default withStyles(styles)(FileInfo)
