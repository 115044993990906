import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useCriteriasList = (): ICriteriasList[] => {
  const [arrayCriterias, setArrayCriterias] = useState<ICriteriasList[]>([])

  useEffect(() => {
    (async () => {
      const programsList = await rolesRoute(GET_LIST, 'criterias')
      setArrayCriterias(programsList.data)
    })()
  }, [])

  return arrayCriterias
}

export default useCriteriasList
