import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'

import {
  withDataProvider,
  showNotification,
  GET_LIST
} from 'react-admin'

import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ASSIGN_EXPERT } from '../../providers/constants'
import UsersSelect from '../UsersSelect'


const styles = () => createStyles({
  wrap: {
    display: 'flex',
    width: '700px',
    marginBottom: '20px',
  },
  elements: {
    marginRight: '25px',
  },
  buttonWrap: {
    width: '596px',
    textAlign: 'right',
  },
  textAreaWrap: {
    marginBottom: '15px',
  },
  button: {
    marginRight: '15px'
  },
})

const responseSuccess = {
  onSuccess: {
    notification: { body: 'Сохранено', level: 'info' },
  },
  onFailure: {
    notification: { body: 'Error', level: 'warning' },
  },
  refresh: true
}

interface IProps extends WithStyles {
  dataProvider: any,
  segmentsId: [],
  permissions: any,
  checkedData: any,
  dispatch: any,
  setSegmentsId: any
}

// статусы сегмента
const STATUS_NEW = 1 // новый
const STATUS_ARCHIVE = 2 // архив
const STATUS_CHECKING = 4 // на проверке
const STATUS_CANCELLED = 6 // отмена
const STATUS_UNDER_REVIEW = 7 // на рецензировании
const STATUS_APPROVING = 8 // на согласовании
const STATUS_CONFIRMING = 9 // на утверждении
const STATUS_CONFIRMED = 10 // утверждено
const STATUS_EXAM = 11 // на экзамен

const DashboardToolbarProvider = withStyles(styles)(({
  classes, dataProvider, segmentsId, checkedData, dispatch
}: IProps) => {
  const [open, setOpen] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [warningContent, setWarningContent] = useState([])
  const [segmentErrors, setSegmentErrors] = useState([])
  const [assignButtonDisabled, setAssignButtonDisabled] = useState(true)
  const [selectedStatus, setSelectedStatus] = useState(0)
  const [selectedSpecId, setSelectedSpecId] = useState(0)
  const [currentUserId, setCurrentUserId] = useState(0)
  const [firstButtonLabel, setFirstButtonLabel] = useState('Назначить эксперта')
  const [secondButtonLabel, setSecondButtonLabel] = useState('')
  const [nextStatus, setNextStatus] = useState(0)
  const [download, setDownload] = useState(true)
  const [modalLabel, setModalLabel] = useState('')
  const [selectedAction, setSelectedAction] = useState(1)

  const [examButton, setExamButton] = useState(false)

  const [users, setUsers] = useState({
    experts: {},
    editors: {},
    nmo: {},
    mainNmo: {}
  })

  useEffect(() => {
    (async () => {
      // //Проверяем, выбран ли хотя бы один сегмент
      Object.keys(checkedData).length > 0 ? setAssignButtonDisabled(false) : setAssignButtonDisabled(true)

      const selectedStatuses = []
      const selectedModule = []

      Object.keys(checkedData).map((id, key) => {
        selectedStatuses.push(checkedData[id].statusId)
        if (checkedData[id].type === 'test') { selectedModule.push(checkedData[id].test.moduleId) } else { selectedModule.push(checkedData[id].case.moduleId) }
      })

      // Обрабатываем ошибки (сегменты находятся в разных статусах, принадлежат к разным модулям)
      const ss = selectedStatuses.filter((v, i, a) => a.indexOf(v) === i) // получаем все уникальные статусы
      const sm = selectedModule.filter((v, i, a) => a.indexOf(v) === i) // получаем все уникальные статусы
      if (ss.length === 1) {
        setSelectedStatus(ss[0])
        switch (ss[0]) {
          case STATUS_NEW: // Новый, выбираем экспертов
            setFirstButtonLabel('Назначить эксперта')
            setSecondButtonLabel('')
            setNextStatus(STATUS_CHECKING)
            break
          case STATUS_CHECKING: // На проверке, выбираем НМО
            setFirstButtonLabel('Назначить НМО')
            setSecondButtonLabel('Назначить эксперта')
            setNextStatus(STATUS_APPROVING)
            break
          case STATUS_APPROVING: // На согласовании, выбираем корректора
            setFirstButtonLabel('Назначить корректора')
            setSecondButtonLabel('Назначить НМО')
            setNextStatus(STATUS_UNDER_REVIEW)
            break
          case STATUS_UNDER_REVIEW: // На рецензировании, выбираем главного методиста
            setFirstButtonLabel('Назначить главного методиста')
            setSecondButtonLabel('Назначить корректора')
            setNextStatus(STATUS_CONFIRMING)
            break
          case STATUS_CONFIRMING: // На утверждении
            setFirstButtonLabel('')
            setSecondButtonLabel('Назначить главного методиста')
            setNextStatus(STATUS_CONFIRMED)
            break
          case STATUS_CONFIRMED: // На утверждении ---->(наверное, здесь должно было быть написано "Утверждено"???)
            setFirstButtonLabel('')
            setSecondButtonLabel('')
            setExamButton(true)
            break
          case STATUS_EXAM:
            setFirstButtonLabel('Назначить эксперта')
            setSecondButtonLabel('')
            setNextStatus(STATUS_CHECKING)
            setExamButton(true)
            break
          default:
            return {}
        }
      }
      if (sm.length === 1) { setSelectedSpecId(sm[0]) }
    })()
  }, [checkedData])

  const setModalTitle = action => {
    switch (action) {
      case 1:
        switch (selectedStatus) {
          case STATUS_NEW: // Новый, выбираем экспертов
            setModalLabel('Назначить эксперта')
            break
          case STATUS_CHECKING: // На проверке, выбираем НМО
            setModalLabel('Назначить НМО')
            break
          case STATUS_APPROVING: // На согласовании, выбираем корректора
            setModalLabel('Назначить корректора')
            break
          case STATUS_UNDER_REVIEW: // На рецензировании, выбираем главного методиста
            setModalLabel('Назначить главного методиста')
            break
          case STATUS_CONFIRMING: // На утверждении
            setModalLabel('')
            break
          case STATUS_EXAM: // На экзамене
            setModalLabel('Назначить эксперта')
            break
        }
        break
      case 2:
        switch (selectedStatus) {
          case STATUS_NEW: // Новый, выбираем экспертов
            setModalLabel('')
            break
          case STATUS_CHECKING: // На проверке, выбираем НМО
            setModalLabel('Назначить эксперта')
            break
          case STATUS_APPROVING: // На согласовании, выбираем корректора
            setModalLabel('Назначить НМО')
            break
          case STATUS_UNDER_REVIEW: // На рецензировании, выбираем главного методиста
            setModalLabel('Назначить корректора')
            break
          case STATUS_CONFIRMING: // На утверждении
            setModalLabel('Назначить главного методиста')
            break
        }
        break
    }
  }

  const handleClickOpen = action => { // При нажатии на кнопку проверяем, какие сегменты были выбраны
    // По-нормальному, надо запрашивать данные через API, а не брать их из стейта.
    const selectedStatuses = []
    const selectedModule = []
    setSelectedAction(action)
    setModalTitle(action)
    // Собираем нужные массивы данных(статусы, модули, статусы проверки, эксперты)
    Object.keys(checkedData).map((id, key) => {
      selectedStatuses.push(checkedData[id].statusId)
      if (checkedData[id].type === 'test') { selectedModule.push(checkedData[id].test.moduleId) } else { selectedModule.push(checkedData[id].case.moduleId) }
    })

    // Обрабатываем ошибки (сегменты находятся в разных статусах, принадлежат к разным модулям)
    const ss = selectedStatuses.filter((v, i, a) => a.indexOf(v) === i) // получаем все уникальные статусы
    if (ss.length > 1) { setSegmentErrors(segmentErrors => [...segmentErrors, 'Сегменты находятся в разных статусах']) } else { setSelectedStatus(ss['0']) }

    const sm = selectedModule.filter((v, i, a) => a.indexOf(v) === i) // получаем все уникальные статусы

    if (sm.length > 1) {
      setSegmentErrors(segmentErrors => [...segmentErrors, 'Сегменты относятся к разным модулям'])
    } else {
      setSelectedSpecId(sm[0])
    }

    getUsers(action)
    setOpen(true)
  }

  const getUsers = async action => {
    setDownload(true)
    const result = await dataProvider(GET_LIST, 'users',
      { filter: getFilter(action) })
    setUsers(result.data)
    setDownload(false)
  }

  const getFilter = action => {
    switch (action) {
      case 1:
        switch (selectedStatus) {
          case STATUS_NEW: // Новый, выбираем экспертов
            return { specializationId: selectedSpecId, role: 'expertReviewer', status: 'active' }
          case STATUS_CHECKING: // На проверке, выбираем НМО
            return { role: 'directorMo', status: 'active' }
          case STATUS_APPROVING: // На согласовании, выбираем редакторов
            return { role: 'editor', status: 'active' }
          case STATUS_UNDER_REVIEW: // На рецензировании, выбираем главного методиста
            return { role: 'methodist', status: 'active' }
          case STATUS_EXAM: // На экзамене, выбираем экспертов
            return { specializationId: selectedSpecId, role: 'expertReviewer', status: 'active' }
        }
      case 2:
        switch (selectedStatus) {
          case STATUS_CHECKING: // На проверке, выбираем экспертов
            return { specializationId: selectedSpecId, role: 'expertReviewer', status: 'active' }
          case STATUS_APPROVING: // На согласовании, выбираем НМО
            return { role: 'directorMo', status: 'active' }
          case STATUS_UNDER_REVIEW: // На рецензировании, выбираем редакторов
            return { role: 'editor', status: 'active' }
          case STATUS_CONFIRMING: // На утверждении, выбираем главного методиста
            return { role: 'methodist', status: 'active' }
          case STATUS_EXAM: // На экзамене, выбираем экспертов
            return { specializationId: selectedSpecId, role: 'expertReviewer', status: 'active' }
        }
    }
  }

  const handleClose = () => {
    setOpen(false)
    clearErrors()
    setCurrentUserId(0)
  }

  const openWarning = () => {
    setShowWarning(true)
  }

  const closeWarning = () => {
    setShowWarning(false)
    clearErrors()
  }

  const handleConfirm = () => {
    checkNewExpert().then(
      result => {
        if (!result.result) {
          confirm()
          handleClose()
        } else {
          setWarningContent(result.error.map((i, key) => <p key={key}>{i}</p>))
          openWarning()
        }
      }
    )
  }
  const confirmInWarning = () => {
    confirm()
    handleClose()
    closeWarning()
  }

  const clearErrors = () => {
    setWarningContent([])
    setSegmentErrors([])
  }

  const assignExpert = async (statusId, successText: boolean | string = false) => {
    if (currentUserId > 0) {
      await dataProvider(ASSIGN_EXPERT, 'segments', {
        segmentId: segmentsId,
        data: {
          toUserId: currentUserId,
          status: 'assign',
          segmentStatusId: statusId // id assign
        }
      }, successText ? {
        onSuccess: {
          notification: { body: successText, level: 'info' },
        },
        onFailure: {
          notification: { body: 'Error', level: 'warning' },
        },
        refresh: true
      } : responseSuccess)
    } else {
      dispatch(showNotification('Эксперт не выбран', 'warning'))
    }
  }

  const checkNewExpert = async () => {
    const newExpert = currentUserId

    const error = []
    let checked = false

    const segmentInfo = await dataProvider(GET_LIST, 'segments', {
      filter: { id: segmentsId.join(',') },
      url: 'segments'
    })

    segmentInfo.data.map((segment, key) => {
      segment.experts.map(item => {
        if (item.id as number == newExpert as number) {
          checked = true
          error.push(`Данный пользователь уже проверял сегмент №${segment.id}, подтвердите повторное назначение эксперта.`)
        }
      })
      if (segment.verifyStatusId === 2) { error.push(`Cегмент №${segment.id} находится в работе, вы уверены что хотите его переназначить?`) }
    })

    return {
      result: checked,
      error,
    }
  }

  const confirm = () => {
    if (selectedAction === 1) { assignExpert(nextStatus, 'Сохранено') } else { assignExpert(selectedStatus, 'Сохранено') }
  }

  const handleExamButtonClick = () => {
    let isValid = true
    if (segmentsId.length > 1) {
      const checkedItems: Array<ISegment> = Object.values(checkedData)
      isValid = checkedItems.every(({ statusId }, i, array) => statusId === array[0].statusId)
    }

    if (isValid) {
      dataProvider(ASSIGN_EXPERT, 'segments', {
        segmentId: segmentsId,
        data: {
          status: 'assign',
          segmentStatusId: STATUS_EXAM // на экзамен
        }
      }, { onSuccess: { refresh: true } }).then(() => {
        dispatch(showNotification('Переведено на экзамен'))
      }).catch(error => {
        dispatch(showNotification(error, 'warning'))
      })
    } else {
      dispatch(showNotification('Сегменты должны быть в одном статусе.', 'warning'))
    }
  }

  return (
    <>
      {firstButtonLabel && (
      <Button
        variant="outlined"
        className={classes.button}
        color="primary"
        onClick={() => handleClickOpen(1)}
        disabled={assignButtonDisabled}
      >
        {firstButtonLabel}
      </Button>
      )}

      {secondButtonLabel && (
      <Button
        variant="outlined"
        className={classes.button}
        color="primary"
        onClick={() => handleClickOpen(2)}
        disabled={assignButtonDisabled}
      >
        {secondButtonLabel}
      </Button>
      )}

      {examButton && (
        <Button
          variant="text"
          className={classes.button}
          color="primary"
          onClick={handleExamButtonClick}
          disabled={assignButtonDisabled}
        >
          На экзамен
        </Button>
      )}

      <Dialog open={segmentErrors.length > 0 && open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>Ошибка! Невозможно назначить одного эксперта для выбранных сегментов.</DialogTitle>
        <DialogContent>
          {segmentErrors.map((i, key) => <p key={key}>{i}</p>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
                    Отмена
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open && !(segmentErrors.length > 0)} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>{modalLabel}</DialogTitle>
        <DialogContent>
          {download ? <Typography style={{ textAlign: 'center', padding: '20px 0' }}>Загрузка...</Typography> : (
            <UsersSelect data={users} onChange={setCurrentUserId} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
                    Отмена
          </Button>
          <Button onClick={handleConfirm} color="primary" disabled={currentUserId === 0}>
            {modalLabel}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showWarning && open && !(segmentErrors.length > 0)} onClose={closeWarning} aria-labelledby="form-dialog-title">
        <DialogTitle>Подтвердите действие</DialogTitle>
        <DialogContent>
          {warningContent}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWarning} color="primary">
            Отмена
          </Button>
          <Button onClick={confirmInWarning} color="primary">
            ОК
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

export default withDataProvider(DashboardToolbarProvider)
