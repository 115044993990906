import React from 'react'

const gray = 'rgba(0, 0, 0, 0.87)'
const red = 'rgba(255, 0, 0, 0.87)'

const ExamTicketsVerifyStatus = ({ status }) => {
  let color
  let text

  switch (status) {
    case 'empty':
      text = 'Не проверена'
      color = gray
      break
    case 'verify':
      text = 'На проверке'
      color = gray
      break
    case 'work':
      text = 'В работе'
      color = gray
      break
    case 'complete':
      text = 'Проверена'
      color = gray
      break
    case 'cancel':
      text = 'Отменена'
      color = red
      break
    default: return null
  }

  return <span style={{ color: `${color}` }}>{text}</span>
}

export default ExamTicketsVerifyStatus
