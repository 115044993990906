import React from 'react'
import { Edit } from 'react-admin'
import TicketCriteriaForm from './TicketCriteriaForm'

const TicketCriteriaEdit = props => (
  <Edit
    undoable
    {...props}
  >
    <TicketCriteriaForm formType="edit" redirect={false} RAprops={props} />
  </Edit>
)

export default TicketCriteriaEdit
