import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const styles = () => createStyles({})

interface IField {
  record?: any
}

interface IProps extends WithStyles {
  id: string,
  data: any,
  onChange?: any
}

const usersSelect = withStyles(styles)(({ classes, data, id, onChange }: IProps) => {
  const FullNameField: React.FunctionComponent<IField> = ({ record }) => (
    <>
      {record.surname ? record.surname : ''}
      {' '}
      {record.name ? record.name : ''}
      {' '}
      {record.middleName ? record.middleName : ''}
    </>
  )
  const [userId, setUserId] = React.useState<number>(0)

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserId(event.target.value as number)
    if (onChange)
      onChange(event.target.value)
  }

  return (Object.keys(data).length > 0
    ? (
      <>
        <Select onChange={handleChange} value={userId} id={id}>
          <MenuItem key={0} value={0} disabled>Выберите пользователя</MenuItem>
          {Object.keys(data).map(i => <MenuItem key={data[i].id} value={data[i].id}><FullNameField record={data[i]} /></MenuItem>)}
        </Select>

      </>
    )
    : (<Typography style={{ textAlign: 'center', padding: '20px 0' }}>Результатов нет</Typography>)
  )
})

export default usersSelect