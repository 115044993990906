import {
  GET_LIST, GET_MANY, CREATE, DELETE
} from 'react-admin'

import { stringify } from 'query-string'
import axios, { AxiosRequestConfig } from 'axios'
import { IQuery } from '../query'
import Cookies from 'js-cookie'

interface Data {
  id?: number,
  idType?: string,
  entity?: string,
  text?: string,
  originalComment?: boolean
}

const commentsRoute = async (type, resource, params) => {
  const options: AxiosRequestConfig = {}
  options.headers = { 'Content-Type': 'application/json' }

  const query: IQuery = {
    token: `${localStorage.getItem('token')}`,
  }
  const {
    idType,
    id,
    text,
    entity,
    originalComment
  } = params

  let optionsData: Data = {
    [idType]: id,
    text
  }

  if (entity) {
    optionsData = { ...optionsData, entity }
  }

  switch (type) {
    case GET_LIST:
    case GET_MANY:
      query[idType] = id
      if (entity) {
        query.entity = entity
      }

      if (originalComment) {
        query.originalComment = originalComment
      }

      options.url = `${resource}?${stringify(query)}`
      break
    case CREATE:
      options.method = 'POST'
      options.url = `${resource}?${stringify(query)}`
      options.data = JSON.stringify(optionsData)
      break
    case DELETE:
      options.method = 'DELETE'
      options.url = `/${resource}/${params.commentId}?${stringify(query)}`
      break
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  const response = await axios(options).catch(error => {
    const {
      response: {
        status: statusCode
      }
    } = error

    if (statusCode === 401) {
      localStorage.removeItem('token')
      Cookies.remove('token')
      Cookies.remove('role')
      Cookies.remove('userId')
      Cookies.remove('userName')
      return Promise.resolve()
    }
  })

  const { data: dataResponse }: any = response

  const data: IRAdminData<IComments> = {
    data: null,
  }


  if ([CREATE, GET_MANY, DELETE].includes(type)) {
    data.data = dataResponse
  } else {
    data.data = dataResponse.models
    data.total = dataResponse.total
  }

  return data
}

export default commentsRoute
