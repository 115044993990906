import React from 'react'

import ApplicanExamQuestionCase from './ApplicanExamQuestionCase'
import ApplicanExamQuestionTest from './ApplicanExamQuestionTest'

const ApplicanExamQuestion = ({ activeQuestion, dispatch, ticketId }) => {
  const isCase = activeQuestion.type === 'case'

  return (
    <>
      {isCase
        ? (<ApplicanExamQuestionCase activeQuestion={activeQuestion} dispatch={dispatch} ticketId={ticketId} />)
        : (<ApplicanExamQuestionTest activeQuestion={activeQuestion} dispatch={dispatch} ticketId={ticketId} />)
      }
    </>
  )
}

export default ApplicanExamQuestion
