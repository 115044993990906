import React, { useState } from 'react'
import Button from '@material-ui/core/Button'

const SortButton = ({ setSort }) => {
  const [sortOrder, toggleSortOrder] = useState(true)

  const sortType = sortOrder ? 'А -> Я' : 'Я -> А'

  const handleSorting = () => {
    const order = sortOrder ? 'ASC' : 'DESC'
    toggleSortOrder(!sortOrder)
    setSort('surname', order)
  }
  return (
    <Button color="primary" size="small" onClick={handleSorting}>
      Сортировать
      {' '}
      {sortType}
    </Button>
  )
}

export default SortButton
