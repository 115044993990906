import React from 'react'
import { Edit } from 'react-admin'
import Cookies from 'js-cookie'
import Form from './userForm'
import { NotPermitted } from '../../elements'

interface ITitle {
  record?: any
}

const Title: React.FunctionComponent<ITitle> = ({ record }) => (
  <span>
    {record.name}
    {' '}
    {record.middleName}
    {' '}
    {record.surname}
  </span>
)

const UserProfileEdit = ({ permissions, id, ...props }) => (
  <>
    {permissions && (id === permissions.userId || Cookies.get('role') === 'admin')
      ? (
        <Edit
          undoable={false}
          title={<Title />}
          id={id}
          {...props}
        >
          <Form
            redirect="show"
            permissions={permissions}
            formType="edit"
          />
        </Edit>
      )
      : (
        <NotPermitted />
      )}
  </>
)


export default UserProfileEdit
