import {
  CREATE
} from 'react-admin'

import axios, { AxiosRequestConfig } from 'axios'

// import { AxiosResponse } from '../axiosResponse'

const resetPassword = async (type, resource, params) => {
  const options: AxiosRequestConfig = {}

  if (CREATE === type) {
    options.method = 'POST'
    options.headers = { 'Content-Type': 'application/json' }
    options.data = JSON.stringify(params)
    options.url = `/site/${resource}`
  } else {
    throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  const response = await axios(options)

  const { data: dataResponse } = response
  const data = {
    data: null
  }

  data.data = dataResponse

  return data
}

export default resetPassword
