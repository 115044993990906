
import React from 'react'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const SelectList = ({
  selectedId,
  list = [],
  onChange,
  className = '',
  disabled = false,
  formControlStyle = '',
  name,
  error = false,
  noticeText = '',
  multiple = false,
  showNameValue = 'name'
}) => {
  const handleChange = event => onChange(event.target.value)

  return (
    <FormControl
      className={formControlStyle}
      error={error}
    >
      <InputLabel
        htmlFor={name}
        shrink
      >
        {name}
      </InputLabel>
      <Select
        multiple={multiple}
        value={selectedId || ''}
        onChange={handleChange}
        className={className}
        inputProps={{
          id: name,
        }}
        disabled={disabled}
      >
        {list.map(item => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            { item[showNameValue] }
          </MenuItem>
        ))}
      </Select>
      {noticeText ? (
        <FormHelperText>
          Выберите значение для
          {' '}
          {noticeText}
        </FormHelperText>
      ) : (
        <>
          {error && (
          <FormHelperText>Выберите значение</FormHelperText>
          )}
        </>
      )}
    </FormControl>
  )
}

export default SelectList
