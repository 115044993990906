import React from 'react'
import {
  ReferenceField, FunctionField, SimpleShowLayout
} from 'react-admin'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Cookies from 'js-cookie'
import { getDateTime } from '../../utils/getFields'


const styles = {
  card: {
    position: 'relative' as 'relative',
    marginBottom: '5px'
  },
  author: {
    opacity: '0.7',
    margin: '0'
  },
  commentText: {
    paddingLeft: '24px',
    paddingRight: '24px',
    marginBottom: '20px'
  },
  date: {
    opacity: '0.7'
  },
  form: {
    paddingTop: '400px'
  },
  deleteButton: {
    position: 'absolute' as 'absolute',
    right: '20px',
    top: '20px',
    zIndex: 2
  }

}

const Comment = ({
  id, idType, entityForCase, classes, comments, setComments, deleteComment, showAddBlock
}) => {
  const userId = +Cookies.get('userId')
  const role = Cookies.get('role')
  let showCreateBlock = true

  if (showAddBlock !== undefined) {
    showCreateBlock = showAddBlock
  }

  /**
   * Возвращает true, если можно показывать автора комментария.
   * @param authorId
   */
  const showAuthor = (authorId) => {
    const allowRoles = ['admin', 'managerMo', 'directorMo', 'methodist'];

    if (authorId == userId || allowRoles.indexOf(role) !== -1) {
      return true;
    }
  }

  return (
    <>
      {comments.length > 0 ? comments.map(comment => (
        <div key={comment.id}>
          <Card className={classes.card}>
            {(comment.createdById === userId || role === 'admin') && (
              <Tooltip title="Удалить комментарий">
                <IconButton onClick={() => deleteComment(comment.id)} className={classes.deleteButton}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
            <div className={classes.commentHeader}>
              <SimpleShowLayout record={comment} basePath="/comments">
                <FunctionField
                  addLabel={false}
                  render={record => getDateTime(record.createdAt)}
                  className={classes.date}
                />
                <ReferenceField
                  reference="users"
                  source="createdById"
                  linkType={false}
                  addLabel={false}
                >
                  <FunctionField
                    record={comment}
                    className={classes.author}
                    render={({ name, middleName, surname, id }) => {
                      if (showAuthor(id)) {
                        return `${name} ${middleName} ${surname} прокомментировал(а):`
                      } else {
                        return ''
                      }
                    }}
                  />
                </ReferenceField>
              </SimpleShowLayout>
            </div>
            <p className={classes.commentText}>{comment.text}</p>
          </Card>
        </div>
      )) : (
        <Typography>Пока ещё нет комментариев.</Typography>
      )}
    </>
  )
}


export default withStyles(styles)(Comment)
