import { changeCorretAnswer, changeSingleCorretAnswer } from './utils'

export const reducerCase = (state, action) => {
  switch (action.type) {
    case 'setTask':
      return {
        ...state,
        task: action.task
      }
    case 'editQuestion':
      return {
        ...state,
        questions: state.questions.map(question => {
          if (question.id === action.id) {
            return {
              ...question,
              ...action
            }
          }
          return question
        })
      }
    case 'deleteQuestion':
      return {
        ...state,
        questions: state.questions.filter(question => question.id !== action.id)
      }
    case 'addQuestion':
      return {
        ...state,
        questions: [
          ...state.questions,
          {
            ...action
          }
        ]
      }
    case 'uploadAttach': {
      const { id, data, attachesValueKey = 'attaches' } = action

      return {
        ...state,
        questions: [
          ...state.questions.map(question => {
            if (question.id === id) { question[attachesValueKey].push(data) }
            return question
          })
        ]
      }
    }
    case 'removeAttach': {
      const { id, attachId, attachesValueKey = 'attaches' } = action

      return {
        ...state,
        questions: [
          ...state.questions.map(question => {
            if (question.id === id) {
              return { ...question, [attachesValueKey]: question[attachesValueKey].filter(el => el.id !== attachId) }
            }

            return question
          })
        ]
      }
    }
    default:
      throw new Error()
  }
}

export const reducerAnswer = (state, action) => {
  switch (action.type) {
    case 'addAnswer':
      return [
        ...state.answers,
        {
          ...action
        }
      ]
    case 'deleteAnswer':
      return state.answers.filter(answer => answer.id !== action.id)
    case 'addAnswerForQuestion': {
      return (state.id !== action.questionId)
        ? state
        : {
          ...state,
          answers: [
            ...state.answers,
            {
              ...action
            }
          ]
        }
    }
    case 'editAnswerForSubQuestion':
      return {
        ...state,
        answers: state.answers.map(answer => {
          if (answer.id === action.id) {
            return {
              ...answer,
              ...action
            }
          }
          return answer
        })
      }
    case 'deleteAnswerForQuestion': {
      return (state.id !== action.questionId)
        ? state
        : {
          ...state,
          answers: state.answers.filter(answer => answer.id !== action.id)
        }
    }
    case 'changeCorretAnswer':
      return state.answers.map(answer => changeCorretAnswer(answer, action.id))
    case 'changeCorretAnswerForQuestion':
      return {
        ...state,
        answers: state.answers.map(answer => changeCorretAnswer(answer, action.id))
      }
    case 'changeSingleCorretAnswer':
      return state.answers.map(answer => changeSingleCorretAnswer(answer, action.id))
    case 'changeSingleCorretAnswerForQuestion':
      return {
        ...state,
        answers: state.answers.map(answer => changeSingleCorretAnswer(answer, action.id))
      }
    default:
      throw new Error()
  }
}

export const reducerTest = (state, action) => {
  switch (action.type) {
    case 'setTask':
      return {
        ...state,
        task: action.task
      }
    case 'editAnswers':
      return {
        ...state,
        answers: state.answers.map(answer => {
          if (answer.id === action.id) {
            return {
              ...answer,
              ...action
            }
          }
          return answer
        })
      }
    case 'editAnswerForSubQuestion':
      return {
        ...state,
        questions: state.questions.map(question => reducerAnswer(question, action))
      }
    case 'deleteAnswer':
    case 'addAnswer':
      return {
        ...state,
        answers: reducerAnswer(state, action)
      }
    case 'deleteAnswerForQuestion':
    case 'addAnswerForQuestion':
      return {
        ...state,
        questions: state.questions.map(question => reducerAnswer(question, action))
      }
    case 'addQuestion':
      return {
        ...state,
        questions: [
          ...state.questions,
          {
            ...action
          }
        ]
      }
    case 'editQuestion':
      return {
        ...state,
        questions: state.questions.map(question => {
          if (question.id === action.id) {
            return {
              ...question,
              ...action
            }
          }
          return question
        })
      }
    case 'deleteQuestion':
      return {
        ...state,
        questions: state.questions.filter(question => question.id !== action.id)
      }
    case 'changeSingleCorretAnswer':
    case 'changeCorretAnswer':
      return {
        ...state,
        answers: reducerAnswer(state, action)
      }
    case 'changeSingleCorretAnswerForQuestion':
    case 'changeCorretAnswerForQuestion':
      return {
        ...state,
        questions: state.questions.map(question => {
          if (question.id === action.questionId) {
            return reducerAnswer(question, action)
          }
          return question
        })
      }
    case 'changePositionAnwser':
      return {
        ...state,
        answers: state.answers.map(item => {
          if (item.correctPosition === action.correctPosition) {
            item.correctPosition = 0
          }
          return item
        }).map(item => {
          if (item.id === action.id) {
            item.correctPosition = action.correctPosition
          }
          return item
        })
      }
    case 'uploadAttach':
      return {
        ...state,
        questions: [
          ...state.questions.map(question => {
            if (question.id === action.id) { question.attaches.push(action.data) }
            return question
          })
        ]
      }
    case 'removeAttach':
      return {
        ...state,
        questions: [
          ...state.questions.map(question => {
            if (question.id === action.id) {
              question.attaches.map((attach, key) => {
                if (attach.id === action.attachId) {
                  question.attaches.splice(key, 1)
                }
              })
            }
            return question
          })
        ]
      }
    default:
      return state
  }
}
