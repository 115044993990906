import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useTopicsList = () => {
  const [arrayTopicsList, setArrayTopicsList] = useState([])

  useEffect(() => {
    (async () => {
      const topicsList = await rolesRoute(GET_LIST, 'topics')
      setArrayTopicsList(topicsList.data)
    })()
  }, [])

  return arrayTopicsList
}

export default useTopicsList
