import React from 'react'

import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Parser from 'html-react-parser'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import formatHtml from '../../utils/formatHtml'
import ApplicanExamAnswersSingle from './ApplicanExamAnswersSingle'
import ApplicanExamAnswersMulti from './ApplicanExamAnswersMulti'
import ApplicanExamAnswersComposite from './ApplicanExamAnswersСomposite'
import ApplicanExamQuestionComments from './ApplicanExamQuestionComments'


const styles = createStyles({
  question: {
    marginBottom: '25px',
    fontWeight: 'bold'
  },
  questionNotation: {
    marginBottom: '16px',
  },
  head: {
    margin: '0px 0px 10px'
  },
  layout: {
    marginTop: '15px',
    padding: '20px'
  },
  markInput: {
    marginBottom: '20px',
  },
  commentButttonWrap: {
    margin: '10px 0 0 0',
    textAlign: 'right'
  }
})

const ApplicanExamQuestionTest = ({
  classes, activeQuestion, ticketId, dispatch
}) => {
  const {
    id,
    comments,
    test: {
      typeId,
      answers,
      questions,
      module: {
        name
      },
      question: {
        content
      }
    },
    expertMark
  } = activeQuestion

  const question = Parser(formatHtml(content))

  const questionMarkChangeHandler = ({ target: { value } }) => {
    const isValidValue = /^[0-2]$/.test(value) || !value

    dispatch({
      type: 'add_mark',
      payload: {
        segmentId: activeQuestion.id,
        expertMark: isValidValue ? value : expertMark.mark
      }
    })
  }

  return (
    <>
      <Card className={classes.layout}>
        <h3 className={classes.head}>{name}</h3>
        <div className={classes.questionNotation}>
          {
            {
              1: 'Выберите один правильный ответ.',
              2: 'Выберите несколько ответов.',
              5: 'Определите последовательность ответов, передвинув иконки с номерами'
            }[typeId]
          }
        </div>
        <div className={classes.question}>{question}</div>
        {
          {
            2: <ApplicanExamAnswersMulti activeQuestionId={id} answers={answers} />,
            1: <ApplicanExamAnswersSingle activeQuestionId={id} answers={answers} />,
            6: <ApplicanExamAnswersSingle activeQuestionId={id} answers={answers} />,
            4: <ApplicanExamAnswersComposite activeQuestionId={id} questions={questions} />,
            3: <ApplicanExamAnswersComposite activeQuestionId={id} questions={questions} />
          }[typeId]
        }
      </Card>
      <Card className={classes.layout}>
        <TextField
          className={classes.markInput}
          label="Оценка (0 - 2)"
          value={expertMark.mark ? expertMark.mark : ''}
          onChange={questionMarkChangeHandler}
        />
        <ApplicanExamQuestionComments dispatchR={dispatch} comments={comments} isTest ticketId={ticketId} segmentId={id} />
      </Card>
    </>
  )
}

export default withStyles(styles)(ApplicanExamQuestionTest)
