import React from 'react'

const ModulesName = ({ record }) => {
  const { modules } = record.exam
  return modules.map(item => (
    <div key={item.id}>
      {item && item.name}
    </div>
  ))
}

export default ModulesName
