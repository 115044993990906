import React from 'react'
import { EditButton, ShowButton } from 'react-admin'

import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import Checkbox from '@material-ui/core/Checkbox'

import { verify } from 'crypto'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { Label } from '@material-ui/icons'
import { ListItem } from '../../elements'
import { getDate } from '../../utils/getFields'
import experts from '../../providers/module/experts'
import { getFIO } from '../../utils/getFields'

const styles = () => createStyles({
  card: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    padding: '10px',
  },
  column: {
    width: '30%',
  },
  checkbox: {
    alignSelf: 'start',
  },
  number: {
    marginBottom: '15px',
    fontWeight: 'bold',
  },
  question: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    marginBottom: '15px'
  },
  list: {
    padding: '0',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  textField: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  item: {
    display: 'inline-block',
    paddingLeft: '24px',
    paddingRight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    lineHeight: '1.46429em',
    marginRight: '5px'
  }
})
interface IProps extends WithStyles {
  id: number,
  segment: ISegment,
  types: ITestTypesList[],
  modules: IModulesList[],
  criterias: ICriteriasList[],
  competences: ICompetencesList[],
  onSegmentCheck?: any,
  checkedValues?: number[],
  isArchived?: boolean,
  statusId: number
}

const getListData = (id, data) => data.filter(item => item.id === id)[0]

const getArrayListData = (id, data) => {
  const idList = id.map(item => item.id)

  return data.filter(item => {
    if (idList.includes(item.id)) return item

    return null
  })
}

const SegmentPreview: React.FunctionComponent<IProps> = (
  {
    id,
    segment,
    classes,
    types,
    competences,
    criterias,
    modules,
    onSegmentCheck,
    checkedValues,
    isArchived,
    statusId
  }) => {
  let basePath
  const isConfirmed = statusId === 10
  const isForExam = statusId === 11

  switch (true) {
    case isArchived:
      basePath = 'archive'
      break
    case isConfirmed:
      basePath = 'version'
      break
    case isForExam:
      basePath = 'version'
      break
    default:
      basePath = 'segments'
      break
  }

  /**
   * Эксперты, назначенные на проверку.
   */
  const experts = segment.experts.filter((item) => item.role === 'expertReviewer');

  /**
   * Эксперты, которые реально проверяли сегмент.
   */
  const verifyExperts = segment.verifyExperts.map((item) => item.expert);

  return (
    <Card className={classes.card}>
      {!isArchived && (
        <Checkbox
          color="primary"
          className={classes.checkbox}
          onChange={e => { onSegmentCheck(e, id) }}
          checked={checkedValues.includes(id)}
        />
      )}
      <div className={classes.column}>
        <List className={classes.list}>
          <ListItem
            record={segment}
            source="id"
            label="Номер сегмента:"
            className={classes.number}
          />
          <ListItem
            record={segment}
            source={`${segment.test ? 'test.question' : 'case.task'}.content`}
            label="Текст сегмента:"
            className={classes.question}
            textFieldClassName={classes.textField}
          />
          {segment.case && (
            <ListItem
              record={getListData(segment.case.moduleId, modules)}
              label="Модуль:"
              source="name"
              className={classes.listItem}
            />
          )}
          {segment.test && (
            <ListItem
              record={getListData(segment.test.moduleId, modules)}
              label="Модуль:"
              source="name"
              className={classes.listItem}
            />
          )}
        </List>
      </div>
      <div className={classes.column}>
        <List className={classes.list}>
          <ListItem
            record={segment}
            label="Дата создания:"
            source="createdAt"
            className={classes.listItem}
            cb={getDate}
          />
          {segment.test && (
            <>
              <ListItem
                record={segment.test.type}
                label="Тип теста:"
                source="name"
                className={classes.listItem}
              />
              <ListItem
                record={segment.test.criteria}
                label="Тест на:"
                source="name"
                className={classes.listItem}
              />
            </>
          )}
          {segment.case && (
            <>
              <ListItem
                record={segment.case.taskType}
                label="Тип теста:"
                source="name"
                className={classes.listItem}
              />
            </>
          )}

          {segment.test && (
            <>
              <ListItem
                record={segment}
                label="Номер раздела:"
                source="test.program.number"
                className={classes.listItem}
              />
              <ListItem
                record={segment}
                label="Номер темы:"
                source="test.topic.number"
                className={classes.listItem}
              />
              <li className={classes.item}>
                Номер вопроса: {segment.test.programNumbers.map((item) => item.number).join(', ')}
              </li>
            </>
          )}
        </List>
      </div>
      <div className={classes.column}>
        <List className={classes.list}>
          <ListItem
            record={segment}
            label={'Назначен на: ' + experts.map((item) => getFIO(item)).join(', ')}
            source=""
            className={classes.listItem}
          />
          <ListItem
            record={segment}
            label="Статус:"
            source="status.name"
            className={classes.listItem}
          />
          {segment.verifyStatus && (
            <ListItem
              record={segment}
              label="Текущий статус проверки:"
              source="verifyStatus.name"
              className={classes.listItem}
            />
          )}
          <ListItem
            record={segment}
            label={'Эксперты: ' + verifyExperts.map((item) => getFIO(item)).join(', ')}
            source=""
            className={classes.listItem}
          />
          {isArchived && (
            <ListItem
              record={segment}
              label="Причина архивации:"
              source="reasonDeactivated"
              className={classes.listItem}
            />
          )}
          {!isArchived && (
            <ListItem
              record={segment}
              label="Дата обновления:"
              source="updatedAt"
              className={classes.listItem}
              cb={getDate}
            />
          )}
          {isArchived && (
            <ListItem
              record={segment}
              label="Дата архивации:"
              source="deactivatedAt"
              className={classes.listItem}
              cb={getDate}
            />
          )}
          <ListItem
            record={segment}
            label="Порядковый номер:"
            source={`${segment.test ? 'test' : 'case'}.number`}
            className={classes.listItem}
          />
          <li className={classes.item}>
            № разработчика: {segment.createdById}
          </li>
        </List>
      </div>
      <div className={classes.buttons}>
        {isArchived ? (
          <div>
            <ShowButton
              label="Просмотреть"
              basePath={basePath}
              record={segment}
            />
            <EditButton
              resource="segments"
              basePath="segments"
              record={segment}
            />
          </div>
        ) : (
          <EditButton
            resource="segments"
            basePath="segments"
            record={segment}
          />
        )}
      </div>
    </Card>
  )
}

export default withStyles(styles)(SegmentPreview)
