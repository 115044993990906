import React from 'react'
import { List } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import RequestItem from './RequestItem'
import RequestPasswordActions from './RequestPasswordActions'

interface IProps {
  ids?: number[],
  data?: any,
  selected?: any,
  onToggleItem?: any,
  perPage?: number,
  record?: any
}

const Requests: React.FunctionComponent<IProps> = ({
  ids, data, selected, onToggleItem
}) => {
  const newRequests = ids.some(item => data[item].status === 'new')

  return (
    <>
      {newRequests
        ? ids.map(id => (
          data[id].status === 'new' && (
          <RequestItem key={id} id={id} data={data} onToggleItem={onToggleItem} selected={selected} />
          )))
        : (
          <Typography style={{ textAlign: 'center', padding: '20px 0' }}>Запросов нет</Typography>
        )}
    </>
  )
}


const RequestsList = props => (
  <List
    title="Запросы пользователей"
    exporter={false}
    bulkActionButtons={<RequestPasswordActions />}
    {...props}
    pagination=""
  >
    <Requests />
  </List>
)


export default RequestsList
