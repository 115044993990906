import React, { useState } from 'react'
import {
  Filter, SelectInput, TextInput, DateInput, FileField
} from 'react-admin'
import useTestTypesList from '../../utils/useTestTypesList'
import useCompetencesList from '../../utils/useCompetencesList'
import useCriteriasList from '../../utils/useCriteriasList'
import useModulesList from '../../utils/useModulesList'
import useVerifyStatusesList from '../../utils/useVerifyStatusesList'
import useProgramsNumbersList from '../../utils/useProgramsNumbersList'
import useProgramsList from '../../utils/useProgramsList'
import useTopicsList from '../../utils/useTopicsList'


const SegmentsListFilters = ({
  statusId,
  levelTypes,
  ...props
}) => {
  const { filterValues } = props
  const [programId, setProgramId] = useState(filterValues.programId)
  const [moduleId, setModuleId] = useState(filterValues.moduleId)
  const [topicId, setTopicId] = useState(filterValues.topicId)

  const types = useTestTypesList()
  const competences = useCompetencesList()
  const criterias = useCriteriasList()
  const modules = useModulesList()
  const verifyStatuses = useVerifyStatusesList()
  const progamNumber = useProgramsNumbersList()
  const progamNumberFrom = useProgramsList()
  const topicsList = useTopicsList()

  return (
    <Filter {...props}>
      <TextInput
        label="ID сегмента"
        source="id"
        allowEmpty={false}
      />
      <TextInput
        label="Текст сегмента"
        source="text"
        allowEmpty={false}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        label="Уровень экзамена"
        choices={levelTypes}
        source="levelType"
        allowEmpty={false}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        label="Модуль"
        choices={modules}
        source="moduleId"
        allowEmpty={false}
        onChange={(e, val) => setModuleId(val)}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        label="Тип сегмента"
        choices={types}
        source="typeId"
        allowEmpty={false}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        label="Тест на"
        choices={criterias}
        source="criteriaId"
        allowEmpty={false}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        title="Выберите модуль"
        label="№ раздела"
        choices={progamNumberFrom.filter(progamNumberFrom => progamNumberFrom.moduleId === moduleId)}
        disabled={!moduleId}
        source="programId"
        allowEmpty={false}
        onChange={(e, val) => setProgramId(val)}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        label="№ темы"
        title="Выберите раздел программы"
        disabled={!programId}
        choices={topicsList.filter(topic => topic.programId === programId)}
        source="topicId"
        allowEmpty={false}
        onChange={(e, val) => setTopicId(val)}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        label="№ вопроса"
        title="Выберите номер темы"
        disabled={!topicId}
        choices={progamNumber.filter(progamNumber => progamNumber.topicId === topicId)}
        source="programNumberId"
        allowEmpty={false}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        label="№ компетенции"
        title="Выберите раздел программы и раздел программы"
        disabled={!moduleId && !topicId}
        choices={competences.filter(competence => competence.programId === programId && competence.topicId === topicId)}
        source="competence"
        allowEmpty={false}
      />
      <TextInput
        label="№ разработчика"
        source="createdById"
        allowEmpty={false}
      />
      <FileField
        label="Дата загрузки"
        source="createdAt"
        allowEmpty={false}
      >
        <DateInput label="Дата загрузки с" source="from" defaultValue={new Date()} />
        <DateInput label="Дата загрузки по" source="to" defaultValue={new Date()} />
      </FileField>
      <FileField
        label="Дата архивации"
        source="deactivatedAt"
        allowEmpty={false}
      >
        <DateInput label="Дата архивации с" source="deactivateFrom" defaultValue={new Date()} />
        <DateInput label="Дата архивации по" source="deactivateTo" defaultValue={new Date()} />
      </FileField>
      <TextInput
        label="Назначен на"
        source="assignedName"
        allowEmpty={false}
      />
      {statusId !== 1 && (
        <SelectInput
          optionText="name"
          optionValue="id"
          label="Статус проверки"
          choices={verifyStatuses}
          source="verifyStatusId"
          allowEmpty={false}
        />
      )}
    </Filter>
  )
}

export default SegmentsListFilters
