import React from 'react'
import { Layout, Sidebar } from 'react-admin'
import AppBar from './CustomAppBar'

const CustomSidebar = props => <Sidebar {...props} size={270} />

const CustomLayout = props => (
  <Layout
    {...props}
    sidebar={CustomSidebar}
    appBar={AppBar}
  />

)

export default CustomLayout
