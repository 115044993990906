import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useModulesList = (): IModulesList[] => {
  const [arrayModules, setArrayModules] = useState<IModulesList[]>([])

  useEffect(() => {
    (async () => {
      const modulesList = await rolesRoute(GET_LIST, 'modules')
      setArrayModules(modulesList.data)
    })()
  }, [])

  return arrayModules
}

export default useModulesList
