import React, { useState, useEffect } from 'react'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {GET_ONE, withDataProvider} from 'react-admin'
import Parser from "html-react-parser";
import formatHtml from "../../utils/formatHtml";
import { Accordion, AccordionItem } from 'react-light-accordion';

import 'react-light-accordion/demo/css/index.css';
import './Accordion.css'
import SimpleTestItem from "./testTypes/SimpleTestItem";
import MultipleTestItem from "./testTypes/MultipleTestItem";
import AccordanceTestItem from "./testTypes/AccordanceTestItem";
import CompositeTestItem from "./testTypes/CompositeTestItem";
import SequenceTestItem from "./testTypes/SequenceTestItem";
import CalculateTestItem from "./testTypes/CalculateTestItem";


const styles = createStyles({
  formGroup: {
    marginBottom: '15px'
  },
  question: {
    padding: '0px 15px 15px',
    marginBottom: '15px'
  },
  panelHeader: {
    padding: '15px',
    backgroundColor: '#eee',
    marginBottom: '15px'
  },
  panelAnswer: {
    padding: '15px',
    backgroundColor: '#b71c1c',
    color: '#fff',
    marginBottom: '15px'
  },
  accordionItem: {
    padding: '0px 15px 15px',
  },
  link: {
    color: 'blue',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  textHide: {
    height: '4em',
    paddingTop: '15px',
    overflow: 'hidden'
  },
  textTask: {
    height: '0px',
    paddingTop: '15px',
    overflow: 'hidden'
  },
  pLeft: {
    paddingLeft: '15px'
  }
})

const ExamTicketPreviewModal = withStyles(styles)(({showModal, setShowModal, workId, setWorkId, classes, dataProvider}: any) => {
  const [workData, setWorkData] = useState<any>(null)

  /**
   * Закрывает модальное окно.
   */
  const closeModal = () => {
    setShowModal(false)
  }

  /**
   * Показываем/скрываем текст задания
   * @param event
   */
  const toggleTask = (event) => {
    const labelToggle = event.target.getAttribute('data-label')
    const label = event.target.innerText
    let node = event.target.parentNode.parentNode.children[0];

    if (label === 'Скрыть') {
      node.style = 'height: 0px'
    } else {
      node.style = 'height: inherit'
    }

    event.target.innerText = labelToggle
    event.target.setAttribute('data-label', label)
  }

  /**
   * Показываем/скрываем текст вопроса
   * @param event
   */
  const toggleQuestion = (event) => {
    const labelToggle = event.target.getAttribute('data-label')
    const label = event.target.innerText
    let node = event.target.parentNode.parentNode.children[0];

    if (label === 'Скрыть') {
      node.style = 'height: 3.5em'
    } else {
      node.style = 'height: inherit'
    }

    event.target.innerText = labelToggle
    event.target.setAttribute('data-label', label)
  }

  const fetchWork = async () => {
    try {
      const response = await dataProvider(GET_ONE, 'exam-tickets', {
        id: workId,
        url: 'exam-tickets'
      })
      setWorkData(response.data)
    } catch (e) {
      throw new Error(e.message)
    }
  }

  useEffect(() => {
    if (workId) {
      fetchWork()
    }
    return () => {
      setWorkData(null)
    }
  }, [workId])

  if (!workData) return null

  return (
    <Dialog
      maxWidth="md"
      open={showModal}
      onClose={closeModal}
    >
      <DialogTitle>Экзаменационная работа {workData.exam.level.id === 3 ? '3-его' : (workData.exam.level.id + '-ого')} этапа №{workData.ticket.number}</DialogTitle>
      <DialogContent>
        <Accordion atomic={true}>
          {workData && workData.segments.map((segment, index) => {
            if (segment.case) {
              return <AccordionItem title={'Задание ' + (index + 1)}>
                <h2 className={classes.pLeft}>Сегмент {segment.id}</h2>
                <div className={classes.accordionItem}>
                  <div className={classes.textTask}>
                    <div className={classes.question}>
                      {Parser(formatHtml(segment.case.task.content))}
                    </div>
                  </div>
                  <div className={classes.question}>
                    <span className={classes.link} onClick={toggleTask} data-label="Скрыть">Показать задание</span>
                  </div>
                  {(segment.case.questions.map((question, number) => {
                    return (
                      <div className={classes.formGroup}>
                        <div>
                          <div className={classes.textHide}>
                            <div className={classes.panelHeader}>Вопрос {(number + 1)}</div>
                            <div className={classes.question}>
                              {Parser(formatHtml(question.content))}
                            </div>
                          </div>
                          <div className={classes.question}>
                            <span className={classes.link} onClick={toggleQuestion} data-label="Скрыть">Показать вопрос</span>
                          </div>
                        </div>
                        <div className={classes.panelAnswer}>Ответ претендента</div>
                        <div className={classes.question}>
                          {question.applicantAnswer && (
                            <>
                              {Parser(formatHtml(question.applicantAnswer.answer !== null ? question.applicantAnswer.answer : ''))}
                              {question.applicantAnswer.files.length > 0 && (
                                <div className={classes.formGroup}>
                                  <br />
                                  Файлы: {question.applicantAnswer.files.map(file => file.name).join(', ')}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <hr />
                      </div>
                    )
                  }))}
                </div>
              </AccordionItem>
            }
            if (segment.test) {
              return <AccordionItem title={'Вопрос ' + (index + 1)}>
                <div className={classes.accordionItem}>
                  <h2>Сегмент {segment.id}</h2>
                  {
                    {
                      1 : <SimpleTestItem record={segment} />,
                      2 : <MultipleTestItem record={segment} />,
                      3 : <AccordanceTestItem record={segment} />,
                      4 : <CompositeTestItem record={segment} />,
                      5 : <SequenceTestItem record={segment} />,
                      6 : <CalculateTestItem record={segment} />
                    }[segment.test.typeId]
                  }
                  <div className={classes.textTask}>
                    <div className={classes.question}>
                      {Parser(formatHtml(segment.test.content))}
                    </div>
                  </div>
                </div>
              </AccordionItem>
            }
          })}
        </Accordion>
      </DialogContent>
    </Dialog>
  )
})

export default withDataProvider(ExamTicketPreviewModal)