import React from 'react'


import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = createStyles({
  stepper: {
    marginBottom: '15px'
  },
  stepperItem: {
    display: 'inline-block',
    background: '#e0e0e0',
    marginRight: '3px',
    marginBottom: '10px',
    padding: '1px 15px',
    borderRadius: '3px',
    cursor: 'pointer',
  },
  active: {
    display: 'inline-block',
    background: 'blue',
    color: 'white',
    marginRight: '3px',
    marginBottom: '10px',
    padding: '1px 15px',
    borderRadius: '3px',
    cursor: 'pointer',
  },
  disabled: {
    display: 'inline-block',
    background: 'green',
    color: 'white',
    marginRight: '3px',
    marginBottom: '10px',
    padding: '1px 15px',
    borderRadius: '3px',
    cursor: 'pointer',
  },
})

const ApplicantExamStepper = ({ classes, questionList, activeQuestionId, setActiveQuestion, setCurrentPostion }) => {
  return (
    <div className={classes.stepper}>
      {questionList && questionList.map((examQuestion, i) => (
        <span key={examQuestion.id}>
          {examQuestion.expertMark.isCreated ? (
            <div
              onClick={() => {
                setActiveQuestion(examQuestion)
                setCurrentPostion(i + 1)
              }}
              className={classes.disabled}>{i + 1}</div>
          ) : (
              <div onClick={() => {
                setActiveQuestion(examQuestion)
                setCurrentPostion(i + 1)
              }} className={examQuestion.id === activeQuestionId ? classes.active : classes.stepperItem}>{i + 1}</div>
            )
          }
        </span>
      ))}
    </div>
  )
}

export default withStyles(styles)(ApplicantExamStepper)
