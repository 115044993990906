import React from "react";
import {createStyles} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Parser from "html-react-parser";
import formatHtml from "../../../utils/formatHtml";

const styles = createStyles({
  panelAnswer: {
    padding: '15px',
    backgroundColor: '#b71c1c',
    color: '#fff',
    marginBottom: '15px'
  },
  formGroup: {
    padding: '0px 15px 15px',
    marginBottom: '15px'
  },
  check: {
    backgroundColor: '#b71c1c',
    color: '#fff',
    padding: '2px 5px',
    fontSize: '12px'
  },
  correct: {
    backgroundColor: '#28a745',
    color: '#fff',
    padding: '2px 5px',
    fontSize: '12px'
  }
})

const SequenceTestItem = withStyles(styles)(({record, classes}: any) => {
  const testAnswers = record.test.answers.sort((a, b) => {
    return a.correctPosition - b.correctPosition;
  })

  const getCorrectClass = (answerId, applicantAnswerId) => {
    return answerId == applicantAnswerId ? classes.correct : classes.check
  }

  return (
    <div>
      <h3>{Parser(formatHtml(record.test.question.content))}</h3>
      <ol>
        {testAnswers.map((answer, index) => {
          return <li className={classes.formGroup}>
            {answer.content.replace(/(<([^>]+)>)/gi, "")}
            &nbsp;
            {(answer.applicantAnswer && record.test.applicantAnswers[index]) && (
              <span className={getCorrectClass(answer.id, record.test.applicantAnswers[index].answerId)}>
                {answer.id == record.test.applicantAnswers[index].answerId ? 'Правильно' : 'Неправильно'}
              </span>
            )}
          </li>
        })}
      </ol>
    </div>
  )
})

export default SequenceTestItem