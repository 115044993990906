import React from 'react'
import * as Yup from 'yup'
import {
  SimpleForm, TextInput, Toolbar, SaveButton, SelectInput, SelectArrayInput
} from 'react-admin'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import InputMask from 'react-input-mask'
import useRolesList from '../../../utils/useRolesList'
import useSpecsList from '../../../utils/useSpecsList'
import { statusesLocal as statuses } from '../../../utils/getFields'
import { SERVER_URL } from '../../../providers/constants'

interface IProps extends WithStyles {
  record: IProfile
}

const styles = {
  img: {
    width: '102px',
    height: '102px'
  },
  input: {
    paddingBottom: 3
  }
}

const StyledImageField = withStyles(styles)(({ classes, record }: IProps) => {
  const src = record.image ? `${SERVER_URL}${record.image}` : 'img-placeholder.png'
  return (
    <img src={src} alt="" className={classes.img} />
  )
})

const PhoneInput = props => (
  <InputMask mask="+7(999)999-99-99" maskChar="_" {...props} />
)


const DataToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Сохранить данные" {...props} />
  </Toolbar>
)

const DataForm = props => {
  const { record, permissions, type } = props
  const roles = useRolesList()
  const specs = useSpecsList()

  const formDefaultValue = { status: 'active' }

  const schemaEdit = Yup.object().shape({
    email: Yup.string()
      .email('Некорректный адрес электронной почты')
      .required('Обязательное поле'),
    name: Yup.string()
      .required('Обязательное поле')
      .max(255, 'Превышено допустимое количество символов'),
    middleName: Yup.string()
      .required('Обязательное поле')
      .max(255, 'Превышено допустимое количество символов'),
    surname: Yup.string()
      .required('Обязательное поле')
      .max(255, 'Превышено допустимое количество символов'),
    phone: Yup.string()
      .required('Обязательное поле')
      .matches(/^(8-?|\+?7-?)?(\(?\d{3}\)?)-?(\d-?){6}\d$/, 'Номер телефона должен состоять из 10 цифр')
  })

  const schemaCreate = schemaEdit.concat(Yup.object().shape({
    password: Yup.string()
      .required('Обязательное поле'),
    passwordRep: Yup.string()
      .required('Обязательное поле')
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
  }))

  const schema = type === 'edit' ? schemaEdit : schemaCreate

  const asyncValidate = values => new Promise((resolve, reject) => {
    schema.validate(values, { abortEarly: false })
      .then(() => {
        resolve()
      })
      .catch(errors => {
        const rErrors = {}
        errors.inner.forEach(error => {
          rErrors[error.path] = error.message
        })
        reject(rErrors)
      })
  })

  return (
    <SimpleForm
      toolbar={<DataToolbar />}
      asyncValidate={asyncValidate}
      redirect="show"
      autoComplete="off"
      defaultValue={type === 'create' ? formDefaultValue : null}
      {...props}
    >
      <StyledImageField record={record} />
      <TextInput
        autoFocus
        source="name"
        label="Имя"
        name="name"
        fullWidth
      />
      <TextInput
        source="middleName"
        label="Отчество"
        name="middleName"
        fullWidth
      />
      <TextInput
        source="surname"
        label="Фамилия"
        name="surname"
        fullWidth
      />
      <TextInput
        source="email"
        label="E-mail"
        type="email"
        name="email"
        fullWidth
      />
      <TextInput
        source="phone"
        label="Телефон"
        type="tel"
        name="phone"
        placeholder="+7(123)456-78-90"
        fullWidth
        InputProps={{
          inputComponent: PhoneInput
        }}
      />
      {permissions && (permissions.role === 'admin') && (
        <>
          <SelectInput
            optionText="description"
            optionValue="name"
            label="Роль"
            choices={roles}
            source="role"
            fullWidth
          />
          <SelectArrayInput
            optionText="name"
            optionValue="id"
            label="Область"
            source="specializationsIds"
            choices={specs}
            fullWidth
            allowEmpty
          />
          <SelectInput
            optionText="name"
            optionValue="id"
            label="Cтатус"
            source="status"
            choices={statuses}
            fullWidth
            name="status"
          />
        </>
      )}
      {type === 'create' && (
        <>
          <TextInput
            source="password"
            label="Пароль"
            type="password"
            name="password"
            fullWidth
            autoComplete="new-password"
          />
          <TextInput
            source="passwordRep"
            label="Повторите пароль"
            type="password"
            name="passwordRep"
            fullWidth
          />
        </>
      )}
    </SimpleForm>
  )
}

export default DataForm
