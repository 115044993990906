const SERVER_URL = process.env.REACT_APP_API_URL
const DEACTIVATE = 'DEACTIVATE'
const ASSIGN_EXPERT = 'ASSIGN_EXPERT'
const REMOVE_FILE = 'REMOVE_FILE'
const ATTACH_FILE = 'ATTACH_FILE'
const SET_CHECK_STATUS = 'SET_CHECK_STATUS'
const CLONE_SEGMENT = 'CLONE_SEGMENT'

export {
  DEACTIVATE,
  ASSIGN_EXPERT,
  REMOVE_FILE,
  ATTACH_FILE,
  SERVER_URL,
  SET_CHECK_STATUS,
  CLONE_SEGMENT
}
