import React from 'react'
import {
  List, CreateButton, CardActions
} from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { SortButton, NotPermitted } from '../../elements'
import UserPreview from './UserPreview'
import UserListFilters from './UserListFilters'
import Pagination from '../../elements/Pagination'

interface IProps {
  ids?: number[],
  data?: IProfile,
  total?: number
}

const Users: React.FunctionComponent<IProps> = ({
  ids, data, total
}) => (
  <>
    {total > 0 ? (
      <Grid container spacing={8}>
        {ids.map(id => (
          <Grid key={id} item style={{ width: '50%' }}>
            <UserPreview id={id} data={data} />
          </Grid>
        ))}
      </Grid>
    ) : (
      <Typography style={{ textAlign: 'center', padding: '20px 0' }}>Результатов нет</Typography>
    )}
  </>
)

const UserListActions = ({
  filters, resource, showFilter, displayedFilters, filterValues, hideFilter, setSort, permissions
}: any) => (
  <CardActions style={{ alignItems: 'center' }}>
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      hideFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    }) }
    <SortButton setSort={setSort} />
    {permissions && (permissions.role === 'admin') && (
      <CreateButton basePath="users" label="Создать пользователя" />
    )}
  </CardActions>
)

const UsersList = ({ permissions, ...props }) => {
  const isPermitted = permissions ? ['admin', 'managerMo', 'directorMo', 'methodist', 'user'].includes(permissions.role) : undefined
  return (
    isPermitted ? (
      <List
        {...props}
        title=" "
        exporter={false}
        bulkActionButtons={false}
        actions={<UserListActions permissions={permissions} />}
        filters={<UserListFilters />}
        pagination={<Pagination rows={[10, 20, 30]} />}
      >
        <Users />
      </List>
    ) : (
      <NotPermitted />
    )
  )
}


export default UsersList
