import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useExamLevelsList = () => {
  const [arrayExamLevels, setArrayExamLevels] = useState([])

  useEffect(() => {
    (async () => {
      const examLevelsList = await rolesRoute(GET_LIST, 'exam-levels')
      setArrayExamLevels(examLevelsList.data)
    })()
  }, [])

  return arrayExamLevels
}

export default useExamLevelsList
