import React, { useEffect, useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import Cookies from 'js-cookie'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SegmentsList from './Segments/SegmentsList'

const styles = () => createStyles({
  tabs: {
    background: '#fff',
    border: '1px solid #d5d5d5',
    borderBottom: 'none'
  },
})

const getSelectedTabID = () => {
  const href = window.location.href
  const hash = href.match(/tab_\d+/g)
  
  if (hash) {
    const num = hash[0].match(/\d+/g)[0]

    return +num
  } else {
    return null
  }
}

const TabbedList = ({ classes, ...props }) => {
  const assignId = Cookies.get('userId')
  const role = Cookies.get('role')
  const tabsList = [
    { id: 1, name: 'Новые сегменты', roles: ['admin', 'managerMo', 'directorMo'] },
    { id: 4, name: 'На проверке у экспертов', roles: ['admin', 'managerMo', 'expertDeveloper', 'expertReviewer', 'directorMo'] },
    { id: 8, name: 'На согласовании у начальника МО', roles: ['admin', 'managerMo', 'directorMo'] },
    { id: 7, name: 'На рецензировании у корректоров', roles: ['admin', 'managerMo', 'editor', 'directorMo'] },
    { id: 9, name: 'на утверждении', roles: ['admin', 'managerMo', 'methodist', 'directorMo'] },
    { id: 10, name: 'Утвержденные', roles: ['admin', 'managerMo', 'methodist', 'directorMo'] },
    { id: 11, name: 'На экзамен', roles: ['admin', 'managerMo', 'methodist', 'directorMo'] }
  ]

  const tabsListPermission = tabsList.filter(tab => tab.roles.includes(role))
  const [selectedTab, setSelectedTab] = useState(getSelectedTabID() || tabsListPermission.length && tabsListPermission[0].id)

  useEffect(() => {
    props.history.push({...props.location, hash: `tab_${selectedTab}`})
  }, [selectedTab])

  const handleChange = (event, value) => {
    setSelectedTab(value)
  }

  return (
    <>
      <Tabs
        className={classes.tabs}
        centered
        onChange={handleChange}
        value={selectedTab}
        fullWidth
      >
        {tabsListPermission.map(item => (
          <Tab
            key={item.id}
            label={item.name}
            value={item.id}
          />
        ))}
      </Tabs>
      <Divider />
      {(role === 'admin' || role === 'managerMo' || role === 'directorMo') ? (
        <SegmentsList
          {...props}
          statusId={selectedTab}
        />
      ) : (
          <SegmentsList
            {...props}
            statusId={selectedTab}
            assignId={assignId}
          />
        )}
    </>
  )
}

export default withStyles(styles)(TabbedList)
