import React, { useState, useEffect } from 'react'
import {
  List,
  Datagrid,
  CardActions,
  FunctionField,
  withDataProvider,
  GET_LIST,
  CREATE,
  ShowButton,
  showNotification,
} from 'react-admin'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AppointmentFilter from './AppointmentFilter'
import Pagination from '../../elements/Pagination'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'

import AppointmentModal from './AppointmentModal'

const styles = () => createStyles({
  examTicketsTitle: {
    fontSize: 48,
    fontWeight: 400,
    marginBottom: 20
  },
  expertsButton: {
    marginTop: 30,
    marginLeft: 'auto'
  }
})

const AppointmentActions = ({
  filters,
  resource,
  showFilter,
  displayedFilters,
  filterValues,
  hideFilter,
}: any) => (
    <CardActions style={{ alignItems: 'center' }}>
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        hideFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </CardActions>
  )

let idListExperts = []

const Appointment = withStyles(styles)(({ classes, dataProvider, redirect, dispatch, ...props }: any) => {
  const { permissions } = props
  const [experts, setExperts] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentUserId, setCurrentUserId] = useState(false)

  const handleCheckedExamTicket = ({ target }, record) => {
    if (target.checked) {
      setExperts([...experts, record.id])
      idListExperts.push(record.id)
    } else {
      setExperts(experts.filter(id => id !== record.id))
      idListExperts = idListExperts.filter(id => id !== record.id)
    }
  }

  const handleAsignExperts = () => {
    setIsLoad(true)
    const tickets = JSON.parse(localStorage.getItem('worksIds'))
    dataProvider(CREATE, 'exam-check', { data: { experts: idListExperts, tickets } })

    dispatch(showNotification('Эксперты распределены', 'info'))

    setTimeout(() => {
      redirect()
    }, 2000)
  }

  return (
    <>
      <Typography
        variant="title"
        className={classes.examTicketsTitle}
      >
        Назначение экспертов и цензоров
      </Typography>
      <AppointmentModal
        currentUserId={currentUserId}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <List
        {...props}
        title=" "
        exporter={false}
        bulkActionButtons={false}
        actions={<AppointmentActions />}
        filter={{ specializationId: localStorage.getItem('moduleId') }}
        filters={<AppointmentFilter permissions={permissions} />}
        perPage={20}
        pagination={<Pagination rows={[20, 50, 100]} />}
      >
        <Datagrid>
          <FunctionField
            label=" "
            render={
              record => (
                <>
                  <Checkbox
                    color="primary"
                    onChange={e => handleCheckedExamTicket(e, record)}
                  />
                </>
              )
            }
          />
          <FunctionField
            label="ФИО"
            render={record => `${record.name} ${record.middleName} ${record.surname}`}
            sortBy="FIO"
          />
          <FunctionField
            label="Email"
            render={record => record.email}
            sortBy="email"
          />
          <FunctionField
            label="Количество работ на проверка"
            render={record => record.examsCount}
            sortBy="examsCount"
          />
          <FunctionField
            label="Последняя активность"
            render={record => record.updatedAt}
            sortBy="examsCount"
          />
          <FunctionField
            label=" "
            render={record => (
              <Button
                disableRipple
                style={{ color: '#c62828' }}
                onClick={() => {
                  setCurrentUserId(record.id)
                  setShowModal(true)
                }}
              >
                <InfoOutlinedIcon />
              </Button>
            )} />
        </Datagrid>
      </List>

      <Button
        className={classes.expertsButton}
        variant="contained"
        color="primary"
        disableRipple
        onClick={handleAsignExperts}
        disabled={experts.length === 0 || isLoad}
      >
        Распределить экспертов
      </Button>
    </>
  )
})

const mapDispatchToProps = dispatch => ({
  redirect: id => dispatch(push(`/exam-tickets`))
})

export default connect(null, mapDispatchToProps)(withDataProvider(Appointment))