import React, { useState, useEffect } from 'react'
import {
  List,
  Datagrid,
  CardActions,
  FunctionField,
  withDataProvider,
  GET_LIST,
  ShowButton
} from 'react-admin'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import ExamTicketsFilter from './ExamTicketsFilter'
import Pagination from '../../elements/Pagination'
import ModulesName from './ModulesName'
import ExamTicketsWorkStatus from './ExamTicketsWorkStatus'
import ExamTicketsVerifyStatus from './ExamTicketsVerifyStatus'
import ExamTicketModal from './ExamTicketModal'
import ExamTicketPreviewModal from './ExamTicketPreviewModal'


const styles = () => createStyles({
  examTicketsTitle: {
    fontSize: 48,
    fontWeight: 400,
    marginBottom: 20
  },
  expertsButton: {
    marginTop: 30,
    width: 210,
    marginLeft: 'auto'
  },
  textGray: {
    fontWeight: 'bold'
  },
  formGroup: {
    margin: '5px 0'
  },
  flex: {
    display: 'flex'
  }
})

const ExamTicketsActions = ({
  filters,
  resource,
  showFilter,
  displayedFilters,
  filterValues,
  hideFilter,
}: any) => (
  <CardActions style={{ alignItems: 'center' }}>
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      hideFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
  </CardActions>
)

const ExamTickets = withStyles(styles)(({
  classes, dataProvider, redirect, ...props
}: any) => {
  const [examTickets, setExamTickets] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [workId, setWorkId] = useState(null)
  const { permissions } = props

  const fetchExamTicketsList = async () => {
    try {
      await dataProvider(GET_LIST, 'exam-tickets')
    } catch (e) {
      throw new Error(e.message)
    }
  }

  useEffect(() => {
    fetchExamTicketsList()
  }, [examTickets.length])

  const handleCheckedExamTicket = ({ target }, record) => {
    setExamTickets(target.checked
      ? [...examTickets, record]
      : examTickets.filter(item => item.id !== record.id))
  }

  const disabledExamTicketsCheckboxes = record => examTickets.some(item => record.exam.modules[0].name !== item.exam.modules[0].name)

  const isChecked = id => examTickets.some(item => id === item.id)

  /**
   * Фильтр по экспертам.
   */
  const expertsFilter = (users) => users.filter((user) => [0, 1, 2, 3, 4, 5].indexOf(user.position) !== -1)

  /**
   * Фильтр по комиссии.
   */
  const commissionFilter = (users) => users.filter((user) => [6, 7, 8].indexOf(user.position) !== -1)

  const handleAsignExperts = () => {
    const worksIds = examTickets.map(item => item.id)
    localStorage.setItem('moduleId', examTickets[0].exam.moduleId)
    localStorage.setItem('worksIds', JSON.stringify(worksIds))
    redirect()
  }

  const getFio = (user, toInitials = false) => {
    const fio = `${user.surname.trim() || ''} ${user.name.trim() || ''} ${user.middleName.trim() || ''}`

    if (toInitials) {
      const [surname, name, middleName] = fio.split(' ')
      return `${surname} ${name[0]}. ${middleName[0]}.`
    }

    return fio
  }

  return (
    <>
      <Typography
        variant="title"
        className={classes.examTicketsTitle}
      >
        Список работ на проверку
      </Typography>
      <List
        {...props}
        title=" "
        exporter={false}
        bulkActionButtons={false}
        actions={<ExamTicketsActions />}
        // filter={{ workStatus: 'verify ' }}
        filters={<ExamTicketsFilter permissions={permissions} />}
        perPage={50}
        pagination={<Pagination rows={[20, 50, 100]} />}
      >
        <Datagrid>
          <FunctionField
            label=" "
            style={{ width: '30px' }}
            render={
              record => (
                <>
                  {permissions && permissions.role !== 'censor' && (
                    <Checkbox
                      checked={isChecked(record.id)}
                      disabled={disabledExamTicketsCheckboxes(record)}
                      color="primary"
                      onChange={e => handleCheckedExamTicket(e, record)}
                    />
                  )}
                </>
              )
            }
          />
          <FunctionField
            label="Номер билета"
            render={record => (record.ticket && record.ticket.number) || 'Нет данных'}
            sortBy="number"
          />
          <FunctionField
            label="Уровень"
            render={record => record.exam.level.name}
            sortBy="levelId"
          />
          <FunctionField
            label="Модуль"
            render={record => <ModulesName record={record} />}
            sortBy="moduleId"
          />
          <FunctionField
            label="Назначен на"
            render={record => (
              <>
                <div className={classes.formGroup}>
                  <span className={classes.textGray}>Цензор: </span>
                  {record.assign ? getFio(record.assign, true) : 'Не назначен'}
                </div>
                <div className={classes.formGroup}>
                  <div>
                    <span className={classes.textGray}>Эксперты: </span>
                    {record.experts.length > 0 && (
                      <>
                        {expertsFilter(record.experts).map(expert => getFio(expert, true)).join(', ')}
                      </>
                    )}
                  </div>

                  <div>
                    <span className={classes.textGray}>Комиссия: </span>
                    {record.experts.length > 0 && (
                      <>
                        {commissionFilter(record.experts).map(expert => getFio(expert, true)).join(', ')}
                      </>
                    )}
                  </div>

                  {record.experts.length === 0 && (
                    <>
                      Не назначены
                    </>
                  )}
                </div>
              </>
            )}
            sortBy="expertId"
          />
          <FunctionField
            label="Дата экзамена"
            render={record => moment(record.exam.examDate).format('DD.MM.YYYY') || 'Нет данных'}
            sortBy="examDate"
          />
          <FunctionField
            label="Статус проверки"
            render={record => <ExamTicketsVerifyStatus status={record.verifyStatus} /> || 'Нет данных'}
            sortBy="verifyStatus"
          />
          {permissions && permissions.role === 'managerMo' && (
            <FunctionField
              label="Этапы проверки"
              render={record => (
                <>
                  {record.verifyExperts.map(expert => <div>{`${getFio(expert, true)}`}</div>)}
                </>
              )}
              sortBy="verifyExperts"
            />
          )}
          <FunctionField
            label="Оценка"
            render={record => record.mark}
          />
          {permissions && (permissions.role === 'censor' || permissions.role === 'expertReviewer') ? (
            <ShowButton />
          ) : (
            <FunctionField
              label=" "
              render={record => (
                <div className={classes.flex}>
                  <Button
                    disableRipple
                    style={{ color: '#c62828' }}
                    onClick={() => {
                      setShowModal(true)
                      setWorkId(record.id)
                    }}
                  >
                    <InfoOutlinedIcon />
                  </Button>
                  <Button
                    disableRipple
                    style={{ color: '#c62828' }}
                    onClick={() => {
                      setShowPreviewModal(true)
                      setWorkId(record.id)
                    }}
                  >
                    <VisibilityIcon />
                  </Button>
                </div>
              )}
            />
          )}
        </Datagrid>
      </List>
      <ExamTicketModal
        showModal={showModal}
        setShowModal={setShowModal}
        setWorkId={setWorkId}
        workId={workId}
      />
      <ExamTicketPreviewModal
        showModal={showPreviewModal}
        setShowModal={setShowPreviewModal}
        setWorkId={setWorkId}
        workId={workId}
      />
      {permissions && permissions.role !== 'expertReviewer' && (
        <Button
          className={classes.expertsButton}
          variant="contained"
          color="primary"
          disableRipple
          disabled={examTickets.length === 0}
          onClick={handleAsignExperts}
        >
          Назначить экспертов
        </Button>
      )}
    </>
  )
})


const mapDispatchToProps = dispatch => ({
  redirect: id => dispatch(push('/experts'))
})

export default connect(null, mapDispatchToProps)(withDataProvider(ExamTickets))
