import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

interface IProps {
  modalTitle: string,
  label: string,
  content: any,
  confirm: any,
  className?: string,
  onBeforeConfirm?: any
}

const FormDialog = ({
  modalTitle,
  label,
  content,
  confirm,
  onBeforeConfirm,
  className,
}: IProps) => {
  const [open, setOpen] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [warningTitle, setWarningTitle] = useState('Подтвердите действие')
  const [warningCancelText, setWarningCancelText] = useState('Отмена')
  const [warningConfirmText, setWarningConfirmText] = useState('OK')
  const [warningContent, setWarningContent] = useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openWarning = () => {
    setShowWarning(true)
  }

  const closeWarning = () => {
    setShowWarning(false)
  }

  const handleConfirm = () => {
    onBeforeConfirm().then(
      result => {
        if (!result.result) {
          confirm()
          handleClose()
        } else {
          setWarningContent(result.error.map(i => <p>{i}</p>))
          setWarningCancelText(result.warningCancelText)
          setWarningConfirmText(result.warningConfirmText)
          openWarning()
        }
      }
    )
  }
  const confirmInWarning = () => {
    confirm()
    handleClose()
    closeWarning()
  }

  return (
    <>
      <Button
        variant="outlined"
        className={className}
        color="primary"
        onClick={handleClickOpen}
      >
        {label}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
                        Отмена
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {label}
          </Button>
        </DialogActions>
      </Dialog>
      {typeof (onBeforeConfirm) === 'function' ? (
        <Dialog open={showWarning} onClose={closeWarning} aria-labelledby="form-dialog-title">
          <DialogTitle>{warningTitle || 'Подтвердите действие'}</DialogTitle>
          <DialogContent>
            {warningContent}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeWarning} color="primary">
              {warningCancelText || 'Отмена'}
            </Button>
            <Button onClick={confirmInWarning} color="primary">
              {warningConfirmText || 'ОК'}
            </Button>
          </DialogActions>
        </Dialog>
      ) : ''}
    </>
  )
}

export default FormDialog
