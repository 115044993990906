
import React, { useState } from 'react'

import Radio from '@material-ui/core/Radio'

import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import {
  UPDATE,
} from 'react-admin'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import CKEditorField from '../../../../elements/CKEditorField'
import SelectList from '../SelectList'

const styles = () => createStyles({
  wrap: {
    width: '100%',
  },
  textAreaWrap: {
    marginBottom: '25px'
  },
  button: {
    marginRight: '20px'
  },
  textAreaHeader: {
    float: 'left'
  },
  textAreaLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  answerTitle: {
    display: 'flex'
  },
  buttonsWrap: {
    display: 'flex'
  },
  select: {
    width: 55,
    marginRight: 15
  }
})

const MultiSegmentAnswerItem = (
  {
    classes,
    answer,
    changeCorretAnswer,
    typeId,
    dataProvider,
    answerPositionList,
    deleteAnswer,
    changePositionAnwser,
    editAnswer,
    status
  }) => {
  const {
    id,
    content,
    number
  } = answer

  const [showModal, setShowModal] = useState<boolean>(false)

  const changeAnswer = id => {
    dataProvider(UPDATE, 'segments', {
      id,
      url: 'answers',
      data: {
        content
      },
    }, {
      onSuccess: {
        notification: { body: 'Сохранено', level: 'info' },
        refresh: true
      },
    })
  }

  return (
    <div className={classes.textAreaWrap}>
      <div className={classes.wrap}>
        <div className={classes.textAreaHeader}>
          <div className={classes.answerTitle}>
            {typeId !== 6 && (
              <>
                {typeId !== 5 ? (
                  <Radio
                    color="primary"
                    checked={!!answer.correct}
                    onClick={() => changeCorretAnswer(id)}
                    value="e"
                    name="radio-button-demo"
                    aria-label="E"
                  />
                ) : (
                  <>
                    <SelectList
                      name="Позиция"
                      selectedId={answer.correctPosition}
                      list={answerPositionList}
                      className={classes.select}
                      onChange={currentPosition => { changePositionAnwser(id, currentPosition) }}
                    />
                  </>
                )}
              </>
            )}
            <FormLabel className={classes.textAreaLabel} filled>{`Ответ ${number}`}</FormLabel>
          </div>
        </div>
        <CKEditorField
          classes={classes.textAreaWrap}
          initialValue={content}
          cb={text => editAnswer(id, text)}
          status={status}
          isSmaller
          id={answer.id}
          idType="answerId"
          typeId={typeId}
          save={() => changeAnswer(id)}
          deleteEditor={() => setShowModal(true)}
          labelSave={`Сохранить ответ ${number}`}
          labelDelete={`Удалить ответ ${number}`}
          symbols={answer.symbols}
        />
        <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">Вы действительно хотите удалить?</DialogTitle>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={() => setShowModal(false)}
              color="primary"
            >
              Нет
            </Button>
            <Button
              className={classes.button}
              onClick={() => deleteAnswer(id)}
              color="primary"
            >
              Да
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

export default withStyles(styles)(MultiSegmentAnswerItem)
