import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { CustomTextField } from '.'

const styles = {
  listItem: {
    paddingTop: '0',
    paddingBottom: '0',
    display: 'block'
  },
  label: {
    marginRight: '5px',
    display: 'inline-block'
  },
  textField: {
    display: 'inline',
  },
}

const Item = ({
  className,
  classes,
  textFieldClassName = '',
  label,
  record,
  source,
  cb,
}) => (
    <ListItem className={`${classes.listItem} ${className}`}>
      <Typography component="span" className={classes.label}>
        {label}
      </Typography>
      <CustomTextField
        record={record}
        source={source}
        className={`${classes.textField} ${textFieldClassName}`}
        cb={cb}
      />
    </ListItem>
  )

export default withStyles(styles)(Item)
