import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import specializationsRoute from '../providers/module/roles'

const useSpecsList = () => {
  const [arraySpecs, setArraySpecs] = useState([])

  useEffect(() => {
    (async () => {
      const specsList = await specializationsRoute(GET_LIST, 'specializations')
      setArraySpecs(specsList.data)
    })()
  }, [])

  return arraySpecs
}

export default useSpecsList
