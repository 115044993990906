import React, { useEffect } from 'react'

import {
  UPDATE,
  DELETE,
  CREATE
} from 'react-admin'

import Button from '@material-ui/core/Button'
import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TestSegmentAnswerItem from './TestSegmentAnswerItemCompact'

const styles = () => createStyles({
  buttonsWrap: {
    display: 'flex',
    marginBottom: '25px',
    justifyContent: 'flex-end'
  },
  answersWrapper: {

  }
})

interface IProps extends WithStyles {
  questionId: number,
  testId: number,
  answers: any,
  originalAnswers: any,
  dataProvider: any,
  typeId: number,
  isQuestionType: boolean,
  status?: string,
  dispatch: (type: any)=> void
}

const TestSegmentAnswerCompact = withStyles(styles)(({
  classes,
  testId = 0,
  questionId = 0,
  answers,
  dataProvider,
  typeId,
  isQuestionType = false,
  status,
  dispatch
}: IProps) => {
  const singleСhoice = [1, 3, 4]

  const answerPositionList = answers && answers.map((answer, i) => ({
    id: i + 1,
    name: i + 1
  }))

  const saveAnswer = (id, data) => {
    dataProvider(UPDATE, 'segments', {
      id,
      url: 'answers',
      data,
    })
  }

  useEffect(() => {
    answers.forEach(answer => {
      const {
        id,
        correct,
        correctPosition
      } = answer

      if (!isQuestionType) {
        saveAnswer(id, {
          testId,
          correctPosition,
          correct
        })
      } else {
        saveAnswer(id, {
          questionId,
          correctPosition,
          correct
        })
      }
    })
  }, [answers])

  const changePositionAnwser = (id, correctPosition) => { dispatch({ type: 'changePositionAnwser', id, correctPosition }) }

  const savePosition = () => {
    const isCorrect = answers.some(elem => !elem.correctPosition)

    if (!isCorrect) {
      answers.forEach(answer => {
        const {
          id,
          correct,
          correctPosition,
        } = answer

        if (!isQuestionType) {
          saveAnswer(id, {
            testId,
            correct,
            correctPosition
          })
        } else {
          saveAnswer(id, {
            questionId,
            correct,
            correctPosition
          })
        }
      })
    } else {
      alert('Выберите все позиции')
    }
  }

  const changeCorretAnswer = id => {
    let name = 'changeCorretAnswer'
    let singleName = 'changeSingleCorretAnswer'

    if (isQuestionType) {
      name = 'changeCorretAnswerForQuestion'
      singleName = 'changeSingleCorretAnswerForQuestion'
    }

    if (singleСhoice.includes(typeId)) {
      dispatch({ type: singleName, id, questionId })
    } else {
      dispatch({ type: name, id })
    }
  }

  const addAnswer = async () => {
    let answerData

    if (isQuestionType) {
      answerData = {
        questionId,
        testId: null,
        content: 'addAnswerForQuestion',
      }
    } else {
      answerData = {
        testId,
        content: 'test',
      }
    }

    const result = await dataProvider(CREATE, 'segments', {
      url: 'answers',
      data: answerData
    }, {
      onSuccess: {
        notification: { body: 'Сохранено', level: 'info' }
      },
    })

    const {
      data
    } = result

    if (isQuestionType) {
      dispatch({ type: 'addAnswerForQuestion', questionId, ...data })
    } else {
      dispatch({ type: 'addAnswer', ...data })
    }
  }

  const deleteAnswer = async id => {
    await dataProvider(DELETE, 'segments', {
      id,
      url: 'answers'
    }, {
      onSuccess: {
        notification: { body: 'Сохранено', level: 'info' }
      }
    })

    if (isQuestionType) {
      dispatch({ type: 'deleteAnswerForQuestion', questionId, id })
    } else {
      dispatch({ type: 'deleteAnswer', id })
    }
  }

  const editAnswer = (id, content) => {
    const actionType = isQuestionType ? 'editAnswerForSubQuestion' : 'editAnswers'
    dispatch({ type: actionType, id, content })
  }


  return (
    <div className={classes.answersWrapper}>
      {answers && answers.map(answer => (
        <TestSegmentAnswerItem
          key={answer.id}
          answer={answer}
          dataProvider={dataProvider}
          typeId={typeId}
          answerPositionList={answerPositionList}
          changePositionAnwser={changePositionAnwser}
          changeCorretAnswer={changeCorretAnswer}
          editAnswer={editAnswer}
          name={`answer${answer.number}`}
          deleteAnswer={deleteAnswer}
          status={status}
        />
      ))}
    </div>
  )
})

export default TestSegmentAnswerCompact
