import {
  GET_MANY, GET_LIST
} from 'react-admin'

import { stringify } from 'query-string'
import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

const testTypesRoute = async (type, resource) => {
  const options: AxiosRequestConfig = {}

  if ([GET_MANY, GET_LIST].includes(type)) {
    const query = {
      token: `${localStorage.getItem('token')}`,
    }
    options.url = `/${resource}?${stringify(query)}`
  } else {
    throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  const response = await axios(options).catch(error => {
    const {
      response: {
        status: statusCode
      }
    } = error

    if (statusCode === 401) {
      localStorage.removeItem('token')
      Cookies.remove('token')
      Cookies.remove('role')
      Cookies.remove('userId')
      Cookies.remove('userName')
      return Promise.resolve()
    }
  })

  const { data: dataResponse }: any = response
  const data = {
    data: null
  }

  data.data = dataResponse
  return data
}

export default testTypesRoute
