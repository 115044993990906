import React from 'react'
import SegmentHead from './SegmentHead'
import SegmentInfo from './SegmentInfo'
import SegmentVersion from './SegmentVersion'

const Header = ({
  typeSegmentId,
  test,
  status,
  createdAt,
  updatedAt,
  createdById,
  versions,
  experts,
  symbols,
  isCopy,
  originalId,
  verifyStatus
}) => (
  <div style={{ display: 'flex' }}>
    <SegmentHead
      typeSegmentId={typeSegmentId}
      test={test}
      verifyStatus={verifyStatus}
      status={status}
      createdById={createdById}
    />
    <div>
      <SegmentInfo
        status={status}
        createdAt={createdAt}
        updatedAt={updatedAt}
        createdById={createdById}
        experts={experts}
        symbols={symbols}
        isCopy={isCopy}
        originalId={originalId}
      />
      {versions.length > 0 && (
        <SegmentVersion
          versions={versions}
        />
      )}
    </div>
  </div>
)

export default Header
