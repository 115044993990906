import React from 'react'
import Typography from '@material-ui/core/Typography'

import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = () => createStyles({
  head: {
    fontSize: 36,
    fontWeight: 400,
    marginTop: 20,
    marginLeft: 20
  },
})

const PageTitle = ({ title, classes }: any) => {
  return (
    <Typography
      className={classes.head}
    >
      {title}
    </Typography>
  )
}

export default withStyles(styles)(PageTitle)
