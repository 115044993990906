import React, { useState, useEffect } from 'react'

import Parser from 'html-react-parser'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'

import formatHtml from '../../utils/formatHtml'

import { withStyles } from '@material-ui/core/styles';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: 'green'
    },
  },
  checked: {},
})((props: any): any => <Checkbox color="default" {...props} />);

const ApplicanExamAnswers = ({ answers, activeQuestionId }) => {
  const [value, setValue] = useState([])

  useEffect(() => {
    setValue([])
  }, [activeQuestionId])

  console.log('compos', answers)

  return (
    <FormControl>
      <FormGroup>
        {answers.map(answer => (
          <div >
            <FormControlLabel
              key={answer.id}
              control={<GreenCheckbox checked={answer.applicantAnswer ? answer.applicantAnswer : false} />}
              label={<Label answer={answer} />}
            />
          </div>
        ))}
      </FormGroup>
    </FormControl>
  )
}

const Label = ({ answer }) => {
  let style = {}

  if (answer.correct) {
    style = { color: 'green' }
  } else if (!answer.correct && answer.applicantAnswer) {
    style = { color: 'red' }
  }

  return (
    <div style={style}>
      {Parser(formatHtml(answer.content))}
    </div>
  )
}

export default ApplicanExamAnswers
