import React, { useEffect, useState } from 'react'

import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Parser from 'html-react-parser'

import formatHtml from '../../utils/formatHtml'

import CKEditor from 'ckeditor4-react';

import Card from '@material-ui/core/Card'

import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField'

import {
  List,
  Datagrid,
  CardActions,
  FunctionField,
  withDataProvider,
  GET_LIST,
  Show,
  SimpleForm,
  CREATE,
} from 'react-admin'

const styles = createStyles({
  question: {
    marginBottom: '25px',
    fontWeight: 'bold'
  },
  questionNotation: {
    marginBottom: '16px',
  },
  head: {
    margin: '0px 0px 10px'
  },
  layout: {
    marginTop: '15px',
    padding: '20px'
  },
  question: {
    marginBottom: '20px',
  },
  markInput: {
    marginBottom: '20px',
  },
  filesWrap: {
    padding: '20px 0 0 0'
  },
  fileItem: {
    display: 'inline-block',
    padding: '5px 13px',
    border: '1px solid',
    color: '#000',
    textDecoration: 'none',
    borderRadius: '32px',
  },
  commentButttonWrap: {
    margin: '10px 0 0 0',
    textAlign: 'right'
  },
  commentWrap: {
    margin: '10px 0 20px 0',
  },
  commentList: {
    margin: '10px 0 20px 0',
  },
  commentListLink: {
    color: '#1D67A4',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  commentListItem: {
    margin: '10px 0 20px 0',
  },
  commentListItemName: {
    margin: '5px 0 0px 0',
    color: 'gray'
  }
})

const ApplicanExamQuestionComments = ({ dataProvider, classes, comments, segmentId, ticketId, dispatchR, questionId, isTest }) => {
  const [showComments, setShowComments] = useState(false)
  const [currentComment, setCurrentComment] = useState('')

  console.log(ticketId, 'ApplicanExamQuestionComments')

  const createComment = async () => {

    const response = await dataProvider(CREATE, 'ticket-comments', {
      data: {
        ticketId,
        segmentId,
        questionId,
        text: currentComment
      }
    }, {
      onSuccess: {
        notification: { body: 'Комментарий добавлен', level: 'info' },

      },
      onFailure: {
        notification: { body: 'Произошла ошибка', level: 'warning' },

      }
    })

    if (isTest) {
      dispatchR({ type: 'add_comment', payload: { segmentId, comment: response.data } })
    } else {
      dispatchR({ type: 'add_сomment_to_question', payload: { segmentId, questionId, comment: response.data } })
    }

    setCurrentComment('')
  }

  return (
    <>
      {console.log(comments)}
      {comments && (
        <div className={classes.commentWrap}>
          {showComments ? (<a className={classes.commentListLink} onClick={() => setShowComments(false)}> Скрыть комментарий</a>) : (<a className={classes.commentListLink} onClick={() => setShowComments(true)}> Комментарий ({comments.length})</a>)}
          {showComments && (
            <div className={classes.commentList}>
              {comments.map(comments => {
                return (
                  <div key={comments.id} className={classes.commentListItem}>
                    <div className={classes.commentListItemName}>{comments.author.name}</div>
                    <div dangerouslySetInnerHTML={{ __html: comments.text }} />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )
      }
      <CKEditor
        //data={question.expertMark && question.expertMark.comment}
        data={currentComment}
        onChange={evt => {
          const comment = evt.editor.getData()
          setCurrentComment(comment)
          //dispatch({ type: 'add_сomment_to_question', payload: { segmentId, questionId: question.id, comment } })
        }}
      />
      <div className={classes.commentButttonWrap}>
        <Button
          variant="contained"
          color="primary"
          onClick={createComment}
        >
          Добавить комментарий
          </Button>
      </div>
    </>
  )
}

export default withDataProvider(withStyles(styles)(ApplicanExamQuestionComments))
