import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { createStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { withStyles } from '@material-ui/core/styles'

import {
  withDataProvider,
} from 'react-admin'
import TextField from "@material-ui/core/TextField";

const styles = () => createStyles({
  button: {
    marginRight: '15px'
  },
  previewButtonWrap: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px'
  },
  wrapper: {
    margin: '0 15px'
  },
  field: {
    width: '90%'
  }
})

const SegmentCopy = withStyles(styles)(({
  classes,
  dataProvider,
  segmentId,
  redirect
}: any) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [deactivateReason, setDeactivateReason] = useState('')

  const copySegment = async (archive: number = 0) => {
    const response = await dataProvider('CLONE_SEGMENT', 'segments', {
      id: segmentId,
      url: 'segments',
      data: {
        archive,
        comment: deactivateReason
      }
    })
    redirect(response.data.id)
  }

  return (
    <div className={classes.wrapper}>
      <Button variant="outlined" color="primary" onClick={() => setShowModal(true)}>
         Создать новый сегмент на основе существующего
      </Button>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Хотите архивировать старый</DialogTitle>
        <DialogContent>
          <div className={classes.item}>
            <TextField
              className={classes.field}
              label="Укажите причину"
              value={deactivateReason}
              onChange={e => setDeactivateReason(e.target.value)}
            />
          </div>
          <br />
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => copySegment(1)}
          >
           Да
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => copySegment(0)}
          >
           Нет
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => setShowModal(false)}
          >
           Отменить
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
})


const mapDispatchToProps = dispatch => ({
  redirect: id => dispatch(push(`/segments/${id}`))
})

export default connect(null, mapDispatchToProps)(withDataProvider(SegmentCopy))
