import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { SnackbarContent } from "@material-ui/core";
import { red, green } from '@material-ui/core/colors';

interface IProps extends WithStyles {
    message?: string,
    type?: string
}

const styles = {
    success: {
        backgroundColor: green[600],
    },
    fail: {
        backgroundColor: red[700],
    }
}

const SnackbarMessage = ({ message, type, classes }: IProps) => {
    const className = type === 'success' ? classes.success : classes.fail

    return (
        <SnackbarContent
            message={message}
            className={className}
            role="alert"
        />
    )
}

export default withStyles(styles)(SnackbarMessage);