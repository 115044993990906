import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useTestTypesList = (): ITestTypesList[] => {
  const [arrayTestTypesList, setArrayTestTypesList] = useState<ITestTypesList[]>([])

  useEffect(() => {
    (async () => {
      const testTypesList = await rolesRoute(GET_LIST, 'test-types')
      setArrayTestTypesList(testTypesList.data)
    })()
  }, [])

  return arrayTestTypesList
}

export default useTestTypesList
