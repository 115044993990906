import React, { useEffect } from 'react'

import Parser from 'html-react-parser'

import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'

import formatHtml from '../../utils/formatHtml'

import { withStyles } from '@material-ui/core/styles'

const RadioCheckbox = withStyles({
  root: {
    '&$checked': {
      color: 'green'
    },
  },
  checked: {},
})((props: any): any => <Radio color="default" {...props} />);

const ApplicanExamAnswersSingle = ({ answers, activeQuestionId }) => {
  const [value, setValue] = React.useState(undefined)

  console.log(value, 'value')

  useEffect(() => {
    setValue([])
  }, [activeQuestionId])

  return (
    <FormControl>
      <RadioGroup >
        {answers.map(answer => (
          <FormControlLabel key={answer.id} value={String(answer.id)} control={<RadioCheckbox checked={answer.applicantAnswer ? answer.applicantAnswer : false} />} label={<Label answer={answer} />} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

const Label = ({ answer }) => {
  let style = {}

  if (answer.correct) {
    style = { color: 'green' }
  } else if (!answer.correct && answer.applicantAnswer) {
    style = { color: 'red' }
  }

  return (
    <div style={style}>
      {Parser(formatHtml(answer.content))}
    </div>
  )
}

export default ApplicanExamAnswersSingle
