import React from 'react'
import {
  Filter, SelectInput, TextInput
} from 'react-admin'
import useRolesList from '../../utils/useRolesList'
import useSpecsList from '../../utils/useSpecsList'
import { statusesLocal as statuses } from '../../utils/getFields'

const UserListFilters = props => {
  const roles = useRolesList()
  const specs = useSpecsList()

  return (
    <Filter {...props}>
      <SelectInput
        optionText="description"
        optionValue="name"
        label="Роль"
        choices={roles}
        source="role"
        allowEmpty={false}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        label="Специализация"
        choices={specs}
        source="specializationId"
        allowEmpty={false}
      />
      <SelectInput
        optionText="name"
        optionValue="id"
        label="Статус"
        choices={statuses}
        source="status"
        allowEmpty={false}
      />
      <TextInput
        label="№ разработчика"
        source="id"
        allowEmpty={false}
      />
    </Filter>
  )
}


export default UserListFilters
