import { CRUD_UPDATE_FAILURE, CRUD_CREATE_FAILURE, USER_LOGIN_FAILURE } from 'react-admin'
import { stopSubmit, change } from 'redux-form'
import { put, takeEvery } from 'redux-saga/effects'


export function* crudEmailFailure(action) {
  const json = { email: action.error }
  yield put(stopSubmit('record-form', json))
}

export function* userLoginFailure() {
  yield put(change('signIn', 'password', ''))
}

export default function* errorSagas() {
  yield takeEvery(CRUD_UPDATE_FAILURE, crudEmailFailure)
  yield takeEvery(CRUD_CREATE_FAILURE, crudEmailFailure)
  yield takeEvery(USER_LOGIN_FAILURE, userLoginFailure)
}
