import React from 'react'
import {
  TextField, SimpleShowLayout, ReferenceField, DateField, FunctionField
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import { removeHtml } from '../../../utils/getFields'
import SegmentVersion from '../segmentsType/header/SegmentVersion'
import SegmentWatch from '../SegmentWatch'
import ToolbarProvider from '../toolbarProvider/SegmentToolbarProvider'
import { dataProvider } from '../../../providers'
import SegmentCopy from '../SegmentCopy'
import moment from "moment";
import Comments from "../../../elements/comments";


const styles = {
  columnLeft: {
    '&:first-child': {
      paddingTop: '0',
      paddingLeft: '0'
    }
  },
  segmentView: {
    width: '327px'
  },
  formInputField: {
    padding: "10px 0",
    borderBottom: "1px dashed rgba(0, 0, 0, 0.38)"
  },
  title: {
    marginBottom: '5px'
  },
  subHeader: {
    color: '#777'
  },
  groupContent: {
    backgroundColor: '#f5f5f5',
    padding: '15px',
    marginBottom: '15px',

    '& del': {
      backgroundColor: 'rgb(254, 221, 222)'
    },

    '& ins': {
      backgroundColor: 'rgb(230, 254, 207)',
      textDecoration: 'none'
    }
  },
  flexGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  textDanger: {
    color: '#b71c1c'
  }
}

const changesHover = (node) => {
  const user = node.getAttribute('data-username')
  const changeUser = node.getAttribute('data-changedata')
  if (user != undefined) {
    const action = node.tagName === 'INS' ? 'Добавил(-а)' : 'Удалил(а)'
    node.setAttribute('title', action + ' ' + (user ? user : changeUser))
  }
}

const TestSegmentShow = ({ classes, record, permissions }) => (
  <>
    <SimpleShowLayout record={record} basePath="/archive">
      <div className={classes.flexGroup}>
        <div>
          {record.statusId !== 2 ? (
            <>
              <h2 className={classes.title}>Версия сегмента {record.originalId}</h2>
              <div className={classes.subHeader}>
                Изменён {moment(record.createdAt).format('LLL')}. {record.author.surname} {record.author.name}
              </div>
            </>
          ) : (
            <>
              <h2 className={classes.title}>Сегмент {record.id} (в архиве)</h2>
            </>
          )}
        </div>
      </div>

      <ReferenceField
        reference="test-types"
        source="typeId"
        linkType={false}
        label="Тип"
      >
        <TextField source="name" class={classes.formInputField} />
      </ReferenceField>

      <div className={classes.flexGroup}>
        <section>
          <SimpleShowLayout
            record={record}
            basePath="/archive"
            className={classes.columnLeft}
          >
            <TextField
              source="test.module.name"
              label="Модуль"
              class={classes.formInputField}
            />
            <TextField
              className={classes.textDanger}
              source="reasonDeactivated"
              label="Причина архивации"
            />
            <TextField
              source="test.programNumber.name"
              label="Номер вопроса программы"
              class={classes.formInputField}
            />
            <TextField
              source="test.source"
              label="Источник"
              class={classes.formInputField}
            />
            <DateField
              source="test.sourceDate"
              label="Дата редакции источника"
              class={classes.formInputField}
            />
            <TextField
              source="test.sourceLevel.name"
              label="Уровень владения источником"
              class={classes.formInputField}
            />
            <TextField
              source="test.number"
              label="Порядкой номер теста"
              class={classes.formInputField}
            />
            <TextField
              source="test.competence.number"
              label="Номер компетенции"
              class={classes.formInputField}
            />
            <TextField
              source="test.criteria.name"
              label="Тест на"
              class={classes.formInputField}
            />
            <TextField
              source="test.program.name"
              label="Номер вопроса программы"
              class={classes.formInputField}
            />
            <TextField
              source="test.topic.name"
              label="Номер темы"
              class={classes.formInputField}
            />
          </SimpleShowLayout>
        </section>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className={classes.segmentView}>
            <SegmentVersion
              versions={record.versions}
              originalVersionId={record.originalId}
            />
          </div>
        </div>
      </div>

      <div className={classes.groupContent}>
        <div><small className={classes.subHeader}>Вопрос теста</small></div>
        <div
          dangerouslySetInnerHTML={{
            __html: record.test.question.content
          }}
          onMouseMove={(event) => {changesHover(event.target)}}
        />
        <Comments id={record.test.question.id} idType="testQuestionId" showAddBlock={false} originalComment={true} />
      </div>
      {record.test.question && record.test.answers.length > 0 && (
        record.test.answers.map(answer => (
          <div className={classes.groupContent}>
            <div><small className={classes.subHeader}>Ответ №{answer.number}</small></div>
            <div
              dangerouslySetInnerHTML={{
                __html: answer.content
              }}
              onMouseMove={(event) => {changesHover(event.target)}}
            />
            <Comments id={answer.id} idType="answerId" showAddBlock={false} originalComment={true} />
          </div>
        )))}
      {record.test.questions && record.test.questions.map(question => (
        <SimpleShowLayout record={record} basePath="/archive">
          <div className={classes.groupContent}>
            <div><small className={classes.subHeader}>Подвопрос №{question.number}</small></div>
            <div
              dangerouslySetInnerHTML={{
                __html: question.content
              }}
              onMouseMove={(event) => {changesHover(event.target)}}
            />
            <Comments id={question.id} idType="questionId" showAddBlock={false} originalComment={true} />
          </div>
          {question.answers.map(answer => (
            <div className={classes.groupContent}>
              <div><small className={classes.subHeader}>Подвопрос №{answer.number}</small></div>
              <div
                dangerouslySetInnerHTML={{
                  __html: answer.content
                }}
                onMouseMove={(event) => {changesHover(event.target)}}
              />
              <Comments id={answer.id} idType="answerId" showAddBlock={false} originalComment={true} />
            </div>
          ))}
        </SimpleShowLayout>
      ))}
    </SimpleShowLayout>
  </>
)

export default withStyles(styles)(TestSegmentShow)
