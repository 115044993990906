import React, { useState } from 'react'

import {
  UPDATE,
  DELETE,
} from 'react-admin'

import Cookies from 'js-cookie'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '../../../../elements/IconButton'
import CKEditorField from '../../../../elements/CKEditorField'
import FileInfo from '../../../../elements/files/FileInfo'
import AttachFileButton from '../../../../elements/files/AttachFileButton'

const acceptedUploadFileFormats = ['.pdf,.doc,.docx,.xls,.xlsx']

const styles = {
  textAreaWrap: {
    marginBottom: '15px',
  },
  editWrap: {
    marginBottom: '80px',
  },
  change: {
    fontSize: '14px',
    cursor: 'pointer'
  },
  fileAreaWrap: {
    marginBottom: '15px',
    display: 'flex',
  },
  buttonsWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  pointsAreaWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '250px',
    marginBottom: '50px'
  },
  fileUpload: {
    flex: 1
  },
  attachedFiles: {
    marginTop: '15px',
  }
}

const CaseSegmentQuestion = props => {
  let {
    classes,
    item: {
      id,
      content,
      answer,
      mark,
      balls,
      applicantAttaches,
      expertAttaches,
      number,
      symbols,
      answerSymbols,
      markSymbols
    },
    dataProvider,
    dispatch,
    status
  } = props

  const [showModal, setShowModal] = useState<boolean>(false)


  const onSuccess = {
    onSuccess: {
      notification: { body: 'Сохранено', level: 'info' },
    },
    onError: {
      notification: { body: 'Произошла ошибка', level: 'warning' },
    }
  }

  const role = Cookies.get('role')
  // форматируем комментарии по изменениям
  const formatValue = (value) => {
    if (role === 'expertReviewer') {
      let text = value
      let node = document.createElement('div')
      node.innerHTML = text
      let list = node.querySelectorAll('[data-username]')
      list.forEach((item) => {
        const username = item.getAttribute('data-changedata')
        if (username.length > 0) {
          item.setAttribute('data-changedata', '')
          item.setAttribute('data-username', username)
        }
      })
      return node.innerHTML
    }

    return value
  }

  const saveQuestion = () => {
    content = formatValue(content)
    dataProvider(UPDATE, 'segments', {
      id,
      url: 'case-questions',
      data: {
        content,
        mark,
        answer,
        balls
      }
    }, onSuccess)
  }

  const deleteQuestion = async () => {
    await dataProvider(DELETE, 'segments', {
      id,
      url: 'case-questions',
    }, { onSuccess, refresh: true })

    dispatch({ type: 'deleteQuestion', id })
  }

  return (
    <>
      <div
        className={classes.editWrap}
      >
        <CKEditorField
          classes={classes.textAreaWrap}
          label={`Подвопрос N ${number}`}
          initialValue={content}
          cb={content => dispatch({ type: 'editQuestion', id, content })}
          status={status}
          id={id}
          idType="caseQuestionId"
          entityForCase="null"
          save={saveQuestion}
          deleteEditor={() => setShowModal(true)}
          labelSave={`Сохранить подвопрос №${number}`}
          labelDelete={`Удалить подвопрос №${number}`}
          symbols={symbols}
        />
      </div>

      <div
        className={classes.editWrap}
      >
        <CKEditorField
          classes={classes.textAreaWrap}
          label="Ответ"
          initialValue={answer}
          cb={answer => dispatch({ type: 'editQuestion', id, answer })}
          isSmaller
          status={status}
          id={id}
          idType="caseQuestionId"
          entityForCase="answer"
          save={saveQuestion}
          deleteEditor={() => setShowModal(true)}
          labelSave="Сохранить ответ"
          labelDelete="Удалить ответ"
          symbols={answerSymbols}
        />
      </div>
      <div
        className={classes.editWrap}
      >
        <CKEditorField
          classes={classes.textAreaWrap}
          label="Маркировка"
          initialValue={mark}
          cb={mark => dispatch({ type: 'editQuestion', id, mark })}
          isSmaller
          status={status}
          id={id}
          idType="caseQuestionId"
          entityForCase="mark"
          save={saveQuestion}
          deleteEditor={() => setShowModal(true)}
          labelSave="Сохранить маркировку"
          labelDelete="Удалить маркировку"
          symbols={markSymbols}
        />
      </div>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Вы действительно хотите удалить?</DialogTitle>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={() => setShowModal(false)}
            color="primary"
          >
            Нет
          </Button>
          <Button
            className={classes.button}
            onClick={deleteQuestion}
            color="primary"
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.pointsAreaWrap}>
        <TextField
          className={classes.field}
          label="Количество баллов"
          value={balls}
          onChange={e => dispatch({ type: 'editQuestion', id, balls: e.target.value })}
        />
        <IconButton
          label="Сохранить"
          onClick={saveQuestion}
        />
      </div>
      <div className={classes.fileAreaWrap}>
        <div className={classes.fileUpload}>
          <AttachFileButton
            label="Прикрепить файл для эксперта"
            id={id}
            idSuffix="1"
            accept={acceptedUploadFileFormats}
            fileType="expert"
            dispatch={dispatch}
            dataProvider={dataProvider}
          />
          <div className={classes.attachedFiles}>
            {expertAttaches && expertAttaches.map(attach => (
              <FileInfo
                key={attach.id}
                title={attach.name}
                source={attach.filename}
                size={attach.size}
                attachId={attach.id}
                fileUrl={attach.url}
                fileType="expert"
                id={id}
                dataProvider={dataProvider}
                dispatch={dispatch}
              />
            ))}
          </div>
        </div>
        <div className={classes.fileUpload}>
          <AttachFileButton
            label="Прикрепить файл для претендента"
            id={id}
            idSuffix="2"
            accept={acceptedUploadFileFormats}
            fileType="applicant"
            dispatch={dispatch}
            dataProvider={dataProvider}
          />
          <div className={classes.attachedFiles}>
            {applicantAttaches && applicantAttaches.map(attach => (
              <FileInfo
                key={attach.id}
                title={attach.name}
                source={attach.filename}
                size={attach.size}
                attachId={attach.id}
                fileUrl={attach.url}
                fileType="applicant"
                id={id}
                dataProvider={dataProvider}
                dispatch={dispatch}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default withStyles(styles)(CaseSegmentQuestion)
