import moment from 'moment'
import 'moment/locale/ru'

import sanitizeHtml from 'sanitize-html'

export const rolesLocal = {
  admin: 'Администратор',
  managerMo: 'Менеджер методического отдела',
  directorMo: 'Начальник методического отдела',
  expertDeveloper: 'Эксперт разработчик сегмента',
  expertReviewer: 'Эксперт проверяющий сегмент',
  methodist: 'Главный методист',
  editor: 'Корректор',
  user: 'Обычный пользователь',
}

export const statusesLocal = [
  {
    id: 'active',
    name: 'Активный'
  },
  {
    id: 'inactive',
    name: 'Неактивный'
  }
]

export const getStatus = status => {
  switch (status) {
    case 'active':
      return 'Активный'
    case 'inactive':
      return 'Неактивный'
    default:
      return ''
  }
}

export const getDate = date => moment(date).format('LL')

export const getDateTime = date => moment(date).format('LLL')

export const getFIO = user => `${user.surname || ''} ${user.name || ''} ${user.middleName || ''}`.trim()

export const removeHtml = (text: number | string) => {
  if (typeof text === 'string') return text.replace(/(<([^>]+)>)/g, '')
  return text
}

export const formatHtml = (text:string) => sanitizeHtml(text, {
  allowedTags: ['span', 'i', 'b', 'p', 'strong', 's', 'em', 'table', 'tbody', 'tr', 'th', 'td', 'ol', 'ul', 'li', 'img', 'blockquote', 'br'],
  allowedAttributes: {
    img: ['alt', 'src'],
    span: ['style'],
    table: ['style', 'border', 'cellpadding', 'cellspacing'],
    td: ['rowspan', 'style', 'colspan']
  },
  nonTextTags: ['del']
})
