import React, { useEffect } from 'react'
import {
  Admin, Resource
} from 'react-admin'
import russianMessages from 'ra-language-russian'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import GroupIcon from '@material-ui/icons/Group'
import FolderIcon from '@material-ui/icons/Folder'
import ViewListIcon from '@material-ui/icons/ViewList'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import { dataProvider, authProvider } from './providers'
import Dashboard from './components/Dashboard'
import SegmentsEdit from './components/Segments/SegmentsEdit'
import SegmentsCreate from './components/Segments/SegmentsCreate'
import UsersList from './components/Users/UsersList'
import UserProfile from './components/Users/UserProfile'
import UserEdit from './components/Users/UserEdit'
import UserCreate from './components/Users/UserCreate'
import LoginPage from './components/LoginPage'
import RequestsList from './components/passwordRequests/RequestsList'
import TicketCriteriaList from './components/TicketCriteria/TicketCriteriaList'
import TicketCriteriaCreate from './components/TicketCriteria/TicketCriteriaCreate'
import TicketCriteriaEdit from './components/TicketCriteria/TicketCriteriaEdit'
import Import from './components/Import/Import'
import Layout from './layout'
import ArchiveList from './components/Archive/ArchiveList'
import SegmentsShow from './components/Segments/SegmentsShow'
import eakTheme from './utils/eakTheme'
import errorSagas from './sagas/errorSagas'
import selectedIdsSaga from './sagas/selectedIdsSaga'
import ExamTickets from './components/examTickets/ExamTickets'
import Appointment from './components/appointment/Appointment'
import ExamTicketСheck from './components/examTickets/ExamTicketСheck'
import DescriptionIcon from '@material-ui/icons/Description';
import './App.css'
import Report from "./components/Report/Report";

const messages = {
  ru: russianMessages
}

const i18nProvider = locale => messages[locale]

const App: React.FunctionComponent = () => (
  <Admin
    title="ЕАК"
    dataProvider={dataProvider}
    authProvider={authProvider}
    locale="ru"
    messages={messages}
    i18nProvider={i18nProvider}
    loginPage={LoginPage}
    appLayout={Layout}
    theme={eakTheme}
    customSagas={[errorSagas, selectedIdsSaga]}
  >
    {permissions => {
      const isAdmin = permissions.role === 'admin'
      const isMethodist = permissions.role === 'methodist'
      const isDirectorMo = permissions.role === 'directorMo'
      const isManager = permissions.role === 'managerMo'
      const isСensor = permissions.role === 'censor'
      const isExpert = permissions.role === 'expertReviewer'
      const isEditor = permissions.role === 'editor'
      const isPermitted = ['admin', 'managerMo', 'directorMo', 'methodist', 'user'].includes(permissions.role)
      return ([
        <Resource
          name="segments"
          list={!isСensor && Dashboard}
          options={{ label: 'Рабочий стол' }}
          edit={SegmentsEdit}
          create={SegmentsCreate}
          icon={ImportContactsIcon}
        />,
        <Resource
          name="users"
          list={isPermitted && UsersList}
          options={{ label: 'Список пользователей' }}
          show={UserProfile}
          edit={UserEdit}
          create={isAdmin && UserCreate}
          icon={GroupIcon}
        />,
        <Resource name="profile" />,
        <Resource name="roles" />,
        <Resource name="specializations" />,
        <Resource name="user" />,
        <Resource
          name="import"
          list={!(isСensor || isExpert || isEditor) && Import}
          options={{ label: 'Импорт сегментов' }}
          icon={ImportExportIcon}
        />,
        <Resource name="comments" />,
        <Resource
          name="archive"
          list={!(isСensor || isExpert || isEditor) && ArchiveList}
          show={SegmentsShow}
          options={{ label: 'Архивные сегменты' }}
          icon={FolderIcon}
        />,
        <Resource
          name="version"
          show={SegmentsShow}
        />,
        <Resource name="test-types" />,
        isAdmin && (
          <Resource
            name="password-requests"
            list={RequestsList}
            options={{ label: 'Восстановление доступа' }}
            icon={VpnKeyIcon}
          />
        ),
        <Resource
          name="exam-tickets"
          list={!(isEditor) && ExamTickets}
          show={ExamTicketСheck}
          options={{ label: 'Список работ на проверку' }}
          icon={ViewListIcon}
        />,
        <Resource
          name="experts"
          list={Appointment}
          options={{ label: false }}
          icon={ViewListIcon}
        />,
        <Resource name="modules" />,
        <Resource name="exam-levels" />,
        (isAdmin || isMethodist || isDirectorMo || isManager) && (
          <Resource
            name="ticket-criterias"
            list={TicketCriteriaList}
            create={TicketCriteriaCreate}
            edit={TicketCriteriaEdit}
            options={{ label: 'Генерация билетов' }}
            icon={FormatListNumberedIcon}
          />
        ),
        (isAdmin || isMethodist || isDirectorMo || isManager) && (
          <Resource
            name="reports"
            list={Report}
            options={{ label: 'Отчёты' }}
            icon={DescriptionIcon}
          />
        )
      ])
    }}
  </Admin>
)


export default App
