import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useLevelOwnership = () => {
  const [arrayLevelOwnership, setArrayLevelOwnership] = useState([])

  useEffect(() => {
    (async () => {
      const levelOwnershipList = await rolesRoute(GET_LIST, 'source-levels')
      setArrayLevelOwnership(levelOwnershipList.data)
    })()
  }, [])

  return arrayLevelOwnership
}

export default useLevelOwnership
