import React from 'react'
import DataForm from './UserDataForm'
import ImageForm from './UserImageForm'
import PasswordForm from './UserPasswordForm'

interface IProps {
  permissions?: any,
  redirect?: string,
  formType?: string,
  record?: any
}

const Form = ({ permissions, formType, ...props }: IProps) => (
  <>
    <ImageForm redirect={false} {...props} />
    <DataForm
      permissions={permissions}
      type={formType}
      {...props}
    />
    {formType === 'edit'
        && (<PasswordForm {...props} />)}
  </>
)


export default Form
