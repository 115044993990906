import React from 'react'
import SegmentHead from './SegmentHeadCompact'
import SegmentInfo from './SegmentInfoCompact'

const HeaderCompact = ({
  typeSegmentId,
  test,
  status,
  createdAt,
  updatedAt,
  createdById,
  versions,
  experts,
  symbols,
  isCopy,
  originalId,
  verifyStatus
}) => (
  <div style={{ display: 'flex' }}>
    <SegmentHead
      typeSegmentId={typeSegmentId}
      test={test}
      verifyStatus={verifyStatus}
      status={status}
      createdById={createdById}
    />
  </div>
)

export default HeaderCompact
