import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  withDataProvider,
  UPDATE,
  CREATE,
  GET_ONE
} from 'react-admin'

import { push } from 'react-router-redux'

import TextField from '@material-ui/core/TextField'
import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'

import moment from 'moment'
import SelectList from '../SelectList'
import useProgramsList from '../../../../utils/useProgramsList'
import useLevelOwnership from '../../../../utils/useLevelOwnership'
import useCompetencesList from '../../../../utils/useCompetencesList'
import useCriteriasList from '../../../../utils/useCriteriasList'
import useProgramsNumbersList from '../../../../utils/useProgramsNumbersList'
import useTopicsList from '../../../../utils/useTopicsList'
import useTestTypesList from '../../../../utils/useTestTypesList'
import useTypesList from '../../../../utils/useTypesList'
import useModulesList from '../../../../utils/useModulesList'
import useUpdateEffect from '../../../../utils/useUpdateEffect'
import Cookies from 'js-cookie'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const styles = () => createStyles({
  container: {
    width: '85%'
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '25px',
  },
  item: {
    width: '31%',
    display: 'flex',
    marginRight: '1.5%',
    marginBottom: '10px',
  },
  field: {
    width: '250px',
    flexGrow: 1
  },
  lastField: {
    width: '30%'
  },
  buttonConteiner: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%'
  },
  button: {
    marginRight: '20px'
  }
})

interface IProps extends WithStyles {
  test: any,
  create: boolean,
  dataProvider: any,
  typeSegmentId: number,
  redirect: any,
  status: any,
  verifyStatus?: any,
  createdById: number
}

interface IEditSegment {
  id?: number,
  url: string,
  data: any
}

const SegmentHead = withStyles(styles)(({
  classes,
  create = false,
  test,
  redirect,
  typeSegmentId: typeIdSegment,
  dataProvider,
  status,
  verifyStatus,
  createdById
}: IProps) => {
  const levelOwnershipList = useLevelOwnership()
  const criteriasList = useCriteriasList()
  const testTypesList = useTestTypesList()
  const typesList = useTypesList()

  const modulesList = useModulesList()
  const programsList = useProgramsList()
  const topicList = useTopicsList()
  const competencesList = useCompetencesList()
  const programsNumbersList = useProgramsNumbersList()

  const [filterProgramsList, setFilterProgramsList] = useState([])
  const [filterTopicsList, setFilterTopicsList] = useState([])
  const [filterCompetencesList, setFilterCompetencesList] = useState([])
  const [filterProgramsNumbersList, setFilterProgramsNumbersList] = useState([])

  const [moduleId, setModuleId] = useState(test.moduleId)
  const [programId, setProgramId] = useState(test.program && test.program.id)
  const [topicId, setTopicId] = useState(test.topicId)
  const [competence, setCompetence] = useState(test.competencies ? test.competencies.map(competence => competence.id) : [])

  const [testNumber, setTestNumber] = useState(test.number)
  const [typeSegmentId, setTypeSegmentId] = useState(typeIdSegment)
  const [taskTypeId, setTaskTypeId] = useState(test.taskTypeId)
  const [programNumbers, setProgramNumbers] = useState(test.programNumbers ? test.programNumbers.map(programNumber => programNumber.id) : [])
  const [criteriaId, setCriteriaId] = useState(test.criteria && test.criteria.id)
  const [sourceLevelId, setSourceLevelId] = useState(test.sourceLevel && test.sourceLevel.id)
  const [number, setNumber] = useState(test.number)
  const [source, setSource] = useState(test.source)
  const [sourceDate, setSourceDate] = useState(test.sourceDate && new Date(test.sourceDate))
  const [createdUserId, setCreatedUserId] = useState(createdById)

  const [isDisabled, toggleDisabled] = useState(true)
  const [isClickSave, toggleIsClickSave] = useState(false)

  const [showDialog, setShowDialog] = useState(false)

  const arrIdAddQuestionList = [3, 4]
  const isQuestionType = arrIdAddQuestionList.includes(typeSegmentId)

  useEffect(() => {
    if (moduleId) setFilterProgramsList(programsList.filter(program => program.moduleId === moduleId))
  }, [moduleId, programsList])

  useUpdateEffect(() => {
    if (moduleId) {
      setProgramId(0)
      setTopicId(0)
      setCompetence([0])
      setProgramNumbers([])
    }
  }, [moduleId])

  useEffect(() => {
    if (programId) {
      setFilterTopicsList(topicList.filter(topic => topic.programId === programId))
      setFilterCompetencesList(competencesList.filter(competence => competence.programId === programId))
    }
  }, [programId, topicList, competencesList])

  useUpdateEffect(() => {
    if (programId) {
      setTopicId(0)
      setProgramNumbers([])
      setCompetence([])
    }
  }, [programId])

  useEffect(() => {
    if (topicId) {
      setFilterProgramsNumbersList(programsNumbersList.filter(programNumber => programNumber.topicId === topicId))
    }
  }, [topicId, programsNumbersList])

  useUpdateEffect(() => {
    if (topicId) {
      setProgramNumbers([])
    }
  }, [topicId])

  const typeName = testTypesList.length && testTypesList.filter(types => types.id === typeSegmentId)[0].name

  const isNumber = n => /^-?[\d.]+(?:e-?\d+)?$/.test(n)

  const addTaskSegment = async segment => {
    if (typeSegmentId >= 7) {
      const {
        data: {
          case: {
            id,
            segmentId
          }
        }
      } = segment

      await Promise.all([dataProvider(CREATE, 'segments', {
        url: 'case-tasks',
        data: {
          caseId: id,
          content: 'case-tasks',
        }
      }), dataProvider(CREATE, 'segments', {
        url: 'case-questions',
        data: {
          caseId: id,
          number: 3,
          content: 'qqquest',
          mark: 'mark',
          balls: 2,
          answer: 'answer'
        }
      })])

      await dataProvider(GET_ONE, 'segments', {
        id: segmentId,
        url: 'segments'
      })

      redirect(segmentId)
    } else {
      const {
        data: {
          test: {
            id,
            segmentId
          }
        }
      } = segment

      await dataProvider(CREATE, 'segments', {
        url: 'test-questions',
        data: {
          testId: id,
          content: 'test-questions',
        }
      })

      if (isQuestionType) {
        const question = await dataProvider(CREATE, 'segments', {
          url: 'questions',
          data: {
            testId: id,
            content: 'questions',
            source: ' ',
            sourceLevelId: ' 1'
          }
        })

        const {
          data: {
            id: questionId
          }
        } = question

        await Promise.all([dataProvider(CREATE, 'segments', {
          url: 'answers',
          data: {
            questionId,
            content: 'answers ',
          }
        }), dataProvider(CREATE, 'segments', {
          url: 'answers',
          data: {
            questionId,
            content: ' answers',
          }
        })])
      } else {
        await dataProvider(CREATE, 'segments', {
          url: 'answers',
          data: {
            testId: id,
            content: 'answers ',
          }
        })
      }

      await dataProvider(GET_ONE, 'segments', {
        id: segmentId,
        url: 'segments'
      })

      redirect(segmentId)
    }
  }

  const saveSegment = async () => {
    const { id } = test
    const request: IEditSegment = {
      url: typeSegmentId >= 7 ? 'cases' : 'tests',
      data: {}
    }

    toggleIsClickSave(true)

    if (!create) request.id = id

    if (typeSegmentId >= 7) {
      request.data = {
        typeId: typeSegmentId,
        number: testNumber,
        taskTypeId,
        moduleId,
        createdById: createdUserId
      }
    } else {
      request.data = {
        typeId: typeSegmentId,
        moduleId,
        competence,
        programId,
        programNumbers,
        number,
        source,
        sourceDate: sourceDate ? moment(sourceDate).format('YYYY-MM-DD') : null,
        sourceLevelId,
        criteriaId,
        topicId,
        createdById: createdUserId
      }
    }

    const segment = await dataProvider(create ? CREATE : UPDATE, 'segments', request, {
      onSuccess: {
        notification: { body: 'Сохранено', level: 'info' },
      },
    })

    if (create) addTaskSegment(segment)

    toggleDisabled(true)
  }

  const responseSuccess = {
    onSuccess: {
      notification: { body: 'Сохранено', level: 'info' },
    },
    onFailure: {
      notification: { body: 'Error', level: 'warning' },
    },
    refresh: true
  }

  const setCheckStatus = async (segmentId, statusId, successText: boolean | string = false) => {
    await dataProvider('SET_CHECK_STATUS', 'segments', {
      segmentId,
      data: {
        statusId
      }
    }, successText ? {
      onSuccess: {
        notification: { body: successText, level: 'info' },
      },
      onFailure: {
        notification: { body: 'Error', level: 'warning' },
      },
      refresh: true
    } : responseSuccess)
  }

  const role = Cookies.get('role')

  /**
   * Проверяте какие статусы и роли, и возвращает true, если нужно показать кнопку "Взять в работе".
   */
  const canShowWorkButton = () => {
    if (role === 'expertReviewer' && status.id === 4 && (!verifyStatus || verifyStatus !== 2)) {
      return true;
    }

    if (role === 'editor' && status.id === 7 && (!verifyStatus || verifyStatus !== 2)) {
      return true
    }

    return false
  }

  /**
   * Устанавливает номер разработчика.
   * @param userId
   */
  const onSetCreateUserId = (userId) => {
    setCreatedUserId(userId)
  }

  useEffect(() => {
    setShowDialog(canShowWorkButton())
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.wrap}>
        <div className={classes.item}>
          { create ? (
            <SelectList
              formControlStyle={classes.field}
              name="Тип"
              selectedId={typeSegmentId}
              list={testTypesList}
              onChange={setTypeSegmentId}
            />
          ) : (
            <TextField
              className={classes.field}
              label="Тип"
              value={typeName}
              disabled
            />
          )}
        </div>
        <div className={classes.item}>
          {typeSegmentId <= 7 && (
            <SelectList
              formControlStyle={classes.field}
              name="Модуль"
              selectedId={moduleId}
              list={modulesList}
              onChange={setModuleId}
              disabled={isDisabled}
              error={!isDisabled && !moduleId && isClickSave}
            />
          )}
        </div>
        {typeSegmentId <= 6 && (
          <div className={classes.item}>
            <SelectList
              formControlStyle={classes.field}
              name="Раздел программы"
              selectedId={programId}
              list={filterProgramsList}
              onChange={setProgramId}
              disabled={isDisabled || !moduleId}
              noticeText={!moduleId && 'Модуль'}
              error={!isDisabled && !programId && isClickSave}
            />
          </div>
        ) }

        {typeSegmentId === 7 && (
          <>
            <div className={classes.item}>
              <SelectList
                formControlStyle={classes.field}
                name="Тип задания"
                className={classes.elements}
                selectedId={taskTypeId}
                list={typesList}
                onChange={setTaskTypeId}
                disabled={isDisabled}
                error={!isDisabled && !taskTypeId && isClickSave}
              />
            </div>
            <div className={classes.item}>
              <TextField
                className={classes.field}
                label="Порядковый номер"
                value={testNumber}
                disabled={isDisabled}
                type="number"
                onChange={e => setTestNumber(e.target.value)}
              />
            </div>
          </>
        ) }
      </div>
      <>
        {typeSegmentId <= 6 && (
          <>
            <div className={classes.wrap}>
              <div className={classes.item}>
                <SelectList
                  formControlStyle={classes.field}
                  name="Номер темы"
                  selectedId={topicId}
                  list={filterTopicsList}
                  onChange={setTopicId}
                  disabled={isDisabled || !programId}
                  noticeText={!programId && 'Раздел программы'}
                  error={(!isDisabled && !programId && isClickSave) || !topicId && isClickSave}
                />
              </div>
              <div className={classes.item}>
                <SelectList
                  formControlStyle={classes.field}
                  name="Номер компетенции"
                  multiple
                  showNameValue="number"
                  selectedId={competence}
                  list={filterCompetencesList}
                  onChange={setCompetence}
                  disabled={isDisabled || !programId}
                  noticeText={!topicId && 'Разделы программ'}
                  error={(!isDisabled && !programId && isClickSave) || !competence.length && isClickSave}
                />
              </div>
              <div className={classes.item}>
                <SelectList
                  multiple
                  formControlStyle={classes.field}
                  name="Номер вопроса программы"
                  selectedId={programNumbers}
                  showNameValue="number"
                  list={filterProgramsNumbersList}
                  onChange={setProgramNumbers}
                  disabled={isDisabled || !topicId}
                  noticeText={!topicId && 'Номер темы'}
                  error={!isDisabled && !topicId && isClickSave}
                />
              </div>

            </div>
            <div className={classes.wrap}>
              <div
                className={classes.item}
              >
                <SelectList
                  formControlStyle={classes.field}
                  name="Тест на"
                  selectedId={criteriaId}
                  list={criteriasList}
                  onChange={setCriteriaId}
                  disabled={isDisabled}
                  error={!isDisabled && !criteriaId && isClickSave}
                />
              </div>
              <div className={classes.item}>
                <TextField
                  className={classes.field}
                  label="Порядковый номер теста"
                  value={number}
                  disabled={isDisabled}
                  onChange={e => setNumber(e.target.value)}
                  error={!isDisabled && !isNumber(number) && isClickSave}
                  helperText={!isDisabled && !isNumber(number) ? 'Только цифры' : ''}
                />
              </div>
              <div className={classes.item}>
                <TextField
                  className={classes.field}
                  label="Источник"
                  value={source}
                  disabled={isDisabled}
                  onChange={e => setSource(e.target.value)}
                  // если это тест соответствия или составной тест, поле "Источник" является необязательным
                  error={isQuestionType ? null : (!isDisabled && !source && isClickSave)}
                  helperText={!isDisabled && !source ? 'Заполните поле' : ''}
                />
              </div>

            </div>
            <div className={classes.wrap}>
              <div className={classes.item}>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                >
                  <DatePicker
                    className={classes.field}
                    label="Дата редакции источника"
                    value={sourceDate || null}
                    onChange={setSourceDate}
                    format="DD/MM/YYYY"
                    disabled={isDisabled}
                    // если источник указан, поле является обязательным
                    error={!isDisabled && !sourceDate && source && isClickSave}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className={classes.item}>
                <SelectList
                  formControlStyle={classes.field}
                  name="Уровень владения источником"
                  selectedId={sourceLevelId}
                  list={levelOwnershipList}
                  onChange={setSourceLevelId}
                  disabled={isDisabled}
                  // если источник указан, поле является обязательным
                  error={!isDisabled && !sourceLevelId && source && isClickSave}
                />
              </div>
            </div>
          </>
        )}
      </>
      <div className={classes.item}>
        <TextField
          className={classes.field}
          label="Номер разработчика"
          value={createdUserId}
          disabled={isDisabled}
          onChange={e => onSetCreateUserId(e.target.value)}
        />
      </div>
      <div className={classes.buttonConteiner}>
        {canShowWorkButton() && (
          <Button onClick={() => setCheckStatus(test.segmentId, 2, 'В работе')} color="primary" variant="contained" className={classes.button}>Взять в работу</Button>
        )}

        <Dialog open={showDialog} onClose={() => setShowDialog(false)} aria-labelledby="form-dialog-title">
          <DialogTitle>Начало работы</DialogTitle>
          <DialogContent>
            <p>Необходимо нажать кнопку "Взять в работу".</p>
            <Button onClick={() => setShowDialog(false)} color="secondary" variant="contained">Понятно</Button>
          </DialogContent>
        </Dialog>

        {isDisabled ? (
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => toggleDisabled(!isDisabled)}
          >
            Редактировать
          </Button>
        ) : (
          <>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => toggleDisabled(true)}
            >
                Отменить
            </Button>
            <Button
              onClick={saveSegment}
              className={classes.button}
              variant="contained"
              color="primary"
            >
                Сохранить
            </Button>
          </>
        )}
      </div>
    </div>
  )
})

const mapDispatchToProps = dispatch => ({
  redirect: id => dispatch(push(`/segments/${id}`))
})

export default connect(null, mapDispatchToProps)(withDataProvider(SegmentHead))
