import { GET_LIST, GET_ONE } from 'react-admin'
import axios, { AxiosRequestConfig } from 'axios'
import { stringify } from 'query-string'
import { IQuery } from '../query'
import Cookies from 'js-cookie'

const archivedStatusId = 2

const archiveRoute = async (type, resource, params) => {
  const options: AxiosRequestConfig = {}
  options.headers = { 'Content-Type': 'application/json' }
  let query: IQuery = {
    token: `${localStorage.getItem('token')}`,
  }

  switch (type) {
    case GET_LIST:

      if (params.pagination) {
        query = {
          ...query,
          ...params.pagination
        }
      }
      if (params.filter) {
        query = {
          ...query,
          ...params.filter
        }
      }
      query.statusId = archivedStatusId
      options.url = `${resource}?${stringify(query)}`
      break
    case GET_ONE:
      options.url = `${resource}/${params.id}?${stringify(query)}`
      break
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  const response = await axios(options).catch(error => {
    const {
      response: {
        status: statusCode
      }
    } = error

    if (statusCode === 401) {
      localStorage.removeItem('token')
      Cookies.remove('token')
      Cookies.remove('role')
      Cookies.remove('userId')
      Cookies.remove('userName')
      return Promise.resolve()
    }
  })

  const { data: dataResponse }: any = response

  const data: IRAdminData<ISegment> = {
    data: null,
  }

  if ([GET_ONE].includes(type)) {
    data.data = dataResponse
  } else {
    data.data = dataResponse.models
    data.total = dataResponse.total
  }

  return data
}

export default archiveRoute
