import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { WithStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const styles = {
  button: {
    display: 'block',
    padding: '0',
    border: '0',
    marginTop: '10px',
    marginBottom: '10px',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent'
  },
  label: {
    fontSize: '16px',
    borderBottom: '1px dashed #333'
  }
}

interface IProps extends WithStyles {
  label: string,
  commentsNumber: number,
  onClick: () => void
}

const ShowCommentsButton: React.FunctionComponent<IProps> = ({
  label, onClick, classes, commentsNumber
}) => (
  <button
    onClick={onClick}
    type="button"
    className={classes.button}
  >
    <Typography className={classes.label}>{`${label} ${commentsNumber}`}</Typography>
  </button>
)

export default withStyles(styles)(ShowCommentsButton)
