
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE
} from 'react-admin'

import { stringify } from 'query-string'
import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

import { IQuery } from '../query'

const examTickets = async (type, resource, params) => {
  const options: AxiosRequestConfig = {}
  const query: IQuery = {
    token: `${localStorage.getItem('token')}`
  }
  switch (type) {
    case GET_ONE:
      options.url = `${resource}/${params.id}?${stringify(query)}`
      break
    case CREATE:
      options.method = 'POST'
      options.url = `/expert-tickets/${params.id}/add-mark?${stringify(query)}`
      options.data = params.data
      break
    case GET_LIST: {
      Object.keys(params.filter || {}).forEach(key => {
        query[key] = params.filter[key]
      })

      if (params.pagination) {
        const { perPage, page } = params.pagination
        query['per-page'] = perPage
        query.page = page
      }

      if (params.sort && params.sort.field) {
        const prefix = params.sort.order === 'ASC' ? '' : '-'
        query.sort = `${prefix}${params.sort.field}`
      }

      options.url = `/${resource}?${stringify(query)}`
      break
    }
    case UPDATE: {
      options.headers = { 'Content-Type': 'application/json' }
      options.url = `/exam-tickets/${params.id}?${stringify(query)}`
      options.method = 'PUT'
      options.data = JSON.stringify(params.data)
      break
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  const response: any = await axios(options).catch(error => {
    const {
      response: {
        status: statusCode
      }
    } = error

    if (statusCode === 401) {
      localStorage.removeItem('token')
      Cookies.remove('token')
      Cookies.remove('role')
      Cookies.remove('userId')
      Cookies.remove('userName')
      return Promise.resolve()
    }
  })

  const { data: dataResponse } = response

  const data: IRAdminData<IExamTickets> = {
    data: null,
  }

  if ([GET_ONE, UPDATE].includes(type)) {
    data.data = dataResponse
  } else {
    data.data = dataResponse.models
    data.total = dataResponse.total
  }

  return data
}

export default examTickets
