import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import {
  GET_ONE,
  GET_LIST,
  UPDATE,
  withDataProvider
} from 'react-admin'
import { getFIO } from '../../utils/getFields'

const translateVerifyStatus = status => {
  let text

  switch (status) {
    case 'empty':
      text = 'Не проверена'
      break
    case 'verify':
      text = 'На проверке'
      break
    case 'work':
      text = 'В работе'
      break
    case 'complete':
      text = 'Проверена'
      break
    case 'cancel':
      text = 'Отменена'
      break
    default: return null
  }
  return text
}

const styles = () => createStyles({
  modalTitle: {
    marginBottom: 30
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  examDateField: {
    width: '60%'
  },
  overallRatingField: {
    width: '30%',
    marginLeft: 30
  },
  ticketNumberField: {
    width: '45%',
    marginTop: 30
  },
  statusField: {
    width: '45%',
    marginLeft: 30,
    marginTop: 30
  },
  moduleField: {
    width: '98%',
    marginTop: 30
  },
  baseLabelField: {
    width: '50%',
    marginTop: 30
  },
  baseLabelFieldMark: {
    width: '50%',
    marginTop: 30,
    paddingLeft: 15
  },
  baseLabelFieldTotalMark: {
    width: '50%',
    marginTop: 30,
    paddingRight: 15
  },
  baseLabelFieldMarkMiddle: {
    width: '50%',
    marginTop: 30,
    paddingLeft: 15
  },
  asignReviewersLabel: {
    fontSize: 18,
    marginTop: 30
  },
  censorSelect: {
    width: '100%',
    marginTop: 30
  },
  closeButton: {
    margin: '0 auto 20px 20px'
  },
  saveButton: {
    margin: ' 0 20px 20px 0'
  },
  expertWrap: {
    display: 'flex'
  }
})

const ExamTicketModal = withStyles(styles)(({
  showModal,
  setShowModal,
  workId,
  setWorkId,
  classes,
  dataProvider,
}: any) => {
  const [workData, setWorkData] = useState<any>(null)
  const [experts, setExperts] = useState<any>(null)
  const [selectedStatus, setSelectedStatus] = React.useState(0)
  const [selectedExperts, setSelectedExperts] = React.useState(0)
  const [selectedExperts1, setSelectedExperts1] = React.useState(0)
  const [selectedExperts2, setSelectedExperts2] = React.useState(0)
  const [selectedExperts3, setSelectedExperts3] = React.useState(0)
  const [selectedExperts4, setSelectedExperts4] = React.useState(0)
  const [selectedExperts5, setSelectedExperts5] = React.useState(0)
  const [selectedExperts6, setSelectedExperts6] = React.useState(0)
  const [selectedExperts7, setSelectedExperts7] = React.useState(0)
  const [selectedExperts8, setSelectedExperts8] = React.useState(0)
  const [selectedExperts9, setSelectedExperts9] = React.useState(0)

  const [verifyStatus1, setVerifyStatus1] = React.useState(0)
  const [verifyStatus2, setVerifyStatus2] = React.useState(0)
  const [verifyStatus3, setVerifyStatus3] = React.useState(0)
  const [verifyStatus4, setVerifyStatus4] = React.useState(0)
  const [verifyStatus5, setVerifyStatus5] = React.useState(0)
  const [verifyStatus6, setVerifyStatus6] = React.useState(0)
  const [verifyStatus7, setVerifyStatus7] = React.useState(0)
  const [verifyStatus8, setVerifyStatus8] = React.useState(0)
  const [verifyStatus9, setVerifyStatus9] = React.useState(0)

  const [totalMark, setTotalMark] = React.useState(null)

  const handleChange = event => {
    setSelectedExperts(event.target.value)
  }

  const handleChange1 = event => {
    setSelectedExperts1(event.target.value)
  }

  const handleChange2 = event => {
    setSelectedExperts2(event.target.value)
  }

  const handleChange3 = event => {
    setSelectedExperts3(event.target.value)
  }

  const handleChange4 = event => {
    setSelectedExperts4(event.target.value)
  }

  const handleChange5 = event => {
    setSelectedExperts5(event.target.value)
  }

  const handleChange6 = event => {
    setSelectedExperts6(event.target.value)
  }

  const handleChange7 = event => {
    setSelectedExperts7(event.target.value)
  }

  const handleChange8 = event => {
    setSelectedExperts8(event.target.value)
  }

  const handleChange9 = event => {
    setSelectedExperts9(event.target.value)
  }

  const handleSelectedStatus = event => {
    setSelectedStatus(event.target.value)
  }

  const handleSelectVerifyStatus1 = event => {
    setVerifyStatus1(event.target.value)
    updateVerifyExpert(getExpert(0).id, event.target.value)
  }

  const handleSelectVerifyStatus2 = event => {
    setVerifyStatus2(event.target.value)
    updateVerifyExpert(getExpert(1).id, event.target.value)
  }

  const handleSelectVerifyStatus3 = event => {
    setVerifyStatus3(event.target.value)
    updateVerifyExpert(getExpert(2).id, event.target.value)
  }

  const handleSelectVerifyStatus4 = event => {
    setVerifyStatus4(event.target.value)
    updateVerifyExpert(getExpert(3).id, event.target.value)
  }

  const handleSelectVerifyStatus5 = event => {
    setVerifyStatus5(event.target.value)
    updateVerifyExpert(getExpert(4).id, event.target.value)
  }

  const handleSelectVerifyStatus6 = event => {
    setVerifyStatus6(event.target.value)
    updateVerifyExpert(getExpert(5).id, event.target.value)
  }

  const handleSelectVerifyStatus7 = event => {
    setVerifyStatus7(event.target.value)
    updateVerifyExpert(getExpert(6).id, event.target.value)
  }

  const handleSelectVerifyStatus8 = event => {
    setVerifyStatus8(event.target.value)
    updateVerifyExpert(getExpert(7).id, event.target.value)
  }

  const handleSelectVerifyStatus9 = event => {
    setVerifyStatus9(event.target.value)
    updateVerifyExpert(getExpert(8).id, event.target.value)
  }

  const updateVerifyExpert = async (expertId, status) => {
    await dataProvider(UPDATE, 'exam-tickets', {
      id: workId,
      data: {
        expertId: expertId,
        expertVerifyStatus: status
      }
    })
  }

  const VERIFY_STATUSES = [
    { type: 'empty', text: 'Не проверена' },
    { type: 'verify', text: 'На проверке' },
    { type: 'work', text: 'В работе' },
    { type: 'complete', text: 'Проверена' },
    { type: 'cancel', text: 'Отменена' },
  ]

  const fetchWork = async () => {
    try {
      const response = await dataProvider(GET_ONE, 'exam-tickets', {
        id: workId,
        url: 'exam-tickets'
      })
      setWorkData(response.data)
    } catch (e) {
      throw new Error(e.message)
    }
  }

  const fetchExperts = async () => {
    try {
      const response = await dataProvider(GET_LIST, 'experts')
      setExperts(response.data)
    } catch (e) {
      throw new Error(e.message)
    }
  }

  const saveExperts = async () => {
    try {
      const expertsList = []

      if (selectedExperts1) expertsList.push({ id: selectedExperts1, position: 0 })
      if (selectedExperts2) expertsList.push({ id: selectedExperts2, position: 1 })
      if (selectedExperts3) expertsList.push({ id: selectedExperts3, position: 2 })
      if (selectedExperts4) expertsList.push({ id: selectedExperts4, position: 3 })
      if (selectedExperts5) expertsList.push({ id: selectedExperts5, position: 4 })
      if (selectedExperts6) expertsList.push({ id: selectedExperts6, position: 5 })
      if (selectedExperts7) expertsList.push({ id: selectedExperts7, position: 6 })
      if (selectedExperts8) expertsList.push({ id: selectedExperts8, position: 7 })
      if (selectedExperts9) expertsList.push({ id: selectedExperts9, position: 8 })

      await dataProvider(UPDATE, 'exam-tickets', {
        id: workId,
        data: {
          assignId: selectedExperts,
          experts: expertsList,
          verifyStatus: selectedStatus,
          totalMark: totalMark
        }
      })
      setShowModal(false)
    } catch (e) {
      throw new Error(e.message)
    }
  }

  useEffect(() => {
    fetchExperts()
    if (workId) {
      fetchWork()
    }
    return () => {
      setWorkData(null)
    }
  }, [workId])


  useEffect(() => {
    if (workData && workData.assign) {
      if (experts.length > 0) {
        setExperts([...experts, workData.assign])
        setSelectedExperts(workData.assign.id)
      }
    }
  }, [workData])

  function getExpert(position) {
    for (let i = 0; i < workData.experts.length; i++) {
      if (workData.experts[i].position == position) {
        return workData.experts[i]
      }
    }

    return null
  }

  useEffect(() => {
    if (workData && workData.experts) {
      if (experts.length > 0) {
        setExperts([...experts, ...workData.experts])

        const expert1 = getExpert(0)
        if (expert1 !== null) {
          setSelectedExperts1(expert1.id)
        }

        const expert2 = getExpert(1)
        if (expert2 !== null) {
          setSelectedExperts2(expert2.id)
        }

        const expert3 = getExpert(2)
        if (expert3 !== null) {
          setSelectedExperts3(expert3.id)
        }

        const expert4 = getExpert(3)
        if (expert4 !== null) {
          setSelectedExperts4(expert4.id)
        }

        const expert5 = getExpert(4)
        if (expert5 !== null) {
          setSelectedExperts5(expert5.id)
        }

        const expert6 = getExpert(5)
        if (expert6 !== null) {
          setSelectedExperts6(expert6.id)
        }

        const expert7 = getExpert(6)
        if (expert7 !== null) {
          setSelectedExperts7(expert7.id)
        }

        const expert8 = getExpert(7)
        if (expert8 !== null) {
          setSelectedExperts8(expert8.id)
        }

        const expert9 = getExpert(8)
        if (expert9 !== null) {
          setSelectedExperts9(expert9.id)
        }
      }
    }
  }, [workData])

  useEffect(() => {
    if (workData) {
      setSelectedStatus(workData.verifyStatus)
      setTotalMark(workData.totalMark)
    }
  }, [workData])

  useEffect(() => {
    if (workData) {
      const expert1 = getExpert(0)
      if (expert1) {
        setVerifyStatus1(expert1.status)
      }

      const expert2 = getExpert(1)
      if (expert2) {
        setVerifyStatus2(expert2.status)
      }

      const expert3 = getExpert(2)
      if (expert3) {
        setVerifyStatus3(expert3.status)
      }

      const expert4 = getExpert(3)
      if (expert4) {
        setVerifyStatus4(expert4.status)
      }

      const expert5 = getExpert(4)
      if (expert5) {
        setVerifyStatus5(expert5.status)
      }

      const expert6 = getExpert(5)
      if (expert6) {
        setVerifyStatus6(expert6.status)
      }

      const expert7 = getExpert(6)
      if (expert7) {
        setVerifyStatus7(expert7.status)
      }

      const expert8 = getExpert(7)
      if (expert8) {
        setVerifyStatus8(expert8.status)
      }

      const expert9 = getExpert(8)
      if (expert9) {
        setVerifyStatus9(expert9.status)
      }
    }
  }, [workData])

  if (!workData) return null

  const { examDate } = workData.exam
  const { number: ticketNumber } = workData.ticket
  const { name: moduleName } = workData.exam.modules[0]
  const { name: examLevelName } = workData.exam.level
  const verifyStatus = translateVerifyStatus(workData.verifyStatus)

  const closeModal = () => {
    setShowModal(false)
    setSelectedExperts(0)
    setSelectedExperts1(0)
    setSelectedExperts2(0)
    setSelectedExperts3(0)
    setSelectedExperts4(0)
    setSelectedExperts5(0)
    setSelectedExperts6(0)
    setSelectedExperts7(0)
    setSelectedExperts8(0)
    setSelectedExperts9(0)
    setWorkId(null)

    setVerifyStatus1(0)
    setVerifyStatus2(0)
    setVerifyStatus3(0)
    setVerifyStatus4(0)
    setVerifyStatus5(0)
    setVerifyStatus6(0)
    setVerifyStatus7(0)
    setVerifyStatus8(0)
    setVerifyStatus9(0)
  }

  return (
    <Dialog
      maxWidth="md"
      open={showModal}
      onClose={closeModal}
    >
      <DialogTitle className={classes.modalTitle}>Информация о экзаменационной работе</DialogTitle>
      <DialogContent>
        <div className={classes.infoContainer}>
          <TextField
            className={classes.examDateField}
            label="Дата экзамена"
            defaultValue={moment(examDate).format('DD.MM.YYYY')}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            className={classes.overallRatingField}
            label="Общая оценка"
            defaultValue={workData.mark}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            className={classes.ticketNumberField}
            label="Билет №"
            defaultValue={ticketNumber}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            className={classes.statusField}
            label="Статус"
            defaultValue={verifyStatus}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            className={classes.moduleField}
            label="Модуль"
            defaultValue={moduleName}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            className={classes.baseLabelField}
            label="Уровень"
            defaultValue={examLevelName}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            className={classes.baseLabelFieldTotalMark}
            label="Оценка комиссии"
            value={totalMark}
            onChange={e => setTotalMark(e.target.value)}
          />
        </div>
        <div>
          <Typography className={classes.asignReviewersLabel}>Назначить проверяющих</Typography>
          <FormControl className={classes.censorSelect}>
            <InputLabel htmlFor="censor">Цензор</InputLabel>
            <Select
              id="censor"
              onChange={handleChange}
              value={selectedExperts}
            >
              <MenuItem value={null}>
                Не выбран
              </MenuItem>
              {experts && experts.map(expert => (
                <MenuItem value={expert.id}>
                  {getFIO(expert)}
                </MenuItem>
              ))}
            </Select>

          </FormControl>

          {workData.exam.modules[0].id !== 7 && (
            <>
              <div className={classes.expertWrap}>
                <FormControl className={classes.censorSelect}>
                  <InputLabel htmlFor="censor">Эксперт 1</InputLabel>
                  <Select
                    id="expert"
                    onChange={handleChange1}
                    value={selectedExperts1}
                  >
                    <MenuItem value={null}>
                      Не выбран
                    </MenuItem>
                    {experts && experts.map(expert => (
                      <MenuItem value={expert.id}>
                        {getFIO(expert)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.baseLabelFieldMark}>
                  <TextField
                    label="Оценка"
                    defaultValue={getExpert(0) !== null ? getExpert(0).mark : 0}
                    disabled
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className={classes.baseLabelFieldMarkMiddle}>
                  <InputLabel htmlFor="censor">Статус проверки</InputLabel>
                  <Select
                    id="verify-status-1"
                    onChange={handleSelectVerifyStatus1}
                    value={verifyStatus1}
                  >
                    {VERIFY_STATUSES.map(status => (
                      <MenuItem value={status.type}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={classes.expertWrap}>
                <FormControl className={classes.censorSelect}>
                  <InputLabel htmlFor="censor">Эксперт 2</InputLabel>
                  <Select
                    id="expert2"
                    onChange={handleChange2}
                    value={selectedExperts2}
                  >
                    <MenuItem value={null}>
                      Не выбран
                    </MenuItem>
                    {experts && experts.map(expert => (
                      <MenuItem value={expert.id}>{getFIO(expert)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.baseLabelFieldMark}>
                  <TextField
                    defaultValue={getExpert(1) !== null ? getExpert(1).mark : 0}
                    label="Оценка"
                    InputProps={{ readOnly: true }}
                    disabled
                  />
                </div>
                <div className={classes.baseLabelFieldMarkMiddle}>
                  <InputLabel htmlFor="censor">Статус проверки</InputLabel>
                  <Select
                    id="verify-status-2"
                    onChange={handleSelectVerifyStatus2}
                    value={verifyStatus2}
                  >
                    {VERIFY_STATUSES.map(status => (
                      <MenuItem value={status.type}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </>
          )}

          {workData.exam.modules[0].id === 7 && (
            <>
              <div className={classes.expertWrap}>
                <FormControl className={classes.censorSelect}>
                  <InputLabel htmlFor="censor">Эксперт РСБУ</InputLabel>
                  <Select
                    id="expert"
                    onChange={handleChange1}
                    value={selectedExperts1}
                  >
                    <MenuItem value={null}>
                      Не выбран
                    </MenuItem>
                    {experts && experts.map(expert => (
                      <MenuItem value={expert.id}>
                        {getFIO(expert)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.baseLabelFieldMark}>
                  <TextField
                    label="Оценка"
                    defaultValue={getExpert(0) !== null ? getExpert(0).mark : 0}
                    disabled
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className={classes.baseLabelFieldMarkMiddle}>
                  <InputLabel htmlFor="censor">Статус проверки</InputLabel>
                  <Select
                    id="verify-status-1"
                    onChange={handleSelectVerifyStatus1}
                    value={verifyStatus1}
                  >
                    {VERIFY_STATUSES.map(status => (
                      <MenuItem value={status.type}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={classes.expertWrap}>
                <FormControl className={classes.censorSelect}>
                  <InputLabel htmlFor="censor">Эксперт МСФО</InputLabel>
                  <Select
                    id="expert2"
                    onChange={handleChange2}
                    value={selectedExperts2}
                  >
                    <MenuItem value={null}>
                      Не выбран
                    </MenuItem>
                    {experts && experts.map(expert => (
                      <MenuItem value={expert.id}>{getFIO(expert)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.baseLabelFieldMark}>
                  <TextField
                    defaultValue={getExpert(1) !== null ? getExpert(1).mark : 0}
                    label="Оценка"
                    InputProps={{ readOnly: true }}
                    disabled
                  />
                </div>
                <div className={classes.baseLabelFieldMarkMiddle}>
                  <InputLabel htmlFor="censor">Статус проверки</InputLabel>
                  <Select
                    id="verify-status-2"
                    onChange={handleSelectVerifyStatus2}
                    value={verifyStatus2}
                  >
                    {VERIFY_STATUSES.map(status => (
                      <MenuItem value={status.type}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={classes.expertWrap}>
                <FormControl className={classes.censorSelect}>
                  <InputLabel htmlFor="censor">Эксперт Финсектор</InputLabel>
                  <Select
                    id="expert3"
                    onChange={handleChange3}
                    value={selectedExperts3}
                  >
                    <MenuItem value={null}>
                      Не выбран
                    </MenuItem>
                    {experts && experts.map(expert => (
                      <MenuItem value={expert.id}>{getFIO(expert)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.baseLabelFieldMark}>
                  <TextField
                    defaultValue={getExpert(2) !== null ? getExpert(2).mark : 0}
                    label="Оценка"
                    InputProps={{ readOnly: true }}
                    disabled
                  />
                </div>
                <div className={classes.baseLabelFieldMarkMiddle}>
                  <InputLabel htmlFor="censor">Статус проверки</InputLabel>
                  <Select
                    id="verify-status-3"
                    onChange={handleSelectVerifyStatus3}
                    value={verifyStatus3}
                  >
                    {VERIFY_STATUSES.map(status => (
                      <MenuItem value={status.type}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div>
                <div className={classes.expertWrap}>
                  <div className={classes.censorSelect}>
                    &nbsp;
                  </div>
                  <div className={classes.baseLabelFieldMark}>
                    &nbsp;
                  </div>
                  <div className={classes.baseLabelFieldMark}>
                    <strong>Общая оценка: {workData.mark1}</strong>
                  </div>
                </div>
                <p>
                  <hr />
                </p>
              </div>
              <div className={classes.expertWrap}>
                <FormControl className={classes.censorSelect}>
                  <InputLabel htmlFor="censor">Эксперт РСБУ</InputLabel>
                  <Select
                    id="expert4"
                    onChange={handleChange4}
                    value={selectedExperts4}
                  >
                    <MenuItem value={null}>
                      Не выбран
                    </MenuItem>
                    {experts && experts.map(expert => (
                      <MenuItem value={expert.id}>{getFIO(expert)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.baseLabelFieldMark}>
                  <TextField
                    defaultValue={getExpert(3) !== null ? getExpert(3).mark : 0}
                    label="Оценка"
                    InputProps={{ readOnly: true }}
                    disabled
                  />
                </div>
                <div className={classes.baseLabelFieldMarkMiddle}>
                  <InputLabel htmlFor="censor">Статус проверки</InputLabel>
                  <Select
                    id="verify-status-4"
                    onChange={handleSelectVerifyStatus4}
                    value={verifyStatus4}
                  >
                    {VERIFY_STATUSES.map(status => (
                      <MenuItem value={status.type}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={classes.expertWrap}>
                <FormControl className={classes.censorSelect}>
                  <InputLabel htmlFor="censor">Эксперт МСФО</InputLabel>
                  <Select
                    id="expert5"
                    onChange={handleChange5}
                    value={selectedExperts5}
                  >
                    <MenuItem value={null}>
                      Не выбран
                    </MenuItem>
                    {experts && experts.map(expert => (
                      <MenuItem value={expert.id}>{getFIO(expert)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.baseLabelFieldMark}>
                  <TextField
                    defaultValue={getExpert(4) !== null ? getExpert(4).mark : 0}
                    label="Оценка"
                    InputProps={{ readOnly: true }}
                    disabled
                  />
                </div>
                <div className={classes.baseLabelFieldMarkMiddle}>
                  <InputLabel htmlFor="censor">Статус проверки</InputLabel>
                  <Select
                    id="verify-status-5"
                    onChange={handleSelectVerifyStatus5}
                    value={verifyStatus5}
                  >
                    {VERIFY_STATUSES.map(status => (
                      <MenuItem value={status.type}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={classes.expertWrap}>
                <FormControl className={classes.censorSelect}>
                  <InputLabel htmlFor="censor">Эксперт Финсектор</InputLabel>
                  <Select
                    id="expert6"
                    onChange={handleChange6}
                    value={selectedExperts6}
                  >
                    <MenuItem value={null}>
                      Не выбран
                    </MenuItem>
                    {experts && experts.map(expert => (
                      <MenuItem value={expert.id}>{getFIO(expert)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.baseLabelFieldMark}>
                  <TextField
                    defaultValue={getExpert(5) !== null ? getExpert(5).mark : 0}
                    label="Оценка"
                    InputProps={{ readOnly: true }}
                    disabled
                  />
                </div>
                <div className={classes.baseLabelFieldMarkMiddle}>
                  <InputLabel htmlFor="censor">Статус проверки</InputLabel>
                  <Select
                    id="verify-status-6"
                    onChange={handleSelectVerifyStatus6}
                    value={verifyStatus6}
                  >
                    {VERIFY_STATUSES.map(status => (
                      <MenuItem value={status.type}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div>
                <div className={classes.expertWrap}>
                  <div className={classes.censorSelect}>
                    &nbsp;
                  </div>
                  <div className={classes.baseLabelFieldMark}>
                    &nbsp;
                  </div>
                  <div className={classes.baseLabelFieldMark}>
                    <strong>Общая оценка: {workData.mark2}</strong>
                  </div>
                </div>
                <p>
                  <hr />
                </p>
              </div>
            </>
          )}
          <Typography className={classes.asignReviewersLabel}>Назначить дополнительных проверяющих</Typography>
          <div className={classes.expertWrap}>
            <Divider />
            <FormControl className={classes.censorSelect}>
              <InputLabel htmlFor="censor">Комиссия 1</InputLabel>
              <Select
                id="expert7"
                onChange={handleChange7}
                value={selectedExperts7}
              >
                <MenuItem value={null}>
                  Не выбран
                </MenuItem>
                {experts && experts.map(expert => (
                  <MenuItem value={expert.id}>
                    {getFIO(expert)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={classes.baseLabelFieldMark}>
              <TextField
                defaultValue={getExpert(6) !== null ? getExpert(6).mark : 0}
                label="Оценка"
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            </div>
            <div className={classes.baseLabelFieldMarkMiddle}>
              <InputLabel htmlFor="censor">Статус проверки</InputLabel>
              <Select
                id="verify-status-7"
                onChange={handleSelectVerifyStatus7}
                value={verifyStatus7}
              >
                {VERIFY_STATUSES.map(status => (
                  <MenuItem value={status.type}>
                    {status.text}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className={classes.expertWrap}>
            <Divider />
            <FormControl className={classes.censorSelect}>
              <InputLabel htmlFor="censor">Комиссия 2</InputLabel>
              <Select
                id="expert8"
                onChange={handleChange8}
                value={selectedExperts8}
              >
                <MenuItem value={null}>
                  Не выбран
                </MenuItem>
                {experts && experts.map(expert => (
                  <MenuItem value={expert.id}>
                    {getFIO(expert)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={classes.baseLabelFieldMark}>
              <TextField
                defaultValue={getExpert(7) !== null ? getExpert(7).mark : 0}
                label="Оценка"
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            </div>
            <div className={classes.baseLabelFieldMarkMiddle}>
              <InputLabel htmlFor="censor">Статус проверки</InputLabel>
              <Select
                id="verify-status-8"
                onChange={handleSelectVerifyStatus8}
                value={verifyStatus8}
              >
                {VERIFY_STATUSES.map(status => (
                  <MenuItem value={status.type}>
                    {status.text}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className={classes.expertWrap}>
            <Divider />
            <FormControl className={classes.censorSelect}>
              <InputLabel htmlFor="censor">Комиссия 3</InputLabel>
              <Select
                id="expert8"
                onChange={handleChange9}
                value={selectedExperts9}
              >
                <MenuItem value={null}>
                  Не выбран
                </MenuItem>
                {experts && experts.map(expert => (
                  <MenuItem value={expert.id}>
                    {getFIO(expert)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={classes.baseLabelFieldMark}>
              <TextField
                defaultValue={getExpert(8) !== null ? getExpert(8).mark : 0}
                label="Оценка"
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            </div>
            <div className={classes.baseLabelFieldMarkMiddle}>
              <InputLabel htmlFor="censor">Статус проверки</InputLabel>
              <Select
                id="verify-status-9"
                onChange={handleSelectVerifyStatus9}
                value={verifyStatus9}
              >
                {VERIFY_STATUSES.map(status => (
                  <MenuItem value={status.type}>
                    {status.text}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.closeButton}
          color="primary"
          variant="outlined"
          onClick={closeModal}
        >
          Закрыть
        </Button>
        <Button
          className={classes.saveButton}
          onClick={saveExperts}
          color="primary"
          variant="contained"
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default withDataProvider(ExamTicketModal)
