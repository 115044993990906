import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useProgramsNumbersList = () => {
  const [arrayProgramsNumbers, setArrayProgramsNumbers] = useState([])

  useEffect(() => {
    (async () => {
      const programsNumbersList = await rolesRoute(GET_LIST, 'program-numbers')
      setArrayProgramsNumbers(programsNumbersList.data)
    })()
  }, [])

  return arrayProgramsNumbers
}

export default useProgramsNumbersList
