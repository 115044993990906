import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useTypesList = () => {
  const [arrayTypes, setArrayTypes] = useState([])

  useEffect(() => {
    (async () => {
      const typesList = await rolesRoute(GET_LIST, 'case-types')
      setArrayTypes(typesList.data)
    })()
  }, [])

  return arrayTypes
}

export default useTypesList
