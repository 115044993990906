import React, { useState } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import * as Yup from 'yup'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import {
  CREATE, userLogin, Notification, showNotification
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import LockIcon from '@material-ui/icons/Lock'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { createStyles, WithStyles } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { dataProvider } from '../providers'

interface IProps extends WithStyles {
  userLogin: (user: IUser) => void,
  showNotification: (message?: string, type?: string) => void,
  formValue: {
    email: string,
    password: string
  }
}

interface IUser {
  password: string,
  email: string
}
const styles = () => createStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  card: {
    width: 300,
    height: 350,
    marginTop: '14em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  formForgot: {
    padding: '1.5em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    flexDirection: 'column',
    padding: '1em',
    paddingTop: '1.5em'
  },
  actionsForgot: {
    flexDirection: 'row',
    marginTop: '1.5em',
    paddingLeft: '0',
    paddingRight: '0'
  },
  logIn: {
    marginBottom: '7px',
    backgroundColor: '#b71c1c',
    color: 'white'
  },
  forgot: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
  sendButton: {
    width: '50%',
    backgroundColor: '#b71c1c',
    color: 'white'
  },
  cancelButton: {
    width: '50%'
  },
  inputsWrapper: {
    height: 150
  }
})

const renderInput = ({
  meta: { touched, error },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const LoginPage: React.FunctionComponent<IProps> = ({
  classes,
  userLogin: actionUserLogin,
  formValue: {
    email,
    password,
  },
  showNotification: notify
}) => {
  const [visible, toggleVisible] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    actionUserLogin({ email, password })
  }

  const handleClick = () => {
    toggleVisible(!visible)
  }

  const handleSendRequest = e => {
    e.preventDefault()
    dataProvider(CREATE, 'reset-password-request', { email }).then(() => {
      toggleVisible(!visible)
      notify('Запрос успешно отправлен')
    })
      .catch(error => {
        const message = error.response ? error.response.data[0].message : 'Произошла ошибка, попробуйте снова'
        notify(message, 'warning')
      })
  }

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        {!visible ? (
          <form onSubmit={handleSubmit}>
            <div className={classes.form}>
              <div className={classes.inputsWrapper}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="email"
                    component={renderInput}
                    label="Электронная почта"
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    component={renderInput}
                    label="Пароль"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
                    }}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="raised"
                  type="submit"
                  className={classes.logIn}
                  fullWidth
                >
                  Войти
                </Button>
                <Button
                  className={classes.forgot}
                  fullWidth
                  onClick={handleClick}
                >
                  Забыли пароль?
                </Button>
              </CardActions>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSendRequest}>
            <div className={classes.formForgot}>
              <Typography>
                Введите email, привязанный к Вашей учетной записи
              </Typography>
              <div className={classes.input}>
                <Field
                  autoFocus
                  name="email"
                  component={renderInput}
                />
              </div>
              <CardActions className={classes.actionsForgot}>
                <Button
                  variant="raised"
                  type="submit"
                  className={classes.sendButton}
                >
                  Отправить
                </Button>
                <Button
                  className={classes.cancelButton}
                  onClick={() => toggleVisible(false)}
                >
                  Отмена
                </Button>
              </CardActions>
            </div>
          </form>
        )}
      </Card>
      <Notification />
    </div>
  )
}

const schema = Yup.object().shape({
  password: Yup.string()
    .required('Обязательное поле'),
  email: Yup.string()
    .email('Некорректный адрес электронной почты')
    .required('Обязательное поле'),
})

const asyncValidate = values => new Promise((resolve, reject) => {
  schema.validate(values, { abortEarly: false })
    .then(() => {
      resolve()
    })
    .catch(errors => {
      const rErrors = {}

      errors.inner.forEach(error => {
        rErrors[error.path] = error.message
      })
      reject(rErrors)
    })
})

const selector = formValueSelector('signIn')

const enhance = compose(
  reduxForm<IUser>({
    form: 'signIn',
    asyncValidate,
  }),
  connect(
    state => {
      const { email, password }: {
        email: string,
        password: string
      } = selector(state, 'email', 'password')

      return {
        formValue: {
          email,
          password,
        },
      }
    },
    {
      userLogin,
      showNotification
    }
  ),
  withStyles(styles),
)

const EnhancedLoginPage = enhance(LoginPage)

export default EnhancedLoginPage
