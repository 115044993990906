import React from 'react'
import {
  Link, ReferenceField, ShowButton, FunctionField
} from 'react-admin'
import Card from '@material-ui/core/Card'
import { WithStyles, createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MuiListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import {
  getStatus, getDate, rolesLocal
} from '../../utils/getFields'

import { SERVER_URL } from '../../providers/constants'

import { ListItem, FullNameField } from '../../elements'
import setPhoneMask from '../../utils/setPhoneMask'

const styles = () => createStyles({
  card: {
    position: 'relative',
    display: 'flex',
    overflow: 'auto',
    padding: '10px',
    height: 'calc(100% - 20px)'
  },
  columnImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    paddingTop: '20px',
  },
  img: {
    width: '100px',
    borderRadius: '50%'
  },
  listItem: {
    display: 'block',
  },
  listItemBold: {
    display: 'block',
    '& span': {
      fontWeight: 'bold',
    }
  },
  showButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 2
  }
})

interface ISpecs {
  active: number,
  createdAt: string,
  id: number,
  name: string,
  updatedAt: string
}

interface IProps extends WithStyles {
  id: number,
  data: IProfile,
  specs: Array<ISpecs>
}

const UserPreview: React.FunctionComponent<IProps> = ({id, data, classes}) => {
  const img = data[id].image ? `${SERVER_URL}${data[id].image}` : 'img-placeholder.png'

  return (
    <Card className={classes.card}>
      <ShowButton label="" className={classes.showButton} basePath={`/users/${id}/show`} />
      <div className={classes.columnImg}>
        <img src={img} alt="" className={classes.img} />
      </div>
      <div className={classes.columnInfo}>
        <List>
          <MuiListItem>
            <Link to={`/users/${id}/show`}>
              <FullNameField record={data[id]} type="subheading" />
            </Link>
          </MuiListItem>
          <ListItem
            record={data[id]}
            label="№ разработчика:"
            source="id"
            className={classes.listItemBold}
          />
          <ListItem
            record={data[id]}
            label="Роль:"
            source="role"
            cb={name => rolesLocal[name]}
            className={classes.listItem}
          />
          {data[id].specializationId
          && (
            <ReferenceField
              record={data[id]}
              basePath="users"
              source="specializationId"
              reference="specializations"
              linkType={false}
            >
              <ListItem
                record={data[id]}
                label="Специализация:"
                source="name"
                className={classes.listItem}
              />
            </ReferenceField>
          )}
          <Typography style={{ padding: '0 24px' }}>
            Телефон:
            {' '}
            <FunctionField
              style={{ display: 'inline' }}
              record={data[id]}
              render={record => setPhoneMask(record)}
            />
          </Typography>
          <ListItem
            record={data[id]}
            label="email:"
            source="email"
            className={classes.listItem}
          />
          <ListItem
            record={data[id]}
            label="Статус:"
            source="status"
            cb={getStatus}
            className={classes.listItem}
          />
          <ListItem
            record={data[id]}
            label="Дата регистрации:"
            source="createdAt"
            cb={getDate}
            className={classes.listItem}
          />
          <ListItem
            record={data[id]}
            label="Дата последней активности:"
            source="updatedAt"
            cb={getDate}
            className={classes.listItem}
          />
        </List>
      </div>
    </Card>
  )
}

export default withStyles(styles)(UserPreview)
