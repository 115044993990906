import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider';
import useRolesList from '../../utils/useRolesList'
import useModulesList from '../../utils/useModulesList'

import {
  GET_ONE,
  GET_LIST,
  CREATE,
  UPDATE,
  withDataProvider
} from 'react-admin'

const translateVerifyStatus = status => {
  let text

  switch (status) {
    case 'empty':
      text = 'Не проверена'
      break
    case 'verify':
      text = 'На проверке'
      break
    case 'work':
      text = 'В работе'
      break
    case 'complete':
      text = 'Проверена'
      break
    case 'cancel':
      text = 'Отменена'
      break
    default: return null
  }
  return text
}

const styles = () => createStyles({
  modalTitle: {
    marginBottom: 30
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  examDateField: {
    width: '60%'
  },
  overallRatingField: {
    width: '30%',
    marginLeft: 30
  },
  ticketNumberField: {
    width: '45%',
    marginTop: 30
  },
  statusField: {
    width: '45%',
    marginLeft: 30,
    marginTop: 30
  },
  moduleField: {
    width: '98%',
    marginTop: 30
  },
  baseLabelField: {
    width: '50%',
    marginTop: 30
  },
  baseLabelFieldMark: {
    width: '50%',
    marginTop: 30,
    paddingLeft: 15
  },
  asignReviewersLabel: {
    fontSize: 18,
    marginTop: 30
  },
  censorSelect: {
    width: '100%',
    marginTop: 30
  },
  closeButton: {
    margin: '0 auto 20px 20px'
  },
  saveButton: {
    margin: ' 0 20px 20px 0'
  },
  expertWrap: {
    display: 'flex'
  }
})

const AppointmentModal = withStyles(styles)(({
  showModal,
  setShowModal,
  classes,
  dataProvider,
  currentUserId
}: any) => {
  const [userInfo, setUserInfo] = useState<any>(null)
  const roles = useRolesList()
  const modules = useModulesList()

  const fetchWork = async () => {
    try {
      const response = await dataProvider(GET_ONE, 'users', {
        id: currentUserId,
      })
      setUserInfo(response.data)
    } catch (e) {
      throw new Error(e.message)
    }
  }

  useEffect(() => {
    if (currentUserId) {
      fetchWork()
    }
  }, [currentUserId])

  if (!userInfo) return null

  const closeModal = () => {
    setShowModal(false)
  }

  const currentRole = roles.filter(role => role.name === userInfo.role)[0]
  const currentSpecalization = modules.filter(module => module.id === userInfo.specializationId)[0]

  console.log(currentRole, 'currentRole')
  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
    >
      <DialogTitle >Информация о пользователе</DialogTitle>
      <DialogContent style={{
        width: '500px'
      }}>
        <div
          style={{
            marginBottom: '15px'
          }}
        >
          <TextField
            style={{
              width: '280px'
            }}
            label="ФИО"
            defaultValue={`${userInfo.name} ${userInfo.middleName} ${userInfo.surname}`}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div
          style={{
            marginBottom: '15px'
          }}
        >
          <TextField
            style={{
              width: '250px',
            }}
            label="Электронный адрес"
            defaultValue={`${userInfo.email}`}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            style={{
              width: '200px',
              marginLeft: '15px'
            }}
            label="Телефон"
            defaultValue={`${userInfo.phone}`}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div
          style={{
            marginBottom: '15px'
          }}
        >
          <TextField
            style={{
              width: '250px',
            }}
            label="Роль"
            defaultValue={`${currentRole.description}`}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            style={{
              width: '200px',
              marginLeft: '15px'
            }}
            label="Область"
            defaultValue={`${currentSpecalization.name}`}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.closeButton}
          color="primary"
          variant="outlined"
          onClick={closeModal}
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog >
  )
})

export default withDataProvider(AppointmentModal)
