import {
  GET_LIST, DELETE_MANY, DELETE, CREATE
} from 'react-admin'

import axios, { AxiosRequestConfig } from 'axios'
import { stringify } from 'query-string'

const passwordRequests = async (type, resource, params) => {
  const options: AxiosRequestConfig = {}
  options.headers = { 'Content-Type': 'application/json' }
  const query = {
    token: `${localStorage.getItem('token')}`,
  }

  switch (type) {
    case GET_LIST:
      options.url = `/${resource}?${stringify(query)}`
      break

    case CREATE:
      options.method = 'POST'
      options.url = `/${resource}/${params.id}/change?${stringify(query)}`
      options.data = JSON.stringify(params.data)
      break
    case DELETE:
      options.method = 'POST'
      options.url = `/${resource}/${params.id}/cancel?${stringify(query)}`
      break
    case DELETE_MANY:
      options.url = `/${resource}/${params.ids}/cancel?${stringify(query)}`
      options.method = 'POST'
      break
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }
  const response = await axios(options)

  const { data: dataResponse } = response
  const data = {
    data: null,
    total: null
  }

  if (CREATE === type) {
    data.data = dataResponse
  } else {
    data.data = dataResponse.models || []
    data.total = dataResponse.total
  }

  return data
}

export default passwordRequests
