import React, {
  useState,
  useEffect,
  useReducer,
} from 'react'

import {
  Toolbar,
  UPDATE,
  CREATE,
  GET_LIST,
  SimpleForm,
  withDataProvider,
} from 'react-admin'

import Cookies from 'js-cookie'
import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import SegmentHeader from '../header'
import ToolbarProvider from '../../toolbarProvider/SegmentToolbarProvider'
import CaseSegmentQuestion from './CaseSegmentQuestion'
import CKEditorField from '../../../../elements/CKEditorField'
import SegmentWatch from '../../SegmentWatch'
import SegmentCopy from '../../SegmentCopy'

import { reducerCase } from '../../../../state/reducer'

const styles = () => createStyles({
  wrap: {
    display: 'flex',
    width: '700px',
    marginBottom: '20px',
  },
  wrapButton: {
    display: 'flex',
    width: 'auto',
    marginBottom: '25px',
    justifyContent: 'space-between'
  },
  elements: {
    marginRight: '25px',
  },
  buttonWrap: {
    width: '596px',
    textAlign: 'right',
  },
  textAreaWrap: {
    marginBottom: '100px',
  },
  previewWrap: {
    marginTop: '10px',
    marginBottom: '25px',
  },
  previewButtonWrap: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '15px'
  },
  taskWrapper: {
    width: '100%'
  },
  headerWrap: {
    width: '100%',
    marginBottom: '80px'
  },
  saveButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    marginTop: '0px',
    marginBottom: '0px'
  },
  rightBlock: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

interface IProps extends WithStyles {
  test: any,
  type: any,
  experts: any,
  typeSegmentId: number,
  dataProvider: any,
  status: any,
  createdAt: string,
  updatedAt: string,
  permissions: any,
  verifyStatus?: any,
  versions: any,
  createdById: number,
  symbols: number,
  isCopy: number,
  originalId?: number
}

const CaseSegment = withStyles(styles)(({ ...props }: IProps) => {
  const {
    classes,
    test,
    experts,
    typeSegmentId,
    dataProvider,
    status,
    createdAt,
    updatedAt,
    permissions,
    verifyStatus,
    versions,
    createdById,
    symbols,
    isCopy,
    originalId
  } = props

  const [users, setUsers] = useState({
    experts: {},
    editors: {},
    nmo: {},
    mainNmo: {}
  })
  const {
    task: {
      content: task
    },
    questions,
  } = test

  const [state, dispatch] = useReducer(reducerCase, {
    task,
    questions
  })

  const responseSuccess = {
    onSuccess: {
      notification: { body: 'Сохранено', level: 'info' },
    },
    onFailure: {
      notification: { body: 'Error', level: 'warning' },
    },
    refresh: true
  }

  const editTask = () => {
    const {
      task: { id }
    } = test

    dataProvider(UPDATE, 'segments', {
      id,
      url: 'case-tasks',
      data: {
        content: state.task
      }
    }, responseSuccess)
  }

  const addQuestion = async () => {
    const {
      id
    } = test

    const response = await dataProvider(CREATE, 'segments', {
      id,
      url: 'case-questions',
      data: {
        caseId: id,
        number: 3,
        content: 'Подвопрос',
        mark: 'Маркировка',
        balls: 2,
        answer: 'Ответ на подвопрос'
      }
    }, { responseSuccess })

    const { data } = response
    dispatch({ type: 'addQuestion', ...data })
  }

  useEffect(() => {
    (async () => {
      const expertReviewers = await dataProvider(GET_LIST, 'users',
        { filter: { specializationId: test.moduleId, role: 'expertReviewer', status: 'active' } })
      const editors = await dataProvider(GET_LIST, 'users',
        { filter: { role: 'editor', status: 'active' } })
      const nmo = await dataProvider(GET_LIST, 'users',
        { filter: { role: 'directorMo', status: 'active' } })
      const mainNmo = await dataProvider(GET_LIST, 'users',
        { filter: { role: 'methodist', status: 'active' } })

      setUsers({
        ...users,
        experts: expertReviewers.data,
        editors: editors.data,
        nmo: nmo.data,
        mainNmo: mainNmo.data
      })
    })()
  }, [])

  return (
    <SimpleForm
      save={editTask}
      toolbar={(
        <Toolbar>
          <ToolbarProvider
            segmentId={test.segmentId}
            verifyStatus={verifyStatus}
            dataProvider={dataProvider}
            experts={experts}
            permissions={permissions}
            status={status}
            users={users}
          />
        </Toolbar>
      )}
    >
      <div className={classes.wrapButton}>
        <h2 className={classes.title}>Сегмент {test.segmentId}</h2>
        <div className={classes.rightBlock}>
          <SegmentWatch
            type="case"
            segment={state}
          />
          <SegmentCopy
            segmentId={test.segmentId}
          />
        </div>
      </div>
      <div className={classes.headerWrap}>
        <SegmentHeader
          typeSegmentId={typeSegmentId}
          test={test}
          status={status}
          createdAt={createdAt}
          updatedAt={updatedAt}
          versions={versions}
          experts={experts}
          createdById={createdById}
          symbols={symbols}
          isCopy={isCopy}
          originalId={originalId}
          verifyStatus={verifyStatus}
        />
      </div>
      <div className={classes.taskWrapper}>
        <CKEditorField
          label="Задание"
          classes={classes.textAreaWrap}
          initialValue={state.task}
          cb={task => dispatch({ type: 'setTask', task })}
          status={status.name}
          id={test.task.id}
          idType="caseTaskId"
          save={editTask}
          isDelete={false}
          labelSave="Сохранить задание"
          symbols={test.task.symbols}
        />
      </div>
      {state.questions.map(item => (
        <CaseSegmentQuestion
          key={item.id}
          dataProvider={dataProvider}
          item={item}
          segmentId={test.segmentId}
          dispatch={dispatch}
          status={status.name}
        />
      ))}
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={addQuestion}
      >
        Добавить Подвопрос
      </Button>
    </SimpleForm>
  )
})

export default withDataProvider(CaseSegment)
