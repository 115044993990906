import React, { useState, useEffect } from 'react'
import { GET_MANY, DELETE, withDataProvider } from 'react-admin'
import CommentField from './CommentField'
import ShowCommentsButton from './ShowCommentsButton'
import AddComment from "./AddComment";

interface Data {
  id: number,
  idType: string,
  entity?: string,
  originalComment?: boolean
}

const Comments = ({
                    id, idType, entityForCase, dataProvider, showAddBlock, originalComment
                  }) => {
  const [showComments, toggleShowComments] = useState(false)
  const [comments, setComments] = useState([])

  const showButtonLabel = showComments ? 'Свернуть' : 'Комментарии'

  const deleteComment = commentId => {
    dataProvider(DELETE, 'comments', { commentId }, {
      onSuccess: {
        notification: { body: 'Комментарий удален', level: 'info' }
      }
    })
    setComments(comments.filter(item => item.id !== commentId))
  }

  useEffect(() => {
    (async () => {
      let data: Data = { id, idType, originalComment}

      if (entityForCase) {
        data = { ...data, entity: entityForCase}
      }
      const response = await dataProvider(GET_MANY, 'comments', data)
      setComments(response.data)
    })()
  }, [])

  const styles = {
    backgroundColor: '#f5f5f5',
    padding: '5px 15px 15px'
  }

  return (
    <section style={styles}>
      <ShowCommentsButton
        onClick={() => toggleShowComments(!showComments)}
        label={showButtonLabel}
        commentsNumber={showComments ? '' : `(${comments.length})`}
      />

      {showComments && (
        <CommentField
          id={id}
          idType={idType}
          entityForCase={entityForCase}
          comments={comments}
          setComments={setComments}
          deleteComment={deleteComment}
        />
      )}

      <AddComment setCommentsList={setComments} id={id} idType={idType} entityForCase={entityForCase} />
    </section>
  )
}

export default withDataProvider(Comments)
