import { put, takeEvery } from 'redux-saga/effects'
import { CRUD_GET_ONE_SUCCESS, setListSelectedIds } from 'react-admin'
import { LOCATION_CHANGE } from 'react-router-redux'

function* selectedIdsSaga(action) {
  if (action.meta.resource === 'ticket-criterias') {
    const selectedIds = action.payload.data.segments
    yield put(setListSelectedIds('segments', selectedIds))
  }
}

function* resetSelectedIds(action) {
  if (action.payload.pathname === '/segments') {
    yield put(setListSelectedIds('segments', []))
  }
}

export default function* selectIds() {
  yield takeEvery(CRUD_GET_ONE_SUCCESS, selectedIdsSaga)
  yield takeEvery(LOCATION_CHANGE, resetSelectedIds)
}
