import React from 'react'
import {
  SimpleForm,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  FormDataConsumer,
  NumberInput,
  DateInput,
  minValue,
  required,
  REDUX_FORM_NAME
} from 'react-admin'
import { change } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import useTestTypesList from '../../utils/useTestTypesList'
import useModulesList from '../../utils/useModulesList'
import useProgramsList from '../../utils/useProgramsList'
import useTopicsList from '../../utils/useTopicsList'
import useCompetencesList from '../../utils/useCompetencesList'
import useProgramsNumbersList from '../../utils/useProgramsNumbersList'
import useCriteriasList from '../../utils/useCriteriasList'
import useLevelOwnership from '../../utils/useLevelOwnership'
import CaseSegmentsList from './CaseSegmentsList'

const BASIC_LEVEL_ID = 1
const MAIN_LEVEL_ID = 2

const styles = {
  moduleName: {
    marginRight: 10
  },
  totalCount: {
    width: 191,
    marginRight: 10
  },
  grid: {
    margin: 0,
    marginBottom: 20
  },
  innerGrid: {
    marginLeft: 0,
    marginTop: 10
  },
  levelId: {
    minWidth: '100%'
  },
  warning: {
    display: 'flex'
  },
  warningText: {
    marginLeft: 10
  },
  countWarning: {
    color: '#b71c1c'
  }
}

const TicketCriteriaForm = ({ classes, formType, RAprops, ...props }) => {
  const examLevel = props.record.module && props.record.module.levelId
  const testTypes = useTestTypesList()
  const modulesList = useModulesList()
  const programsList = useProgramsList()
  const topicsList = useTopicsList()
  const competencesList = useCompetencesList()
  const programsNumbersList = useProgramsNumbersList()
  const criteriaList = useCriteriasList()
  const sourceKnowledgeLevels = useLevelOwnership()

  const isEditForm = formType === 'edit'
  const isCreateForm = formType === 'create'

  return (
    isEditForm && examLevel === MAIN_LEVEL_ID ? (
      <CaseSegmentsList RAprops={RAprops} record={props.record} />
    ) : (
        <SimpleForm {...props}>
          <FormDataConsumer>
            {({ formData }) => {
              const { moduleId, cacheCount, count } = formData
              const programs = programsList.filter(program => program.moduleId === moduleId)

              const validateQuestionsNumber = (value, allValues) => {
                const { filters, count: totalQ } = allValues
                const sum = filters ? filters.reduce((acc, { count }) => (acc + count), 0) : 0
                if (value > totalQ || sum > totalQ) {
                  return 'Превышено общее кол-во вопросов'
                }

                return ''
              }
              // относится ли модуль к базовому уровню
              const isBasicLevel = modulesList.some(item => item.id === formData.moduleId && item.levelId === BASIC_LEVEL_ID)

              return (
                <>
                  <SelectInput
                    label="Модуль"
                    source="moduleId"
                    optionText="name"
                    optionValue="id"
                    choices={modulesList}
                    validate={required()}
                    className={classes.moduleName}
                    disabled={isEditForm}
                  />
                  {isBasicLevel && (
                    <>
                      <NumberInput
                        source="count"
                        label="Общее кол-во вопросов"
                        inputProps={{ min: 0 }}
                        validate={minValue(1)}
                        className={classes.totalCount}
                      />
                      <TextInput
                        source="cacheCount"
                        label="Кол-во сегментов по модулю в базе"
                        disabled
                      />
                    </>
                  )}
                  {count > cacheCount && isBasicLevel && (
                    <Typography className={classes.countWarning}>
                      В базе недостаточно сегментов по этому модулю. Билеты не будут сгенерированы.
                    </Typography>
                  )}
                  {isEditForm && (
                    <ArrayInput source="filters" label="Фильтры">
                      <SimpleFormIterator>
                        <FormDataConsumer>
                          {({
                            scopedFormData, getSource, dispatch
                          }) => {
                            const topics = scopedFormData ? topicsList.filter(topic => topic.programId === scopedFormData.programId) : []
                            const competences = scopedFormData ? competencesList.filter(competence => competence.programId === scopedFormData.programId) : []
                            const programNumbers = scopedFormData ? programsNumbersList.filter(programNumber => programNumber.topicId === scopedFormData.topicId) : []

                            const onChange = e => {
                              const sourceValue = e.target.value
                              if (!sourceValue && (scopedFormData.sourceDate || scopedFormData.sourceLevelId)) {
                                dispatch(change(REDUX_FORM_NAME, getSource('sourceDate'), ''))
                                dispatch(change(REDUX_FORM_NAME, getSource('sourceLevelId'), ''))
                              }
                            }

                            const validateCount = [minValue(1), required(), validateQuestionsNumber]

                            return scopedFormData ? (
                              <Grid container spacing={16} className={classes.grid}>
                                <Grid container spacing={16} className={classes.innerGrid}>
                                  <Grid item xs={3}>
                                    <NumberInput
                                      source={getSource('count')}
                                      label="Кол-во вопросов"
                                      inputProps={{ min: 0 }}
                                      InputLabelProps={{ shrink: true }}
                                      validate={validateCount}
                                      fullWidth
                                    />
                                  </Grid>
                                  {scopedFormData.cacheCount >= 0 && (
                                    <Grid item xs={3}>
                                      <TextInput
                                        source={getSource('cacheCount')}
                                        label="Кол-во сегментов в базе"
                                        disabled
                                        fullWidth
                                      />
                                    </Grid>
                                  )}
                                  {scopedFormData.cacheCount < scopedFormData.count && (
                                    <Grid item xs={5} className={classes.warning}>
                                      <SentimentVeryDissatisfiedIcon color="primary" />
                                      <Typography className={classes.warningText}>
                                        В базе недостаточно сегментов, соотвествующих этому критерию. Недостающие сегменты будут подобраны автоматически.
                                  </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item xs={6}>
                                  <SelectInput
                                    label="Тип сегмента"
                                    source={getSource('typeId')}
                                    optionText="name"
                                    optionValue="id"
                                    choices={testTypes}
                                    resettable
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <SelectInput
                                    label="Тест на"
                                    source={getSource('criteriaId')}
                                    optionText="name"
                                    optionValue="id"
                                    choices={criteriaList}
                                    resettable
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <SelectInput
                                    label="Раздел программы"
                                    source={getSource('programId')}
                                    optionText="name"
                                    optionValue="id"
                                    choices={programs}
                                    resettable
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <SelectInput
                                    label="Номер темы"
                                    source={getSource('topicId')}
                                    optionText="name"
                                    optionValue="id"
                                    choices={topics}
                                    resettable
                                    fullWidth
                                    disabled={!scopedFormData.programId}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <SelectInput
                                    label="Номер компетенции"
                                    source={getSource('competenceId')}
                                    optionText="number"
                                    optionValue="id"
                                    choices={competences}
                                    resettable
                                    fullWidth
                                    disabled={!scopedFormData.programId}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <SelectInput
                                    label="Номер вопроса программы"
                                    source={getSource('programNumberId')}
                                    optionText="number"
                                    optionValue="id"
                                    choices={programNumbers}
                                    resettable
                                    fullWidth
                                    disabled={!scopedFormData.topicId}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextInput
                                    label="Источник"
                                    source={getSource('source')}
                                    onChange={onChange}
                                    multiline
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <DateInput
                                    label="Дата редакции источника"
                                    source={getSource('sourceDate')}
                                    disabled={!scopedFormData.source}
                                    validate={scopedFormData.source ? required() : null}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <SelectInput
                                    label="Уровень владения источником"
                                    source={getSource('sourceLevelId')}
                                    choices={sourceKnowledgeLevels}
                                    optionText="name"
                                    optionValue="id"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!scopedFormData.source && !scopedFormData.sourceDate}
                                    fullWidth
                                    className={classes.levelId}
                                  />
                                </Grid>
                              </Grid>
                            ) : null
                          }}
                        </FormDataConsumer>
                      </SimpleFormIterator>
                    </ArrayInput>
                  )}
                </>
              )
            }}
          </FormDataConsumer>
        </SimpleForm>
      )
  )
}

export default withStyles(styles)(TicketCriteriaForm)
