import React from 'react'
import {
  Show, SimpleShowLayout, EmailField, EditButton, FunctionField
} from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import { createStyles, WithStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'

import { ListItem, FullNameField, NotPermitted } from '../../elements'
import {getStatus, getDate, rolesLocal, getFIO} from '../../utils/getFields'
import { SERVER_URL } from '../../providers/constants'
import setPhoneMask from '../../utils/setPhoneMask'

const styles = () => createStyles({
  layout: {
    display: 'flex',
    padding: '35px',
    '&:last-child': {
      paddingTop: '35px',
      paddingBottom: '35px',
    },
  },
  imgColumn: {
    width: '243px',
    marginRight: '50px',
  },
  img: {
    display: 'block',
    width: '100%',
    marginBottom: '35px',
    borderRadius: '50%'
  },
  cardContent: {
    padding: 0,
    paddingTop: '24px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingTop: 0,
    paddingBottom: '10px',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '35px',
  },
  formGroup: {
    display: 'flex',
    marginBottom: '15px',
  },
  editButton: {
    minWidth: '40px',
    marginLeft: '15px',
  },
  spec: {
    marginBottom: '15px',
  },
  contacts: {
    marginBottom: '15px',
  },
  email: {
    color: 'inherit',
  },
})

interface IProps extends WithStyles {
  record: IProfile
}

const UserProfile = withStyles(styles)(({ classes, record }: IProps) => {
  const img = record.image ? `${SERVER_URL}${record.image}` : 'img-placeholder.png'

  return (
    <SimpleShowLayout className={classes.layout}>
      <div className={classes.imgColumn}>
        <img src={img} alt="" className={classes.img} />
        <Card>
          <CardContent className={classes.cardContent}>
            <ListItem
              record={record}
              label="Статус:"
              source="status"
              cb={getStatus}
              className={classes.listItem}
            />
            <ListItem
              record={record}
              label="Дата регистрации:"
              source="createdAt"
              cb={getDate}
              className={classes.listItem}
            />
            <ListItem
              record={record}
              label="Последняя активность:"
              source="updatedAt"
              cb={getDate}
              className={classes.listItem}
            />
          </CardContent>
        </Card>
      </div>
      <div className={classes.infoColumn}>
        <div className={classes.name}>
          <FullNameField record={record} type="title" />
        </div>
        <div className={classes.formGroup}>
          № разработчика:&nbsp;
          <FunctionField record={record} render={value => value.id} />
        </div>
        <div className={classes.formGroup}>
          Роль:&nbsp;
          <FunctionField record={record} render={rec => rolesLocal[rec.role]} />
        </div>
        <div className={classes.formGroup}>
          Специализации:&nbsp;
          <FunctionField record={record} render={value => value.specializations.map(spec => spec.name).join(', ')} />
        </div>
        <Typography
          variant="title"
          component="span"
          className={classes.contacts}
        >
          Контакты:
        </Typography>
        <FunctionField record={record} render={value => setPhoneMask(value)} />
        <Typography>
          <EmailField
            record={record}
            source="email"
            className={classes.email}
          />
        </Typography>
      </div>
    </SimpleShowLayout>
  )
})

const ProfileShowActions = ({basePath, data, permissions, id}: { basePath?: string, data?: any, permissions?: any, id?: number }) => (
  <>
    {permissions && (permissions.userId === id || permissions.role === 'admin')
      ? (
        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <EditButton basePath={basePath} record={data} />
        </CardActions>

      ) : (
        <></>
      )}
  </>
)


const UserProfileShow = ({ permissions, id, ...props }) => {
  const isPermitted = permissions ? ['admin', 'managerMo', 'directorMo', 'methodist', 'user'].includes(permissions.role) : undefined
  return (
    (permissions && permissions.userId === id) || isPermitted ? (
      <Show
        title=" "
        id={id}
        actions={<ProfileShowActions permissions={permissions} id={id} />}
        {...props}
      >
        <UserProfile />
      </Show>
    ) : (
      <NotPermitted />
    ))
}


export default UserProfileShow
