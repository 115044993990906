import React from 'react'
import {
  Filter,
  TextInput,
} from 'react-admin'

const ExamTicketsFilter = props => {
  return (
    <>
      <Filter {...props}>
        <TextInput
          label="Поиск по ФИО, Email"
          allowEmpty={false}
          source="search"
        />
      </Filter>
    </>
  )
}

export default ExamTicketsFilter
