import { createMuiTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'

const eakTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#b71c1c',
      light: '#c54949'
    },
    secondary: {
      main: '#ffe082',
      light: '#ffe69b'
    },
    error: red,
    contrastThreshold: 2,
    tonalOffset: 0.1,
  }
})

export default eakTheme
