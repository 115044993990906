import React, { useState, useEffect, useReducer } from 'react'
import {
  SimpleForm,
  withDataProvider,
  UPDATE,
  CREATE,
  GET_LIST,
  Toolbar
} from 'react-admin'

import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TestSegmentAnswer from './TestSegmentAnswerCompact'
import TestSegmentQuestion from './TestSegmentQuestionCompact'
import SegmentHeader from '../../header/compact'
import CKEditorField from '../../../../../elements/CKEditorFieldCompact'
import ToolbarProvider from '../../../toolbarProvider/SegmentToolbarProvider'

import { reducerTest } from '../../../../../state/reducer'
import SegmentWatch from '../../../SegmentWatch'
import SegmentCopy from '../../../SegmentCopy'

const styles = () => createStyles({
  wrap: {
    display: 'flex',
    width: 'auto',
    marginBottom: '25px'
  },
  wrapButton: {
    display: 'flex',
    width: 'auto',
    marginBottom: '25px',
    justifyContent: 'space-between'
  },
  taskWrap: {
    width: 'auto',
    marginBottom: '5px',
    border: '1px solid #ddd',
    padding: '15px'
  },
  answersWrap: {
    display: 'flex',
    marginBottom: '25px',
    justifyContent: 'flex-end'
  },
  textAreaWrap: {
    marginBottom: '0'
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  previewButtonWrap: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px'
  },
  title: {
    marginTop: '0px',
    marginBottom: '0px'
  },
  rightBlock: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

interface IProps extends WithStyles {
  type: any,
  test: any,
  typeSegmentId: number,
  dataProvider: any,
  experts: any,
  dispatch: any,
  status: any,
  createdAt: string,
  updatedAt: string,
  verifyStatus?: any,
  permissions: any,
  versions: any,
  createdById: number,
  symbols: number,
  isCopy: number,
  originalId?: number
}

const TestSegmentCompact = withStyles(styles)(({
  classes,
  test,
  typeSegmentId,
  dataProvider,
  experts,
  status,
  createdAt,
  updatedAt,
  permissions,
  verifyStatus,
  createdById,
  versions,
  symbols,
  isCopy,
  originalId,
}: IProps) => {
  const {
    question: {
      content: task,
      id: questionId
    },
    answers,
    questions,
    segmentId,
    source,
    sourceLevelId
  } = test

  const [users, setUsers] = useState({
    experts: {},
    editors: {},
    nmo: {},
    mainNmo: {}
  })

  const [state, dispatch] = useReducer(reducerTest, {
    task,
    answers,
    questions
  })


  const saveTask = () => {
    const {
      id,
      question: {
        id: questionId
      }
    } = test

    dataProvider(UPDATE, 'segments', {
      id: questionId,
      url: 'test-questions',
      data: {
        testId: id,
        content: state.task
      }
    }, {
      onSuccess: {
        notification: { body: 'Сохранено', level: 'info' },
        refresh: true
      },
    })
  }

  const addQuestion = async () => {
    const {
      id: testId,
    } = test

    const result = await dataProvider(CREATE, 'segments', {
      url: 'questions',
      data: {
        testId,
        source,
        sourceLevelId,
        content: 'test',
      }
    }, {
      onSuccess: {
        notification: { body: 'Сохранено', level: 'info' },
      },
    })

    const {
      data
    } = result

    dispatch({ type: 'addQuestion', ...data })
  }

  const arrIdAddQuestionList = [3, 4]
  const isQuestionType = arrIdAddQuestionList.includes(test.typeId)

  useEffect(() => {
    (async () => {
      const expertReviewers = await dataProvider(GET_LIST, 'users',
        { filter: { specializationId: test.module.id, role: 'expertReviewer', status: 'active' } })
      const editors = await dataProvider(GET_LIST, 'users',
        { filter: { role: 'editor', status: 'active' } })
      const nmo = await dataProvider(GET_LIST, 'users',
        { filter: { role: 'directorMo', status: 'active' } })
      const mainNmo = await dataProvider(GET_LIST, 'users',
        { filter: { role: 'methodist', status: 'active' } })

      setUsers({
        ...users,
        experts: expertReviewers.data,
        editors: editors.data,
        nmo: nmo.data,
        mainNmo: mainNmo.data
      })
    })()
  }, [])

  return (
    <SimpleForm
      save={saveTask}
      toolbar={(
        <Toolbar>
          <ToolbarProvider
            verifyStatus={verifyStatus}
            dataProvider={dataProvider}
            experts={experts}
            permissions={permissions}
            status={status}
            users={users}
            segmentId={segmentId}
          />
        </Toolbar>
      )}
    >
      <div className={classes.wrapButton}>
        <h2 className={classes.title}>Сегмент {test.segmentId}</h2>
        <div className={classes.rightBlock}>
          <SegmentWatch
            type="test"
            segment={state}
          />
          <SegmentCopy
            segmentId={test.segmentId}
          />
        </div>
      </div>
      <SegmentHeader
        typeSegmentId={typeSegmentId}
        test={test}
        status={status}
        versions={versions}
        createdAt={createdAt}
        updatedAt={updatedAt}
        createdById={createdById}
        experts={experts}
        symbols={symbols}
        isCopy={isCopy}
        originalId={originalId}
        verifyStatus={verifyStatus}
      />
      <div className={classes.taskWrap}>
        <>
          <CKEditorField
            label="Вопрос теста"
            classes={classes.textAreaWrap}
            initialValue={state.task}
            cb={task => dispatch({ type: 'setTask', task })}
            status={status.name}
            id={questionId}
            idType="testQuestionId"
            save={saveTask}
            isDelete={false}
            labelSave="Сохранить вопрос теста"
            symbols={test.question.symbols}
          />
        </>
      </div>
      {!isQuestionType ? (
        <TestSegmentAnswer
          testId={test.id}
          answers={state.answers}
          dataProvider={dataProvider}
          typeId={test.typeId}
          status={status.name}
          dispatch={dispatch}
        />
      ) : (
        <>
          {state.questions && state.questions.map(question => (
            <TestSegmentQuestion
              key={question.id}
              question={question}
              typeId={test.typeId}
              testId={test.id}
              dispatchR={dispatch}
              status={status.name}
              typeSegmentId={typeSegmentId}
            />
          ))}
        </>
      )}

      {isQuestionType && (
        <Button
          onClick={addQuestion}
          className={classes.button}
          variant="contained"
          color="primary"
        >
          Добавить Подвопрос
        </Button>
      )}

    </SimpleForm>
  )
})

export default withDataProvider(TestSegmentCompact)
