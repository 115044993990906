import React from 'react'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  saveIcon: {
    fill: 'green',
    fontSize: '13px'
  },
  deleteIcon: {
    fill: 'red',
    fontSize: '13px'
  },
  confirmed: {
    backgroundColor: '#b2f8b2',
    '&:hover': {
      backgroundColor: '#73f773'
    },
    cursor: 'pointer',
    minWidth: '0',
    minHeight: '0',
    padding: '5px'
  },
  button: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff'
    },
    cursor: 'pointer',
    minWidth: '0',
    minHeight: '0',
    padding: '5px'
  },
  tooltip: {
    fontSize: '45px'
  },
  buttonClass: {
    marginLeft: '10px'
  }
}

const IconButton = ({
  isConfirmed,
  isDelete,
  label,
  onClick,
  classes
}) => (
  <div className={isDelete ? classes.buttonClass : ''}>
    <Tooltip title={label}>
      <Button
        className={isConfirmed ? classes.confirmed : classes.button}
        variant="contained"
        onClick={onClick}
      >
        { isDelete ? (
          <CloseIcon className={classes.deleteIcon} />
        ) : (
          <CheckIcon className={classes.saveIcon} />
        )}
      </Button>
    </Tooltip>
  </div>
)

export default withStyles(styles)(IconButton)
