import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import specializationsRoute from '../providers/module/roles'

const useVerifyStatusesList = () => {
  const [arrayVerifyStatuses, setArrayVerifyStatuses] = useState([])

  useEffect(() => {
    (async () => {
      const verifyStatusesList = await specializationsRoute(GET_LIST, 'verify-statuses')
      setArrayVerifyStatuses(verifyStatusesList.data)
    })()
  }, [])

  return arrayVerifyStatuses
}

export default useVerifyStatusesList
