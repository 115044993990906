
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  UPDATE,
  CREATE,
  DELETE,
  HttpError
} from 'react-admin'

import { stringify } from 'query-string'
import axios, { AxiosRequestConfig } from 'axios'
import diff from 'object-diff'
import Cookies from 'js-cookie'

import { AxiosResponse } from '../axiosResponse'
import { IQuery } from '../query'

const expertsRoute = async (type, resource, params) => {
  const options: AxiosRequestConfig = {}
  const query: IQuery = {
    token: `${Cookies.get('token')}`
  }
  switch (type) {
    case GET_ONE:
      options.url = `${resource}?${stringify(query)}`
      break
    case GET_MANY: {
      if (params.ids) {
        const ids = params.ids.join(',')
        options.url = `/${resource}?id=${ids}&${stringify(query)}`
      } else {
        options.url = `/${resource}?${stringify(query)}`
      }
      break
    }
    case GET_LIST: {
      Object.keys(params.filter || {}).forEach(key => {
        query[key] = params.filter[key]
      })

      if (params.pagination) {
        const { perPage, page } = params.pagination
        query.perPage = perPage
        query.page = page
      }

      if (params.sort && params.sort.field) {
        const prefix = params.sort.order === 'ASC' ? '' : '-'
        query.sort = `${prefix}${params.sort.field}`
      }

      options.url = `/${resource}?${stringify(query)}`
      break
    }
    case CREATE: {
      options.headers = { 'Content-Type': 'application/json' }
      options.url = `/${resource}?${stringify(query)}`
      options.method = 'POST'
      const { data } = params
      if (data.passwordRep) {
        delete data.passwordRep
      }
      options.data = JSON.stringify(data)
      break
    }
    case UPDATE: {
      options.headers = { 'Content-Type': 'application/json' }
      options.url = `/${resource}/${params.id}?${stringify(query)}`
      options.method = 'PUT'
      const data = diff(params.previousData, params.data)
      if (data.passwordRepeat) {
        delete data.passwordRepeat
      }
      options.data = JSON.stringify(data)
      break
    }
    case DELETE: {
      options.url = `/${resource}/${params.id}?${stringify(query)}`
      options.method = 'DELETE'
      break
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  let response: any


  response = await axios(options).catch(error => {
    const {
      response: {
        status: statusCode
      }
    } = error

    if (statusCode === 401) {
      localStorage.removeItem('token')
      Cookies.remove('token')
      Cookies.remove('role')
      Cookies.remove('userId')
      Cookies.remove('userName')
      return Promise.resolve()
    }

    if (statusCode === 422) {
      throw new HttpError(error.response.data[0].message)
    }
  })

  const { data: dataResponse } = response

  const data: IRAdminData<IProfile> = {
    data: null,
  }

  let dd
  if ([UPDATE, CREATE].includes(type)) {

    data.data = dataResponse
  } else {
    data.data = dataResponse.models
    data.total = dataResponse.total
  }

  console.log(data)

  return data
}

export default expertsRoute
