import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useCompetencesList = () :ICompetencesList[] => {
  const [arrayCompetences, setArrayCompetences] = useState<ICompetencesList[]>([])

  useEffect(() => {
    (async () => {
      const competencesList = await rolesRoute(GET_LIST, 'competences')
      setArrayCompetences(competencesList.data)
    })()
  }, [])

  return arrayCompetences
}

export default useCompetencesList
