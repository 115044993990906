import { useState, useEffect } from 'react'
import { GET_LIST } from 'react-admin'
import rolesRoute from '../providers/module/roles'

const useRolesList = () => {
  const [arrayRoles, setArrayRoles] = useState([])

  useEffect(() => {
    (async () => {
      const rolesList = await rolesRoute(GET_LIST, 'roles')
      setArrayRoles(rolesList.data)
    })()
  }, [])

  return arrayRoles
}

export default useRolesList
