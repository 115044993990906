export const changeCorretAnswer = (answer, id) => {
  if (answer.id === id) {
    if (answer.correct === 1) {
      answer.correct = 0
    } else {
      answer.correct = 1
    }
  }
  return answer
}

export const changeSingleCorretAnswer = (answer, id) => {
  if (answer.id === id) {
    if (!answer.correct) {
      answer.correct = 1
    }
  } else {
    answer.correct = 0
  }
  return answer
}
