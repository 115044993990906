import React, { useState } from 'react'
import {
  FileInput,
  SimpleForm,
  FileField,
  showNotification,
  withDataProvider
} from 'react-admin'
import {
  Paper, CardContent, CircularProgress
} from '@material-ui/core'

import SnackbarMessage from '../../elements/SnackBarMessage'
import ImportTable from './ImportTable'

export const isValidFile = filename => {
  const availableExtensions = ['xls', 'xlsx']
  const ext = filename.split('.').pop()

  if (availableExtensions.includes(ext)) return true
  return false
}

const ImportForm = props => {
  const [segments, setSegments] = useState(null)
  const [status, setStatus] = useState('')
  const [loaded, setLoaded] = useState(true)

  const handleClick = async formData => {
    const { dispatch, dataProvider } = props
    if (!formData.files || !formData.files.rawFile) {
      dispatch(showNotification('Файл не выбран', 'warning'))
    } else if (isValidFile(formData.files.title)) {
      setLoaded(false)

      const response = await dataProvider('IMPORT', 'import', formData, {
        onSuccess: {
          notification: { body: 'Успешно импортировано', level: 'info' },
          refresh: true
        },
        onFailure: {
          notification: { body: 'Произошла ошибка', level: 'warning' },
          refresh: true
        }
      })

      const { result, data } = response

      setStatus(result)
      setSegments(data)
      setLoaded(true)
    } else {
      dispatch(showNotification('Неверный тип файла!', 'warning'))
      return false
    }
  }

  const validateFileUpload = ({ files }) => {
    const { dispatch } = props

    if (files) {
      if (!isValidFile(files.title)) {
        dispatch(showNotification('Неверный тип файла!', 'warning'))
        return false
      }
    }
  }

  const message = {
    txt: '',
    type: ''
  }

  if (status == 'success') {
    message.txt = 'Импорт сегментов произведен успешно'
    message.type = status
  } else if (status == 'fail') {
    message.txt = 'Импорт сегментов произведен некорректно'
    message.type = status
  }

  return (
    <Paper>
      <CardContent>
        <h2>Импорт сегментов</h2>
        {status && <SnackbarMessage message={message.txt} type={message.type} />}
        {loaded || <CircularProgress />}
      </CardContent>

      {loaded && !segments
        && (
        <SimpleForm validate={validateFileUpload} save={handleClick}>
          <FileInput
            source="files"
            label="Файл должен быть заполнен в соответствии со стандартами. xls, xlsx файлы"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
        )}

      {segments && segments.length && <ImportTable segments={segments} />}
    </Paper>
  )
}
export default withDataProvider(ImportForm)
