import React from "react";
import {createStyles} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Parser from "html-react-parser";
import formatHtml from "../../../utils/formatHtml";

const styles = createStyles({
  panelAnswer: {
    padding: '15px',
    backgroundColor: '#b71c1c',
    color: '#fff',
    marginBottom: '15px'
  },
  formGroup: {
    padding: '0px 15px 15px',
    marginBottom: '15px'
  },
  check: {
    backgroundColor: '#b71c1c',
    color: '#fff',
    padding: '2px 5px',
    fontSize: '12px'
  },
  answer: {
    padding: '0px 15px 15px',
    marginBottom: '15px'
  },
  correct: {
    padding: '0px 15px 15px',
    marginBottom: '15px',
    color: '#28a745'
  }
})

const CompositeTestItem = withStyles(styles)(({record, classes}: any) => {
  return (
    <div>
      <h3>{Parser(formatHtml(record.test.question.content))}</h3>
      {record.test.questions.map((question) => {
        return <div>
          <h4>{Parser(formatHtml(question.content))}</h4>
          <ol>
            {question.answers.map(answer => {
              return <li className={answer.correct ? classes.correct : classes.answer}>
                {answer.content.replace(/(<([^>]+)>)/gi, "")}
                &nbsp;
                {answer.applicantAnswer && (
                  <span className={classes.check}>Ответ&nbsp;претендента</span>
                )}
              </li>
            })}
          </ol>
        </div>
      })}
    </div>
  )
})

export default CompositeTestItem