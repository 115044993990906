import React, { useState, useEffect } from 'react'
import { Toolbar } from 'react-admin'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Cookies from 'js-cookie'
import SegmentPreview from './SegmentPreview'
import DeactivateSegmentModal from './DeactivateSegmentModal'
import DashboardToolbarProvider from '../../elements/toolbars/DashboardToolbarProvider'

interface IProps extends WithStyles {
  ids?: number[],
  data?: ISegment,
  types: ITestTypesList[],
  modules: IModulesList[],
  criterias: ICriteriasList[],
  competences: ICompetencesList[],
  dataProvider: any,
  isArchived?: boolean,
  statusId: number
}

const styles = () => createStyles({
  Toolbar: {
    backgroundColor: '#ffffff',
  }
})

const Segments = withStyles(styles)(({
  classes,
  ids,
  data,
  modules,
  types,
  competences,
  criterias,
  isArchived,
  statusId
}: IProps) => {
  const [checkedValues, setCheckedValues] = useState([])
  const [checkedData, setCheckedData] = useState({})
  const [deactivateDisabled, setDeactivateDisabled] = useState(true)
  const role = Cookies.get('role')
  const rolesNotSegmentManagement = ['expertDeveloper', 'expertReviewer', 'editor']

  useEffect(() => {
    setDeactivateDisabled(checkedValues.length === 0)
  }, [checkedValues])

  const handleChange = (e, id: never) => {
    if (!checkedValues.includes(id)) {
      setCheckedValues([...checkedValues, id])
    } else {
      setCheckedValues(checkedValues.filter(item => item !== id))
    }
    if (!checkedData[id]) {
      setCheckedData({ ...checkedData, [id]: data[id] })
    } else {
      const newData = checkedData
      delete newData[id]
      setCheckedData({ ...newData })
    }
  }

  return (
    <>
      {ids.map(id => (
        <SegmentPreview
          ids={ids}
          key={id}
          data={data}
          segment={data[id]}
          types={types}
          competences={competences}
          criterias={criterias}
          modules={modules}
          onSegmentCheck={handleChange}
          checkedValues={checkedValues}
          id={id}
          isArchived={isArchived}
          statusId={statusId}
        />
      ))}
      {!isArchived && ids.length > 0 ? (
        <Toolbar className={classes.Toolbar}>
          {!rolesNotSegmentManagement.includes(role) && (<DashboardToolbarProvider segmentsId={checkedValues} checkedData={checkedData} />)}
          {role !== 'methodist' && (<DeactivateSegmentModal segmentId={checkedValues} buttonDisabled={deactivateDisabled} />)}
        </Toolbar>
      ) : ''}
    </>
  )
})

export default Segments
