import React from 'react'
import { Create } from 'react-admin'
import Form from './userForm'

const UserCreate = ({ permissions, ...props }) => (
  <Create {...props} title=" ">
    <Form
      permissions={permissions}
      redirect="show"
      formType="create"
    />
  </Create>
)

export default UserCreate
