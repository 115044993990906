import React from 'react'
import {
  Filter,
  TextInput,
  SelectInput,
  DateInput,
  NumberInput,
} from 'react-admin'
import useModulesList from '../../utils/useModulesList'
import useExamLevelsList from '../../utils/useExamLevelsList'

const WORK_STATUSES = [
  { type: 'empty', text: 'Законченная' },
  { type: 'validate', text: 'Проверка на валидность' },
  { type: 'invalidate', text: 'Не валидная' },
  { type: 'verify', text: 'На проверке' },
  { type: 'complete', text: 'Оцененая' },
  { type: 'archive', text: 'В архиве' }
]

const VERIFY_STATUSES = [
  { type: 'empty', text: 'Не проверена' },
  { type: 'verify', text: 'На проверке' },
  { type: 'work', text: 'В работе' },
  { type: 'complete', text: 'Проверена' },
  { type: 'cancel', text: 'Отменена' },
]

const CHECK_STAGES = [
  { type: '1', text: 'Один эксперт' },
  { type: '2', text: 'Два эксперта' },
  { type: '3', text: 'Комиссия' },
]

const styles = {
  numberInput: {
    width: '150px'
  }
}

const ExamTicketsFilter = props => {
  const modules = useModulesList()
  const examLevels = useExamLevelsList()
  const { permissions } = props

  return (
    <>
      <Filter {...props}>
        {permissions && permissions.role !== 'censor' && (
          <TextInput
            label="Поиск по № билета"
            allowEmpty={false}
            source="number"
          />
        )}
        <SelectInput
          optionText="name"
          optionValue="id"
          label="Модуль"
          choices={modules}
          source="moduleId"
          allowEmpty={false}
        />
        <SelectInput
          optionText="name"
          optionValue="id"
          label="Уровень"
          choices={examLevels}
          source="levelId"
          allowEmpty={false}
        />
        {permissions && permissions.role !== 'censor' && (
          <SelectInput
            optionText="text"
            optionValue="type"
            choices={WORK_STATUSES}
            label="Статус работы"
            source="workStatus"
            allowEmpty={false}
          />
        )}
        <SelectInput
          optionText="text"
          optionValue="type"
          choices={VERIFY_STATUSES}
          source="verifyStatus"
          label="Статус проверки"
          allowEmpty={false}
        />
        <SelectInput
          optionText="text"
          optionValue="type"
          choices={CHECK_STAGES}
          source="expertStatus"
          label="Этап проверки"
          allowEmpty={false}
        />
        <NumberInput
          style={styles.numberInput}
          source="markFrom"
          label="Общая оценка от"
        />
        <NumberInput
          style={styles.numberInput}
          source="markTo"
          label="Общая оценка до"
        />
        <TextInput
          label="Назначен на"
          source="assignedName"
          allowEmpty={false}
        />
        <DateInput
          label="Дата экзамена"
          source="examDate"
          allowEmpty={false}
        />
      </Filter>
    </>
  )
}

export default ExamTicketsFilter
