import React from 'react'
import {
  Edit,
} from 'react-admin'
import Cookies from 'js-cookie'
import { createStyles, WithStyles, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TestSegment from './segmentsType/test/TestSegment'
import TestSegmentCompact from './segmentsType/test/compact/TestSegmentCompact'

import CaseSegment from './segmentsType/case/CaseSegment'

const styles = () => createStyles({
  wrap: {
    display: 'flex',
  },
  elements: {
    marginRight: '25px',
  },
  root: {
    flexGrow: 1,
  },
  preview: {
    marginTop: '15px',
    float: 'right',
    marginRight: '15px'
  },
  paper: {
    position: 'absolute',
    left: '50%',
    top: '20%',
    marginLeft: '-200px',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
  },
})

export const SegmentEdit = props => (
  <Edit {...props} undoable>
    <SegmentEditFrom {...props} />
  </Edit>
)

interface IProps extends WithStyles {
  record: any,
  permissions: any
}

const SegmentEditFrom = withStyles(styles)(({ ...props }:IProps) => {
  const { classes, record, permissions } = props
  const {
    type,
    typeId: typeSegmentId,
    case: caseTest,
    test,
    experts,
    status,
    createdAt,
    updatedAt,
    verifyStatusId,
    createdById,
    versions,
    symbols,
    isCopy,
    originalId
  } = record

  const isCase = type === 'case'

  const role = Cookies.get('role')

  return (
    <Grid
      container
      direction="row"
      spacing={24}
      className={classes.root}
    >
      {role === 'editor' && (
        <Grid item xs={12}>
          {isCase ? (
            <CaseSegment
              type={type}
              test={caseTest}
              typeSegmentId={typeSegmentId}
              experts={experts}
              status={status}
              createdAt={createdAt}
              updatedAt={updatedAt}
              permissions={permissions}
              verifyStatus={verifyStatusId}
              versions={versions}
              createdById={createdById}
              symbols={symbols}
            />
          ) : (
            <TestSegmentCompact
              status={status}
              type={type}
              test={test}
              typeSegmentId={typeSegmentId}
              experts={experts}
              createdAt={createdAt}
              updatedAt={updatedAt}
              permissions={permissions}
              verifyStatus={verifyStatusId}
              versions={versions}
              createdById={createdById}
              symbols={symbols}
              isCopy={isCopy}
              originalId={originalId}
            />
          )}
        </Grid>
      )}

      {role !== 'editor' && (
        <Grid item xs={12}>
          {isCase ? (
            <CaseSegment
              type={type}
              test={caseTest}
              typeSegmentId={typeSegmentId}
              experts={experts}
              status={status}
              createdAt={createdAt}
              updatedAt={updatedAt}
              permissions={permissions}
              verifyStatus={verifyStatusId}
              versions={versions}
              createdById={createdById}
              symbols={symbols}
            />
          ) : (
            <TestSegment
              status={status}
              type={type}
              test={test}
              typeSegmentId={typeSegmentId}
              experts={experts}
              createdAt={createdAt}
              updatedAt={updatedAt}
              permissions={permissions}
              verifyStatus={verifyStatusId}
              versions={versions}
              createdById={createdById}
              symbols={symbols}
              isCopy={isCopy}
              originalId={originalId}
            />
          )}
        </Grid>
      )}
    </Grid>
  )
})

export default SegmentEdit
