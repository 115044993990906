import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import {
  withDataProvider,
  GET_ONE
} from 'react-admin'
import Cookies from 'js-cookie'
import { getDateTime } from '../../../../utils/getFields'
import { Link } from 'react-router-dom'

const styles = {
  card: {
    width: '100%',
    backgroundColor: '#f8f8f8',
    alignSelf: 'flex-start',
    marginBottom: '20px'
  },
  title: {
    marginBottom: '10px'
  }
}

const EXPERT_ROLE = {
  REVIEWER: 'expertReviewer',
  DIRECTOR: 'directorMo',
  EDITOR: 'editor',
  METHODIST: 'methodist',
}

interface iCreator {
  id: string,
  middleName: string,
  name: string,
  surname: string
}

const SegmentInfo = (
  {
    classes,
    status,
    createdAt,
    updatedAt,
    createdById,
    dataProvider,
    experts,
    symbols,
    isCopy,
    originalId
  }
) => {
  const [creator, setCreactor] = useState<iCreator>({
    id: '',
    middleName: '',
    name: '',
    surname: ''
  })

  useEffect(() => {
    (async () => {
      const response = await dataProvider(GET_ONE, 'users', { id: createdById })
      setCreactor(response.data)
    })()
  }, [])

  const getUsersByRole = role => experts.filter(expert => expert.role === role)
  const getFio = user => `${user.surname || ''} ${user.name || ''} ${user.middleName || ''}`.trim()

  const role = Cookies.get('role')

  const getVerifyExperts = (experts) => {
    const list = experts.map((item) => `${item.expert.surname} ${item.expert.name} ${item.expert.middleName}`)
    return list.join(', ')
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="title" className={classes.title}>Информация о сегменте</Typography>
        <Typography>
          Кем создан: <Link to={{ pathname: `/users/${creator.id}/show` }}>{creator.id}</Link>
        </Typography>
        <Typography>
          {`Статус сегмента: ${status.name}`}
        </Typography>
        {(isCopy == 0 && originalId) && (
          <Typography>
            Создан на основе: <Link to={{ pathname: `/segments/${originalId}` }}>{originalId}</Link>
          </Typography>
        )}
        <Typography>
          {`Создан: ${getDateTime(createdAt)}`}
        </Typography>
        <Typography>
          {`Изменен: ${getDateTime(updatedAt)}`}
        </Typography>
        {experts.length > 0 && role !== 'expertReviewer' && (
          <>
            <Typography>
              {`Эксперт: ${getUsersByRole(EXPERT_ROLE.REVIEWER).map(getFio).join(', ')}`}
            </Typography>
            <Typography>
              {`НМО: ${getUsersByRole(EXPERT_ROLE.DIRECTOR).map(getFio).join(', ')}`}
            </Typography>
            <Typography>
              {`Корректор: ${getUsersByRole(EXPERT_ROLE.EDITOR).map(getFio).join(', ')}`}
            </Typography>
            <Typography>
              {`Главный методист: ${getUsersByRole(EXPERT_ROLE.METHODIST).map(getFio).join(', ')}`}
            </Typography>
          </>
        )}
        <hr />
        <Typography>
          {`Количество символов: ${symbols}`}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default withDataProvider(withStyles(styles)(SegmentInfo))
