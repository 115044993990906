import React from 'react'
import {
  Toolbar,
  Button
} from 'react-admin'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'

/* need extends to Button type */
interface IButton {
  className?: string,
  label?: string,
  onClick?: any,
  variant?: string,
  color?: string,
  disabled?: any
}

interface IProps extends WithStyles{
  buttons: IButton[]
}

const styles = () => createStyles({
  Toolbar: {
    backgroundColor: '#ffffff',
  },
})

const ButtonsToolbar = ({ classes, buttons }: IProps) => (
  <Toolbar className={classes.Toolbar}>
    {buttons.map(props => {
      const {
        className, label, onClick, variant, color, disabled
      } = props
      return (
        <Button
          className={className}
          label={label}
          onClick={onClick}
          variant={variant}
          color={color}
          disabled={disabled}
        />
      )
    })}
  </Toolbar>
)


export default withStyles(styles)(ButtonsToolbar)
