import React, { useState } from 'react'
import {
  CREATE, withDataProvider
} from 'react-admin'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

interface Data {
  id: number,
  idType: string,
  entity?: string,
  text?: string
}

const AddCommentField = ({
  id,
  idType,
  entityForCase,
  setCommentsList,
  ...props
}) => {
  const [comment, setComment] = useState('')
  const handleClick = async () => {
    const { dataProvider } = props
    let data: Data = { id, idType, text: comment }
    if (entityForCase) {
      data = { ...data, entity: entityForCase }
    }
    const response = await dataProvider(CREATE, 'comments', data, {
      onSuccess: {
        notification: { body: 'Комментарий добавлен', level: 'info' },

      },
      onFailure: {
        notification: { body: 'Произошла ошибка', level: 'warning' },

      }
    })
    setCommentsList(commentsList => [...commentsList, response.data])
    setComment('')
  }

  return (
    <>
      <TextField
        value={comment}
        multiline
        fullWidth
        label="Введите текст комментария..."
        onChange={e => setComment(e.target.value)}
      />
      <Button
        style={{ display: 'block', marginTop: '10px' }}
        variant="contained"
        onClick={handleClick}
      >
        Сохранить комментарий
      </Button>
    </>

  )
}

export default withDataProvider(AddCommentField)
